import { find } from 'lodash'
import { I18n } from 'react-redux-i18n'
import { SelectOption } from '../types'

export const QUESTIONS_EXAMPLES = {
  'question1': {
    'label': 'Est-il possible de rajouter une question à une questionnaire ?',
    'content': 'Il n’est pas possible de changer les questions une fois les questionnaires créés et publiés. Il est cependant possible de demander la création d’un nouveau questionnaire à vos correspondants Thank you & Welcome.'
  },
  'question2': {
    'label': 'Combien d’administrateurs peuvent avoir accès à mon compte administrateur ?',
    'content': 'Vous pouvez partager votre compte administrateur avec autant de personnes que vous le souhaitez. Elles auront accès aux mêmes droits que vous.'
  },
  'question3': {
    'label': 'Quel est le nombre maximum de collaborateurs à qui je peux transmettre un questionnaire ?',
    'content': 'Il n’y a aucune limite si ce n’est par le nombre de transmission inclus dans votre pack abonnement'
  }
}

export const getCategories = (includeAll: Boolean = false): SelectOption[] => {
  const categories: SelectOption[] = [
    { value: 'thank_you', label: I18n.t('pages.questions.thank_you') },
    { value: 'welcome', label: I18n.t('pages.questions.welcome') }
  ]

  if (includeAll) {
    categories.unshift({ value: '', label: I18n.t('pages.questions.all') })
  }
  return categories
}

export const currentCategory = (value: string) => {
  return find(getCategories(true), (cat: SelectOption) => cat.value === value)
}
