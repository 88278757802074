import { handle } from 'redux-pack'
import { filter, map, unionBy } from 'lodash'
import {
  GET_TEMPLATE_QUESTIONS,
  UNACTIVE_TEMPLATE_QUESTIONS,
  UPDATE_TEMPLATE_QUESTION,
  BATCH_UPDATE_TEMPLATE_QUESTION,
  CREATE_TEMPLATE_QUESTION,
} from '../constants'
import { TemplateQuestion } from '../types'

interface IState {
  allTemplateQuestions: TemplateQuestion[]
  loading: boolean
  create_loading: boolean
}
const initialState: IState = {
  allTemplateQuestions: [],
  loading: false,
  create_loading: false,
}

export default (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case GET_TEMPLATE_QUESTIONS:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({ ...prevState, allTemplateQuestions: payload }),
      })
    case UNACTIVE_TEMPLATE_QUESTIONS:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allTemplateQuestions: filter(
            prevState.allTemplateQuestions,
            (q: TemplateQuestion) => q.id !== payload.id,
          ),
        }),
      })
    case CREATE_TEMPLATE_QUESTION:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true, create_loading: true }),
        finish: prevState => ({ ...prevState, loading: false, create_loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allTemplateQuestions: [...prevState.allTemplateQuestions, payload],
        }),
      })
    case BATCH_UPDATE_TEMPLATE_QUESTION:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true, create_loading: true }),
        finish: prevState => ({ ...prevState, loading: false, create_loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allTemplateQuestions: unionBy(payload, prevState.allTemplateQuestions, 'id')
        })
      })
    case UPDATE_TEMPLATE_QUESTION:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true, create_loading: true }),
        finish: prevState => ({ ...prevState, loading: false, create_loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allTemplateQuestions: map(prevState.allTemplateQuestions, (question: TemplateQuestion) =>
            question.id === payload.id ? payload : question,
          ),
        }),
      })
    default:
      return state
  }
}
