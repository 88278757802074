export const GET_ADMINS = 'GET_ADMINS'

export const GET_CAMPAIGNS = 'GET_CAMPAIGNS'
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN'
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN'
export const GET_CAMPAIGN_STEP = 'GET_CAMPAIGN_STEP'
export const CREATE_CAMPAIGN_STEP = 'CREATE_CAMPAIGN_STEP'
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN'

export const GET_COMPANY = 'GET_COMPANY'
export const DELETE_COMPANY = 'DELETE_COMPANY'
export const GET_COMPANIES = 'GET_COMPANIES'

export const GET_MANAGERS = 'GET_MANAGERS'
export const UPDATE_MANAGER_COMPANY = 'UPDATE_MANAGER_COMPANY'
export const DELETE_MANAGERS = 'DELETE_MANAGERS'

export const GET_MANAGER_INVITATION_TOKENS = 'GET_MANAGER_INVITATION_TOKENS'
export const CREATE_MANAGER_INVITATION_TOKEN = 'CREATE_MANAGER_INVITATION_TOKEN'

export const CREATE_SURVEY = 'CREATE_SURVEY'
export const UPDATE_SURVEY = 'UPDATE_SURVEY'
export const GET_SURVEYS = 'GET_SURVEYS'
export const GET_SURVEY = 'GET_SURVEY'

export const GET_QUESTION_TYPES = 'GET_QUESTION_TYPES'

export const GET_TEMPLATE_QUESTIONS = 'GET_TEMPLATE_QUESTIONS'
export const UNACTIVE_TEMPLATE_QUESTIONS = 'UNACTIVE_TEMPLATE_QUESTIONS'
export const BATCH_UPDATE_TEMPLATE_QUESTION = 'BATCH_UPDATE_TEMPLATE_QUESTION'
export const UPDATE_TEMPLATE_QUESTION = 'UPDATE_TEMPLATE_QUESTION'
export const CREATE_TEMPLATE_QUESTION = 'CREATE_TEMPLATE_QUESTION'

export const UPDATE_USER = 'UPDATE_USER'
export const GET_USERS_COLLABORATOR = 'GET_USERS_COLLABORATOR'
export const DELETE_USER = 'DELETE_USER'

export const GET_USER_SURVEYS = 'GET_USER_SURVEYS'
export const VALIDATE_USER_SURVEY = 'VALIDATE_USER_SURVEY'
export const RESEND_SURVEY_INVITATION = 'RESEND_SURVEY_INVITATION'
export const DELETE_USER_SURVEY = 'DELETE_USER_SURVEY'
export const CREATE_USER_SURVEY = 'CREATE_USER_SURVEY'

export const GET_MOSAIC = 'GET_MOSAIC'
export const GET_MOSAICS = 'GET_MOSAICS'
export const CREATE_MOSAIC = 'CREATE_MOSAIC'
export const UPDATE_MOSAIC = 'UPDATE_MOSAIC'
export const DELETE_MOSAIC = 'DELETE_MOSAIC'

export const CREATE_TILE = 'CREATE_TILE'
export const UPDATE_TILE = 'UPDATE_TILE'

export const GET_STATISTIC = 'GET_STATISTIC'
export const GET_STATISTICS = 'GET_STATISTICS'
