import React from 'react'
import { withRouter } from 'react-router'

import { map } from 'lodash'
import { I18n } from 'react-redux-i18n'
import { HelpQuestion, SignedInLayout } from '../../components'
import { QUESTIONS_EXAMPLES } from '../../constants'

import './styles.sass'

interface IProps {}

class HelpScene extends React.Component<IProps> {
  render() {
    return (
      <SignedInLayout>
        <p className="page-title">{I18n.t('pages.help.title')}</p>
        {map(QUESTIONS_EXAMPLES, ({ label, content}: any, key: string) => {
          return (
            <HelpQuestion
              key={key}
              title={({ onClick }: any) => <div onClick={onClick}>{label}</div>}
              >
                <p className="help-question-content">{content}</p>
            </HelpQuestion>
          )
        })}
        <small>
          {I18n.t('pages.help.support')}
          support@thankyouandwelcome.fr
        </small>
      </SignedInLayout>
    )
  }
}

export default withRouter(HelpScene as any)
