import React from 'react'
import { withRouter } from 'react-router'

/* redux */
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'

/* libs */
import { get, filter } from 'lodash'
import { TextInput } from 'tyaw-components'

/* AG Grid */
import { ColumnApi, GridApi, GridReadyEvent, RowValueChangedEvent } from 'ag-grid-community/main.d'

/* app */
import { notification } from '../../helpers'
import { Store, Hierarchy, User, Company, SelectOption } from '../../types'
import { getHierarchies, createHierarchy, updateHierarchy, deleteHierarchy } from '../../api'
import { HierarchiesGrid, SignedInLayout, CompanySelect } from '../../components'
import { AddRowComponent } from '../../components/ag-grid'

interface IProps {
  user: User
  locale: string
  companies: Company[]
}

interface IState {
  gridApi: GridApi | undefined
  gridColumnApi: ColumnApi | undefined
  hierarchies: Hierarchy[]
  selectedCompany: SelectOption
}

export class HierarchiesScene extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props)

    this.state = {
      gridApi: undefined,
      gridColumnApi: undefined,
      hierarchies: [],
      selectedCompany: {
        value: get(props.user, 'company.id', ''),
        label: get(props.user, 'company.name', '')
      }
    }
  }

  componentDidMount() {
    const {selectedCompany} = this.state
    if (selectedCompany.value !== '') {
      this.selectCompany(selectedCompany)
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const { user } = this.props

    if (user !== prevProps.user && user.company) {
      this.selectCompany({
        value: get(user, 'company.id'),
        label: get(user, 'company.name')
      })
    }
  }

  selectCompany(selectedCompany: SelectOption) {

    getHierarchies(get(selectedCompany, 'value')).then((hierarchies) =>
      this.setState({
        hierarchies,
        selectedCompany
      })
    ).catch(() => notification('error', 'hierarchy.index'))
  }

  onGridReady(event: GridReadyEvent) {
    this.setState({
      gridApi: event.api,
      gridColumnApi: event.columnApi
    })
  }

  handleChanges(event: RowValueChangedEvent) {
    const companyId = get(this.state, 'selectedCompany.value')
    const data: any = get(event, 'data')

    if (get(data, 'isNew', false)) {
      createHierarchy(companyId, data).then((hierarchies: Hierarchy[]) => {
        this.setState({ hierarchies })
        notification('success', 'hierarchy.create')
      }).catch(() => {
        notification('error', 'hierarchy.create')
      })
    } else {
      updateHierarchy(companyId, data.id, data).then((hierarchies: Hierarchy[]) => {
        this.setState({ hierarchies })
        notification('success', 'hierarchy.update')
      }).catch(() => {
        notification('error', 'hierarchy.update')
      })
    }
  }

  handleDelete(hierarchy: Hierarchy): Promise<boolean> {
    return new Promise((resolve: any, reject: any) => {
      const companyId = get(this.state, 'selectedCompany.value')

      if (!hierarchy.isNew) {
        deleteHierarchy(companyId, get(hierarchy, 'id')).then((hierarchies: Hierarchy[]) => {
          this.setState({ hierarchies })
          notification('success', 'hierarchy.delete')
          resolve(true)
        }).catch((error: any) => {
          notification('error', 'hierarchy.delete', { apiErrors: get(error, 'response.data', []) })
          reject(false)
        })
      }
    })
  }

  renderGridMenu() {
    const { gridApi, gridColumnApi } = this.state

    if (!gridApi) {
      return null
    }

    return (
      <div className="ag-grid-tools">
        <AddRowComponent
          gridApi={gridApi}
          columnApi={gridColumnApi}
          buttonLabel={I18n.t('pages.hierarchies.titleCreate')}
          initData={{nameI18n: {}}}
        />
        <div className="ag-grid-filters-container">
          <TextInput
            placeholder={I18n.t('actions.search')}
            className="ag-grid-filter white"
            onChangeText={(e: any) => gridApi && gridApi.setQuickFilter(e)}
          />
        </div>
      </div>
    )
  }

  renderGrid() {
    const { hierarchies } = this.state
    const { locale, user } = this.props

    return (
      <div className="ag-grid-container">
        { this.renderGridMenu() }
        <HierarchiesGrid
          data={{
            locale,
            rows: hierarchies
          }}
          onGridReady={ (event: GridReadyEvent) => this.onGridReady(event) }
          handleChanges={ (event: RowValueChangedEvent) => this.handleChanges(event) }
          handleDelete={ get(user, 'admin', false) ? (hierarchy: Hierarchy) => this.handleDelete(hierarchy) : undefined }
        />
      </div>
    )
  }

  render() {
    const { selectedCompany } = this.state
    const { companies } = this.props

    const options = filter(companies, (company: Company) => get(company, 'activatedTags', false))

    return (
      <SignedInLayout>
        <CompanySelect
          companies={options}
          selectedCompany={selectedCompany}
          handleSelect={(company: SelectOption) => this.selectCompany(company)}
          hint={I18n.t('pages.hierarchies.companySelectHint')}
        />
        { get(selectedCompany, 'value') ? this.renderGrid() : null }
      </SignedInLayout>
    )
  }
}

const mapStateToProps = () => ({
  user: { currentUser },
  i18n: { locale },
  companies: { allCompanies }
}: Store) => ({
  locale,
  user: currentUser,
  companies: allCompanies
})

export default withRouter(connect(
  mapStateToProps
)(HierarchiesScene) as any)
