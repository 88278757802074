import React from 'react'
import Select from 'react-select'

import { I18n } from 'react-redux-i18n'

import { Company, SelectOption } from '../../types'
import { companyOptions } from '../../helpers'
import { Can } from '../../config/ability-context'

import './styles.scss'

interface IProps {
  companies: Company[]
  selectedCompany: SelectOption
  ref?: any
  hint?: string
  handleSelect: (e: any) => void
}

const companySelect = React.forwardRef(({ companies, selectedCompany, handleSelect, hint }: IProps, forwardedRef: any) => {

  return (
    <Can I="see" this="companies">
      <div className="select-company">
        {I18n.t('components.companySelect.label')}
        { hint ? <small>{hint}</small> : null }

        <Select
          value={selectedCompany.value ? selectedCompany : 'Organisation'}
          onChange={(selectedCompany: any) => handleSelect(selectedCompany)}
          options={companyOptions(companies)}
          placeholder={I18n.t('components.companySelect.placeholder')}
        />
      </div>
    </Can>
  )
})

export default companySelect
