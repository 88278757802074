import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension'
import { middleware as reduxPackMiddleware } from 'redux-pack'

import { history } from '../constants'
import reducers from '../reducers'

export default createStore(
  reducers,
  composeWithDevTools({ trace: true, traceLimit: 25 })(
    applyMiddleware(thunk),
    applyMiddleware(routerMiddleware(history)),
    applyMiddleware(reduxPackMiddleware),
  ),
)
