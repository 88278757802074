import { apiClient } from '../constants'
import { Tile } from '../types'

export const getMosaics = () =>
  apiClient.get('/mosaics').then(({ data }) => data)

export const getMosaicsForCompany = (companyId: string) => apiClient.get('/mosaics', {
  params: {
    company_id: companyId
  }
}).then(({ data }) => data)

export const getMosaic = (id: string) =>
  apiClient.get(`/mosaics/${id}`).then(({ data }) => data)

export const getMosaicFromToken = (accessToken: string) =>
  apiClient.get('/mosaics/public', { params: { access_token: accessToken } }).then(({ data }) => data)

export const createMosaic = (mosaic: FormData) =>
  apiClient.post('/mosaics', mosaic, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
  }).then(({ data }) => data)

export const updateMosaic = (mosaic: FormData, id: string) => {
  return apiClient.patch(
    `/mosaics/${id}`,
    mosaic, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
  }).then(({ data }) => data)
}

export const removeTileImage = (mosaicId: string, tile: Tile) =>
  apiClient.delete(`/mosaics/${mosaicId}/tiles/${tile.id}/remove-image`).then(({ data }) => data)

export const removeTile = (mosaicId: string, tile: Tile) =>
  apiClient.delete(`/mosaics/${mosaicId}/tiles/${tile.id}`).then(({ data }) => data)

export const deleteMosaic = (id: string) =>
  apiClient.delete(`/mosaics/${id}`).then(({ data }) => data)

export const downloadMosaic = (mosaicId: string) =>
  apiClient.get(`/mosaics/${mosaicId}/download`).then(({ data }) => data)