import { handle } from 'redux-pack'

import { GET_ADMINS } from '../constants'

interface IState {
  allAdmins: any[]
  loading: boolean
}

const initialState: IState = {
  allAdmins: [],
  loading: false,
}

export default (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case GET_ADMINS:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({ ...prevState, allAdmins: payload }),
      })
    default:
      return state
  }
}
