import {
  createSurvey as apiCreateSurvey,
  updateSurvey as apiUpdateSurvey,
  getSurveys as apiGetSurveys,
  getSurvey as apiGetSurvey,
} from '../api'
import {
  CREATE_SURVEY,
  GET_SURVEYS,
  GET_SURVEY,
  UPDATE_SURVEY,
} from '../constants'
import { Survey, SurveyTarget } from '../types'

export const getSurveys = (params?: SurveyTarget) => ({
  type: GET_SURVEYS,
  promise: apiGetSurveys(params),
})

export const getSurvey = (id: string, meta: any) => ({
  meta,
  type: GET_SURVEY,
  promise: apiGetSurvey(id)
})

export const createSurvey = (params: Survey, meta: any) => ({
  meta,
  type: CREATE_SURVEY,
  promise: apiCreateSurvey(params),
})

export const updateSurvey = (params: Survey, meta: any) => ({
  meta,
  type: UPDATE_SURVEY,
  promise: apiUpdateSurvey(params),
})
