import { connect } from 'react-redux'
import { get } from 'lodash'

import { Sidebar } from '../../components'
import { Store } from '../../types'

import { logo } from '../../assets/images'

const mapStateToProps = ({
  user: { currentUser },
}: Store) => ({
  isAdmin: currentUser.admin,
  logoUrl: get(currentUser, 'company.logoUrl', logo)
})

export default connect(mapStateToProps)(Sidebar as any)
