import {
  getUserSurveys as apiGetUserSurveys,
  getUserSurveysForCompany as apiGetUserSurveysForCompany,
  validateUserSurvey as apiValidateUserSurvey,
  unvalidateUserSurvey as apiUnvalidateUserSurvey,
  completeUserSurvey as apiCompleteUserSurvey,
  uncompleteUserSurvey as apiUncompleteUserSurvey,
  resendSurveyInvitation as apiResendSurveyInvitation,
  deleteUserSurvey as apiDeleteUserSurvey,
  createUserSurvey as apiCreateUserSurvey,
} from '../api'
import { I18n } from 'react-redux-i18n'
import { toastr } from 'react-redux-toastr'
import { GET_USER_SURVEYS, CREATE_USER_SURVEY, RESEND_SURVEY_INVITATION, VALIDATE_USER_SURVEY, DELETE_USER_SURVEY } from '../constants'
import { UserSurvey } from '../types'

export const getUserSurveys = (withTags: boolean = false) => ({
  promise: apiGetUserSurveys(withTags),
  type: GET_USER_SURVEYS,
})

export const getUserSurveysForCompany = (companyId: string, withTags: boolean = false) => ({
  promise: apiGetUserSurveysForCompany(companyId, withTags),
  type: GET_USER_SURVEYS
})

export const createUserSurvey = (userSurvey: UserSurvey, meta: any) => ({
  meta,
  type: CREATE_USER_SURVEY,
  promise: apiCreateUserSurvey(userSurvey)
})

export const resendSurveyInvitation = (userSurvey: UserSurvey) => ({
  promise: apiResendSurveyInvitation(userSurvey),
  meta: {
    onSuccess: () => {
      toastr.success(
        I18n.t('alerts.userSurvey.resendSurveyInvitation.success.title'),
        I18n.t('alerts.userSurvey.resendSurveyInvitation.success.message')
      )
    },
    onFailure: (error: any, getState: any) => {
      let message = getState().userSurveys.error.response.data.join('<br />')
      if (!message) {
        message = I18n.t('alerts.userSurvey.resendSurveyInvitation.error.message')
      }
      toastr.error(
        I18n.t('alerts.userSurvey.resendSurveyInvitation.error.title'),
        message
      )
    }
  },
  type: RESEND_SURVEY_INVITATION
})

export const validateUserSurvey = (userSurvey: UserSurvey) => ({
  meta: {
    onSuccess: () => {
      toastr.success('Validation réussie', 'La transmission a bien été validée !')
    },
    onFailure: () => {
      toastr.error(
        'Erreur lors de votre validation',
        'Assurez vous que le collaborateur a bien finalisé son questionnaire',
      )
    },
  },
  promise: apiValidateUserSurvey(userSurvey),
  type: VALIDATE_USER_SURVEY,
})

export const completeUserSurvey = (userSurvey: UserSurvey) => ({
  meta: {
    onSuccess: () => {
      toastr.success('Validation réussie', 'La transmission a bien été validée !')
    },
    onFailure: () => {
      toastr.error(
        'Erreur lors de votre validation',
        'Assurez vous que le collaborateur a bien finalisé son questionnaire',
      )
    },
  },
  promise: apiCompleteUserSurvey(userSurvey),
  type: VALIDATE_USER_SURVEY,
})

export const unvalidateUserSurvey = (userSurvey: UserSurvey) => ({
  meta: {
    onSuccess: () => {
      toastr.success('Invalidation réussie', 'La transmission a bien été invalidée !')
    },
    onFailure: () => {
      toastr.error(
        'Erreur lors de votre validation',
        'Assurez vous que le collaborateur a bien finalisé son questionnaire',
      )
    },
  },
  promise: apiUnvalidateUserSurvey(userSurvey),
  type: VALIDATE_USER_SURVEY,
})

export const uncompleteUserSurvey = (userSurvey: UserSurvey) => ({
  meta: {
    onSuccess: () => {
      toastr.success('Invalidation réussie', 'La transmission a bien été invalidée !')
    },
    onFailure: () => {
      toastr.error(
        'Erreur lors de votre validation',
        'Assurez vous que le collaborateur a bien finalisé son questionnaire',
      )
    },
  },
  promise: apiUncompleteUserSurvey(userSurvey),
  type: VALIDATE_USER_SURVEY,
})

export const deleteUserSurvey = (userSurveyId: any, meta: any) => ({
  meta,
  type: DELETE_USER_SURVEY,
  promise: apiDeleteUserSurvey(userSurveyId.id),
})
