import React from 'react'
import { get, map } from 'lodash'
import './styles.sass'
import { getFileIcon } from 'tyaw-components'

export default (props?: any) => {
  const files = get(props, 'documents')

  return(
    <div className="list-documents-container">
      <label>Pièce(s) jointe(s)</label>
      <ul className="list-documents">
        {map(files, (file: object, index: number) => {
          const filename = get(file, 'filename', '')

          return(
            <li key={`file${index}`}>
              <img src={getFileIcon(filename)} />
              <a href={get(file, 'publicSignedUrl')} target="_blank">
                {filename}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
