import {
  createCampaign as apiCreateCampaign,
  updateCampaign as apiUpdateCampaign,
  getCampaigns as apiGetCampaigns,
  getCampaignsForCompany as apiGetCampaignsForCompany,
  getCampaignStep as apiGetCampaignStep,
  createCampaignStep as apiCreateCampaignStep,
  deleteCampaign as apiDeleteCampaign,
} from '../api'
import {
  CREATE_CAMPAIGN,
  GET_CAMPAIGNS,
  UPDATE_CAMPAIGN,
  GET_CAMPAIGN_STEP,
  CREATE_CAMPAIGN_STEP,
  DELETE_CAMPAIGN,
} from '../constants'
import { Campaign } from '../types'

export const getCampaigns = () => ({
  type: GET_CAMPAIGNS,
  promise: apiGetCampaigns(),
})

export const getLightCampaigns = () => ({
  type: GET_CAMPAIGNS,
  promise: apiGetCampaigns(true),
})

export const getCampaignsForCompany = (companyId: string, preview: boolean = false) => ({
  type: GET_CAMPAIGNS,
  promise: apiGetCampaignsForCompany(companyId, preview),
})

export const createCampaign = (params: Campaign, meta: any) => ({
  meta,
  type: CREATE_CAMPAIGN,
  promise: apiCreateCampaign(params),
})

export const updateCampaign = (params: Campaign, meta: any) => ({
  meta,
  type: UPDATE_CAMPAIGN,
  promise: apiUpdateCampaign(params),
})

export const getCampaignStep = (id: string, meta: any) => ({
  meta,
  type: GET_CAMPAIGN_STEP,
  promise: apiGetCampaignStep(id)
})

export const createCampaignStep = (params: any, meta: any) => ({
  meta,
  type: CREATE_CAMPAIGN_STEP,
  promise: apiCreateCampaignStep(params),
})

export const deleteCampaign = (params: any) => ({
  type: DELETE_CAMPAIGN,
  promise: apiDeleteCampaign(params),
})
