import axios from 'axios'
import Cookie from 'js-cookie'
import { decamelizeKeys, camelizeKeys } from 'humps'
import { authTokenCookieKey } from './cookies'

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: Cookie.get(authTokenCookieKey),
    'content-type': 'application/json',
    "Accept": "application/json",
    'App-Name': 'TYAW-Manager'
  },
})

apiClient.interceptors.request.use(
  (request) => {
    if (request.data instanceof FormData) { return request }

    return ({
      ...request,
      data: decamelizeKeys(request.data),
    })
},
  error => Promise.reject(error),
)

apiClient.interceptors.response.use(
  response => ({
    ...response,
    data: camelizeKeys(response.data),
  }),
  (error) => Promise.reject(error)
)

export default apiClient
