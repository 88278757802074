import { handle } from 'redux-pack'
import { filter, map } from 'lodash'
import { CREATE_CAMPAIGN, GET_CAMPAIGNS, UPDATE_CAMPAIGN, DELETE_CAMPAIGN } from '../constants'
import { Campaign } from '../types'

interface IState {
  allCampaigns: Campaign[]
  loading: boolean
}

const initialState: IState = {
  allCampaigns: [],
  loading: false,
}

export default (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case CREATE_CAMPAIGN:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allCampaigns: [...prevState.allCampaigns, payload],
        }),
      })
    case UPDATE_CAMPAIGN:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allCampaigns: map(prevState.allCampaigns, (stateCampaign: any) =>
            stateCampaign.id === payload.id ? payload : stateCampaign,
          ),
        }),
      })
    case GET_CAMPAIGNS:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({ ...prevState, allCampaigns: payload }),
      })
    case DELETE_CAMPAIGN:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allCampaigns: filter(prevState.allCampaigns, (a: any) => a.id !== payload.id),
        }),
      })
    default:
      return state
  }
}
