import {
    getStatistic as apiGetStatistic,
    getStatistics as apiGetStatistics,
    getStatisticsForCompany as apiGetStatisticsForCompany
  } from '../api'
  
  import {
    GET_STATISTIC,
    GET_STATISTICS,
  } from '../constants'

export const getStatistic = (id: string, meta: any) => ({
    meta,
    type: GET_STATISTIC,
    promise: apiGetStatistic(id)
})

export const getStatistics = () => ({
    type: GET_STATISTICS,
    promise: apiGetStatistics()
})

export const getStatisticsForCompany = (companyId: string) => ({
  type: GET_STATISTICS,
  promise: apiGetStatisticsForCompany(companyId)
})