import { apiClient } from '../constants'

export const getCompany = (id: string) => apiClient.get(`/companies/${id}`).then(({ data }) => data)

export const getCompanies = () => apiClient.get('/companies').then(({ data }) => data)

export const createCompany = (company: FormData) =>
  apiClient.post('/companies', company, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(({ data }) => data)

export const updateCompany = (company: FormData) =>
  apiClient.patch(`/companies/${company.get('company[id]')}`, company, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(({ data }) => data)

export const deleteCompany = (id: string) =>
  apiClient.delete(`/companies/${id}`).then(({ data }) => data)
