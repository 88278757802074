import { handle } from 'redux-pack'
import { map, filter } from 'lodash'
import {
  GET_MOSAIC,
  GET_MOSAICS,
  CREATE_MOSAIC,
  UPDATE_MOSAIC,
  DELETE_MOSAIC,
} from '../constants'
import { Mosaic } from '../types'

interface IState {
  allMosaics: Mosaic[]
  mosaic: Mosaic
  loading: boolean
}

const initialState: IState = {
  allMosaics: [],
  mosaic: {},
  loading: false,
}

export default (state = initialState, action: any ) => {
  const { type, payload } = action
  switch (type) {
    case CREATE_MOSAIC:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allMosaics: [...prevState.allMosaics, payload],
          mosaic: payload
        })
      })
    case UPDATE_MOSAIC:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allMosaics: map(prevState.allMosaics, (mosaic: Mosaic) =>
            mosaic.id === payload.id ? payload : mosaic,
          ),
          mosaic: payload
        })
      })
    case GET_MOSAIC:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          mosaic: payload
        })
      })
    case GET_MOSAICS:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allMosaics: payload
        })
      })
    case DELETE_MOSAIC:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allMosaics: filter(prevState.allMosaics, (m: Mosaic) => {
            return (m.id !== payload.id)})
        })
      })
    default:
      return state

  }
}
