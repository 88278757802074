import { apiClient } from '../constants'
import { SurveyTarget, TemplateQuestion } from '../types'

export const createTemplateQuestion = (templateQuestion: TemplateQuestion) =>
  apiClient.post(`/template_questions`, { templateQuestion }).then(({ data }) => data)

export const getTemplateQuestions = (target: SurveyTarget, companyId?: string) =>
  apiClient.get('/template_questions', { params: { target, company_id: companyId } }).then(({ data }) => data)

export const unactiveTemplateQuestion = (id: string) =>
  apiClient
    .patch(`/template_questions/${id}`, { templateQuestion: { active: false } })
    .then(({ data }) => data)

export const updateTemplateQuestion = (id: string, templateQuestion: TemplateQuestion) =>
  apiClient.patch(`/template_questions/${id}`, { templateQuestion }).then(({ data }) => data)

export const batchUpdateTemplateQuestions = (ids: string[], templateQuestion: TemplateQuestion) =>
  apiClient.patch('/template_questions/batch_update', { ids, templateQuestion }).then(({ data }) => data)
