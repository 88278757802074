import React from 'react'
import { withRouter } from 'react-router'
import { NavLink } from 'react-router-dom'
// import Modal from 'react-modal'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'

import { find, get } from 'lodash'

import { Button } from 'tyaw-components'

import Icon from '../../components/icon'

import { Can } from '../../config/ability-context'
import { Company, Store, User, Mosaic, SelectOption } from '../../types'

import { MosaicRow, SignedInLayout, CompanySelect } from '../../components'

import {
  getMosaicsForCompany as getMosaicsForCompanyAction
} from '../../actions'

import './styles.sass'

interface IProps {
  user: User
  mosaics: Mosaic[]
  companies: Company[]
  getMosaics: (companyId: string) => void
}

interface IState {
  filteredCompany: SelectOption
}

class MosaicsScene extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props)

    let company: Company = {}

    if (props.user.company) {
      company = props.user.company
    } else if (props.mosaics.length > 0) {
      company = get(props.mosaics, '[0].company')
    }

    this.state = {
      filteredCompany: {
        value: get(company, 'id', ''),
        label: get(company, 'name', ''),
      }
    }
  }

  componentDidMount() {
    const { getMosaics } = this.props
    const { filteredCompany } = this.state

    if (filteredCompany.value !== '') {
      this.selectCompany(filteredCompany)
    }
    getMosaics(filteredCompany.value)
  }

  selectCompany(filteredCompany: SelectOption) {
    const companyId: string = get(filteredCompany, 'value')
    this.props.getMosaics(companyId)
    this.setState({filteredCompany})
  }

  renderHeader( ) {
    const { companies } = this.props
    const { filteredCompany } = this.state
    return(
    <div className="campaign-header">
      <div className="company-filter">
        <CompanySelect
          companies={companies}
          selectedCompany={filteredCompany}
          handleSelect={(company: SelectOption) => this.selectCompany(company)}
          hint={I18n.t('components.mosaic.companySelectHint')}
        />
      </div>
    </div>
    )
  }

  mosaicRow(mosaic: Mosaic) {
    const { companies } = this.props
    const company = find(companies, (c: Company) => c.id === get(mosaic, 'companyId') )

    return (
      <MosaicRow mosaic={mosaic} company={company} key={mosaic.id} />
    )
  }

  renderAddBtn() {
    const { user } = this.props
    const primaryColor = get(user, 'company.primaryColor')

    return (
      <NavLink
        to={{ pathname: '/mosaics/new' }}
      >
        <Button type="button" primaryColor={primaryColor}>
          <Icon name="duplicate" size={24} />
          {I18n.t('pages.mosaics.create')}
        </Button>
      </NavLink>
    )
  }

  renderHeaders() {
    return (
      <div className="mosaic-row">
        <Can I="see" this="companies">
          <span className="company">
            {I18n.t('models.user.company')}
          </span>
        </Can>

        <span className="title">
          {I18n.t('models.mosaic.title')}
        </span>

        <span className="share-link">
          {I18n.t('models.mosaic.shared')}
        </span>

        <span className="testimonies">
          {I18n.t('models.mosaic.testimonyCount')}
        </span>

        <span className="mosaic-hit">
          {I18n.t('models.mosaic.mosaicHit')}
        </span>

        <span className="actions">

        </span>

      </div>
    )
  }

  render() {
    const { mosaics } = this.props

    return (
      <SignedInLayout>
        <div className="mosaic-index page-container">
          <div className="header-bar">
            { this.renderAddBtn() }
          </div>
          {this.renderHeader()}

          <div className="page-sub-container">
            <h2>{I18n.t('pages.mosaics.title')}</h2>
            { this.renderHeaders() }
            {mosaics.map((m: Mosaic) => this.mosaicRow(m))}
          </div>
        </div>
      </SignedInLayout>
    )
  }
}


const mapStateToProps = () => ({
  mosaics: { allMosaics },
  user: { currentUser },
  companies: { allCompanies }
}: Store) => ({
  user: currentUser,
  mosaics: allMosaics,
  companies: allCompanies
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getMosaics: (companyId: string) => dispatch(getMosaicsForCompanyAction(companyId)),
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(MosaicsScene) as any)
