import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { Button, TextInput } from 'tyaw-components'
import queryString from 'query-string'
import { I18n } from 'react-redux-i18n'
import { toastr } from 'react-redux-toastr'

import { resetPassword } from '../../api'
import { LoginLayout } from '../../components'

import './styles.sass'

interface IProps {}

interface IState {
  password: string,
  passwordConfirmation: string
}

class ResetPasswordScene extends React.Component<IProps, IState> {
  token: string = ''

  constructor(props: IProps) {
    super(props)

    this.state = {
      password: '',
      passwordConfirmation: '',
    }
  }

  componentDidMount() {
    this.token = queryString.parse(location.search).reset_password_token as string
  }

  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    if (this.state.password !== this.state.passwordConfirmation) {
      toastr.error(
        I18n.t('alerts.resetPassword.unmatch.title'),
        I18n.t('alerts.resetPassword.unmatch.message')
      )
      this.setState({ passwordConfirmation: '' })
      this.setState({ password: '' })
      return false
    }

    resetPassword({
      ...this.state,
      resetPasswordToken: this.token,
    }).then((user) => {
      toastr.success(
        I18n.t('alerts.resetPassword.success.title'),
        I18n.t('alerts.resetPassword.success.message')
      )

      const email = queryString.stringify({ email: user.email })
      window.location = `/login?${email}` as any
    }).catch((error) => {
      let message = I18n.t('alerts.resetPassword.error.message')
      if (error.response) {
        message = error.response.data.errors[0]
      }

      toastr.error(
        I18n.t('alerts.resetPassword.error.title'),
        message
      )
    })

  }

  render() {
    const { password, passwordConfirmation } = this.state

    return (
      <LoginLayout>
        <h1>{I18n.t('pages.resetPassword.title')}</h1>
        <p>{I18n.t('pages.resetPassword.subtitle')}</p>
        <form onSubmit={e => this.handleSubmit(e)}>
          <div className="inputs-container">
            <TextInput
              label={I18n.t('models.user.newPassword')}
              placeholder=""
              onChangeText={(password: string) => this.setState({ password })}
              type="password"
              value={password}
            />
            <TextInput
              label={I18n.t('models.user.passwordConfirmation')}
              placeholder=""
              onChangeText={(passwordConfirmation: string) => this.setState({ passwordConfirmation })}
              type="password"
              value={passwordConfirmation}
            />
            <Button type="submit">{I18n.t(`pages.resetPassword.button`)}</Button>
            <Link
              className="login-page-link"
              to={{ pathname: '/login' }}
            >
              {I18n.t('actions.signIn')}
            </Link>
          </div>
        </form>
      </LoginLayout>
    )
  }
}

export default withRouter(ResetPasswordScene as any)
