import { decamelize } from 'humps'
import { forEach } from 'lodash'

export const appendObjectToFormData = (object: any, dataName: string, formData: FormData, skippedParams: string[]) => {

  forEach(Object.keys(object), (key: string) => {
    const value = object[key]

    if (skippedParams.indexOf(key) === -1 && (value || value === false) && value !== null) {
      formData.append(`${dataName}[${decamelize(key)}]`, value)
    }
  })

  return formData
}
