import { connect } from 'react-redux'

import { Navbar } from '../../components'
import { Store } from '../../types'

const mapStateToProps = ({ user: { currentUser } }: Store) => ({
  currentUser,
})

export default connect(mapStateToProps)(Navbar as any)
