import { combineReducers } from 'redux'
import { i18nReducer as i18n } from 'react-redux-i18n'
import { connectRouter } from 'connected-react-router'
import { reducer as toastrReducer } from 'react-redux-toastr'
import admins from './admins'
import campaigns from './campaigns'
import companies from './companies'
import managers from './managers'
import mosaics from './mosaics'
import statistics from './statistics'
import surveys from './surveys'
import questionTypes from './question-types'
import templateQuestions from './template-questions'
import user from './user'
import userSurveys from './user-surveys'

import { history } from '../constants'

export default combineReducers({
  admins,
  campaigns,
  companies,
  managers,
  mosaics,
  statistics,
  questionTypes,
  i18n,
  surveys,
  templateQuestions,
  user,
  userSurveys,
  toastr: toastrReducer,
  router: connectRouter(history),
})
