import { toastr } from 'react-redux-toastr'
import { I18n } from 'react-redux-i18n'

import { useRef } from 'react';

import { User } from '../types'
/*
  Pass a react referenced element, like:
    this.refs.yourElementRef
*/
export const copyToClipboard = (elt: any) => {
  elt.select()
  document.execCommand('copy')
  toastr.success(
    I18n.t('alerts.copy.title'),
    I18n.t('alerts.copy.message')
  )
}

export const getToken = (tokenName: string) => {
  const urlString = window.location.href
  const url = new URL(urlString)

  return url.searchParams.get(tokenName)
}

export const notification = (state: string, key: string, options: { apiErrors: string[] } = { apiErrors: [] }) => {
  switch(state) {
    case 'error':
      toastr.error(
        I18n.t(`alerts.${key}.error.title`),
        I18n.t(`alerts.${key}.error.message`, { error: options.apiErrors.join('; ') })
      )
      break
    case 'success':
      toastr.success(
        I18n.t(`alerts.${key}.success.title`),
        I18n.t(`alerts.${key}.success.message`)
      )
      break
    default:
      toastr.info(
        I18n.t(`alerts.${key}.info.title`),
        I18n.t(`alerts.${key}.info.message`)
      )
      break
  }
}

export const useComponentWillMount = (func: any) => {
  const willMount = useRef(true)

  if (willMount.current) {
    func()
  }

  willMount.current = false
}

export const redirectHome = (user: User) => {
  if (user.admin) {
    window.location = '/companies' as any
  } else {
    window.location = '/user_surveys' as any
  }
}
