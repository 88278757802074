import { toastr } from 'react-redux-toastr'
import {
  GET_MANAGERS,
  CREATE_MANAGER_INVITATION_TOKEN,
  GET_MANAGER_INVITATION_TOKENS,
  DELETE_MANAGERS,
  UPDATE_MANAGER_COMPANY,
} from '../constants'
import {
  getManagers as apiGetManagers,
  deleteUser as apiDeleteUser,
  createManagerInvitationToken as apiCreateManagerInvitationToken,
  getManagerInvitationTokens as apiGetManagerInvitationTokens,
  updateManagerCompany as apiUpdateManagerCompany,
} from '../api'

export const getManagers = () => ({
  type: GET_MANAGERS,
  promise: apiGetManagers(),
})

export const createManagerInvitationToken = (params: any) => ({
  meta: {
    onSuccess: () => {
      toastr.success('Invitation Envoyée', 'Votre invitation a bien été envoyée')
    },
    onFailure: (response: any) => {
      toastr.error('Erreur lors de votre invitation', response)
    },
  },
  type: CREATE_MANAGER_INVITATION_TOKEN,
  promise: apiCreateManagerInvitationToken(params),
})

export const getManagerInvitationTokens = () => ({
  type: GET_MANAGER_INVITATION_TOKENS,
  promise: apiGetManagerInvitationTokens(),
})

export const deleteManager = (userId: string, role: string = 'manager') => ({
  meta: {
    onSuccess: () => {
      toastr.success('Utilisateur supprimé', 'Votre utilisateur a bien été supprimé')
    },
    onFailure: (response: any) => {
      toastr.error('Erreur lors de la suppression', response)
    },
  },
  type: DELETE_MANAGERS,
  promise: apiDeleteUser(userId, role),
})

export const updateManagerCompany = (userId: string, companyId: string) => ({
  meta: {
    onSuccess: () => {
      toastr.success('Manager updaté', 'La companie de votre manager a bien été sauvegardé')
    },
    onFailure: (response: any) => {
      toastr.error('Erreur lors du update de votre companie', response)
    },
  },
  type: UPDATE_MANAGER_COMPANY,
  promise: apiUpdateManagerCompany(userId, companyId),
})
