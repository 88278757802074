import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'

/* redux */
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'

/* libs */
import { get, map, orderBy, filter, isEmpty } from 'lodash'
import { Button, TextInput, Loader } from 'tyaw-components'
import moment from 'moment'

import './styles.sass'

/* app */
import { notification } from '../../helpers/application-helper'
import { User, Store, Campaign, Guest, CampaignStep } from '../../types'
import { CampaignStepCard, SignedInLayout, InviteModal } from '../../components'

import {
  createCampaignStep,
  deleteCampaignStep,
  createCampaignUserSurvey,
  publishCampaign,
  updateCampaign,
  getCampaign
} from '../../api'

interface IProps extends RouteComponentProps<any> {
  user: User
}

interface IState {
  loading: Boolean
  displayModal: boolean
  days: number
  campaign: Campaign
}

class CampaignScene extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    const { match: { params: { id }}} = props

    super(props)

    this.state = {
      loading: true,
      campaign: { id },
      displayModal: false,
      days: 0
    }
  }

  componentDidMount() {
    getCampaign(get(this.state.campaign, 'id'))
      .then((campaign: Campaign) =>
        this.setState({campaign, loading: false})
      )
      .catch((err: any) => {
        notification('error', 'campaign.get')
        this.setState({loading: false})
      })
  }

  isPublished() {
    return !isEmpty(get(this.state.campaign, 'publishedAt'))
  }

  deleteStep(campaignStep: CampaignStep) {
    const { campaign } = this.state
    let steps = get(campaign, 'campaignSteps', [])

    deleteCampaignStep(get(campaignStep, 'id')).then(() => {
      steps = filter(steps, (step: CampaignStep) => step.id !== campaignStep.id)
      this.setState({
        campaign: { ...campaign, campaignSteps: steps }
      })
      notification('success', 'campaignStep.delete')
    }).catch(() => {
      notification('error', 'campaignStep.delete')
    })
  }

  async createStep() {
    const { days, campaign } = this.state
    const steps = get(campaign, 'campaignSteps', [])

    const campaignStep = await createCampaignStep({
      days: days * 93600,
      campaignId: get(campaign, 'id')
    })

    steps.push(campaignStep)

    this.setState({
      campaign: { ...campaign, campaignSteps: steps }
    })
  }

  publish() {
    const { campaign } = this.state

    this.setState({loading: true})

    publishCampaign(campaign)
      .then((campaign: Campaign) => {
        notification('success', 'campaign.publish')
        this.setState({campaign, loading: false})
      })
      .catch(() => {
        this.setState({loading: false})
        notification('error', 'campaign.publish')
      })
  }

  update() {
    const { campaign } = this.state

    updateCampaign(campaign)
      .then((campaign: Campaign) => {
        notification('success', 'campaign.update')
        this.setState({campaign})
      })
      .catch(() => {
        notification('error', 'campaign.update')
      })
  }

  async handleInvitationSubmit(guest: Guest) {
    const { campaign } = this.state

    try {
      await createCampaignUserSurvey({
        companyId: get(campaign, 'company.id'),
        jobTitle: get(guest, 'jobTitle'),
        campaignId: get(campaign, 'id'),
        memberAttributes: guest
      })

      notification('success', 'invitation')
    } catch (err) {
      notification('error', 'invitation')
    }
  }

  renderMenu() {
    const { user } = this.props
    const primaryColor = get(user, 'company.primaryColor')
    const publishedAt = get(this.state.campaign, 'publishedAt')

    let elt;

    if (this.isPublished()) {

      elt = <InviteModal
        primaryColor={primaryColor}
        handleInvitation={(guest: Guest) => this.handleInvitationSubmit(guest)}
      />

    } else {
      elt = <Button
          onClick={() => this.publish()}
          primaryColor={primaryColor}
        >
          {I18n.t('actions.publish')}
        </Button>
    }

    return (
      <div className="buttons-container">
        { this.isPublished() ?
          `${I18n.t('models.companySurvey.publishedAt')} ${moment(new Date(publishedAt)).format('LL')}`
          : null
        }
        <Button
          primaryColor={primaryColor}
          onClick={() => this.update()}
        >
          {I18n.t('actions.save')}
        </Button>
        {elt}
      </div>
    )
  }

  renderNewStep() {
    const { days } = this.state

    return (
      <div key="new-step" className="campaign-step-container new-step-container">
        <h2>{I18n.t('pages.campaign.titleAddStep')}</h2>
        <div className="campaign-step-form">
          <TextInput
            label={I18n.t('models.campaignStep.day', {days: days })}
            onChangeText={(days: number) => this.setState({ days })}
            type="number"
            className="white"
            value={days}
          />
          <div className="campaign-buttons-container">
            <Button onClick={() => this.createStep()}>
              {I18n.t('pages.campaign.buttonAddStep')}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { campaign, loading } = this.state
    const orderedSteps = orderBy(get(campaign, 'campaignSteps', []), 'days')

    if (loading) {
      return (
        <SignedInLayout>
          <Loader />
        </SignedInLayout>
      )
    }

    return (
      <SignedInLayout>
        <form className="horizontal">
          <TextInput
            label={I18n.t('models.campaign.title')}
            value={get(campaign, 'title')}
            className="white"
            onChangeText={(e: any) => this.setState(
              {
                campaign: { ...campaign, title: e }
              }
            )}
          />

          { this.renderMenu() }
        </form>
        <div className="campaign-steps-container">
          {map(orderedSteps, (campaignStep: any, idx: number) => (
              <div key={campaignStep.id} className="campaign-step-container">
                <CampaignStepCard
                  campaignStep={campaignStep}
                  order={idx + 1}
                  isPublished={this.isPublished()}
                  onDelete={(campaignStep: CampaignStep) => this.deleteStep(campaignStep)}
                />
              </div>
            ),
          )}
          { this.isPublished() ? null : this.renderNewStep() }
        </div>
      </SignedInLayout>
    )
  }
}

const mapStateToProps = ({
  user: { currentUser }
}: Store) => ({
  user: currentUser
})

export default withRouter(connect(
  mapStateToProps
)(CampaignScene) as any)
