import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { I18n } from 'react-redux-i18n'
import { toastr } from 'react-redux-toastr'

import history from 'history'
import queryString from 'query-string'

import { Button, TextInput } from 'tyaw-components'

import { LoginLayout } from '../../components'
import { signIn } from '../../api'
import { redirectHome } from '../../helpers/application-helper'

import './styles.sass'

interface IProps {
  history: history.History
}

interface IState {
  email: string
  password: string
}

class LoginScene extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    const email = process.env.NODE_ENV === 'development' ? 'tyaw+manager@derniercri.io' : ''
    const password = process.env.NODE_ENV === 'development' ? 'tyaw123' : ''

    this.state = { email, password }
  }

  componentDidMount() {
    const query = queryString.parse(location.search)
    const emailFromUrl: string = query.email as string
    const redirectedFromSignUp: boolean = (query.signed_up as string) === 'true'

    if (emailFromUrl){
      this.setState({ email: emailFromUrl })
    }

    if (redirectedFromSignUp) {
      toastr.info(
        I18n.t('alerts.login.signed_up.title'),
        I18n.t('alerts.login.signed_up.message')
      )
    }
  }

  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    signIn(this.state)
      .then((data) => {
        redirectHome(data.user)
      })
      .catch((err) => {
        toastr.error(
          I18n.t('alerts.login.error.title'),
          I18n.t('alerts.login.error.message')
        )
      })
  }

  render() {
    const { email, password } = this.state

    return (
      <LoginLayout>
        <h1 className="login-title">{I18n.t('pages.login.title')}</h1>
        <form onSubmit={e => this.handleSubmit(e)}>
          <div className="inputs-container">
            <TextInput
              label={I18n.t('models.user.email')}
              placeholder=""
              onChangeText={(email: string) => this.setState({ email })}
              value={email}
            />
            <TextInput
              label={I18n.t('models.user.password')}
              placeholder=""
              onChangeText={(password: string) => this.setState({ password })}
              type="password"
              value={password}
            />
            <Button type="submit">{I18n.t(`pages.login.button`)}</Button>
            <Link
              className="login-page-link"
              to={{ pathname: '/forgot-password' }}
            >
              {I18n.t('pages.login.link')}
            </Link>
          </div>
        </form>
      </LoginLayout>
    )
  }
}

export default withRouter(LoginScene as any)
