import React from 'react'
import { Dispatch } from 'redux'
import Select from 'react-select'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { filter, get } from 'lodash'
import { I18n } from 'react-redux-i18n'

import { Store, User, Statistic, SelectOption, Company } from '../../types'
import { SignedInLayout, CompanySelect } from '../../components'
import { ExportGridComponent } from '../../components/ag-grid'

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, LabelList, ResponsiveContainer } from 'recharts'

import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { GridReadyEvent, GridApi } from 'ag-grid-community/main.d'

import { getStatisticsForCompany } from '../../actions'

import './styles.sass'

/*
To do:
 - filter the title on mosaic and user survey with stats only
    --> refactoring needed to get all the stats data from a new route from backend api!
 - clean code
 - try to render the graph dynamic regarding the data sources
 - try to have generic code to get data sources
 - add groupBy and sumBy to get reporting by date for example?
 - create react components for graph and grid?
 - add graph/grid with emails details?
*/

interface IProps {
    user: User
    statistics: Statistic[]
    getStatistics: (companyId: string) => void
    selectedOption: SelectOption
    onGridReady: (event: GridReadyEvent) => void
    companies: Company[]
  }
  
  interface IState {
    selectedOption: SelectOption
    statistics: Statistic[]
    gridApi: GridApi | undefined
    filteredCompany: SelectOption
  }

class SurveysScene extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props)

    let company: Company = {}

    if (props.user.company) {
      company = props.user.company
    } else if (props.statistics.length > 0) {
      company = get(props.statistics, '[0].company')
    }

    this.state = {
      selectedOption: {
        value: '1',
        label: I18n.t('pages.statistics.graph1Xaxis1'),
        prop1: 'UserSurvey'
      },
      statistics: [],
      gridApi: undefined,
      filteredCompany: {
        value: get(company, 'id', ''),
        label: get(company, 'name', ''),
      }
    }
  }

  componentDidMount() {
    const { filteredCompany } = this.state
    const { getStatistics } = this.props

    if (filteredCompany.value !== '') {
      this.selectCompany(filteredCompany)
    }

    getStatistics(filteredCompany.value)
  }

  
  selectCompany(filteredCompany: SelectOption) {
    const companyId: string = get(filteredCompany, 'value')
    this.props.getStatistics(companyId)
    this.setState({filteredCompany})
  }

  
  handleChange(selectedOption: SelectOption) {
    this.setState({ selectedOption})
  }

  renderHeader() {
    const { companies } = this.props
    const { filteredCompany } = this.state
    return(
    <div className="campaign-header">
      <div className="company-filter">
        <CompanySelect
          companies={companies}
          selectedCompany={filteredCompany}
          handleSelect={(company: SelectOption) => this.selectCompany(company)}
          hint={I18n.t('components.statistic.companySelectHint')}
        />
      </div>
    </div>
    )
  }

  renderStatisticSelect() {
    const options = [
      { value: '0', label: I18n.t('pages.statistics.graph1Xaxis0'), prop1: 'Mosaic'},
      { value: '1', label: I18n.t('pages.statistics.graph1Xaxis1'), prop1: 'UserSurvey'},
      { value: '2', label: I18n.t('pages.statistics.graph1Xaxis2'), prop1: 'Video'},
      { value: '3', label: I18n.t('pages.statistics.graph1Xaxis3'), prop1: 'Video'},
      { value: '4', label: I18n.t('pages.statistics.graph1Xaxis4'), prop1: 'Video'}
    ]

    return (
      <div className="select-statistic">
          Sélection de la source de données:
        <Select
          defaultValue={ {value: '1', label: I18n.t('pages.statistics.graph1Xaxis1'), prop1: 'UserSurvey'}}
          options={ options }
          onChange={ (selectedOption: any) => this.handleChange(selectedOption) }
        />
      </div>
    )
  }

  onGridReady = (event: GridReadyEvent) => {
    this.setState({
      gridApi: event.api
    })
    event.api.sizeColumnsToFit()
  }

  onBtnExport = (event: GridReadyEvent) => {
    event.api.exportDataAsExcel()
  }

  renderGraph(dataStatsFiltered: any, statToDisplay: string) {
    return(
      <div className="StackedBarchart">
        <ResponsiveContainer width="90%" height={450}>
          <BarChart
              data={dataStatsFiltered}
              margin={{
                  top: 80,
                  right: 2,
                  left: 2,
                  bottom: 5,
              }}
              maxBarSize={100}
          >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="title">
                <Label value={this.state.selectedOption.label} offset={0} position="insideBottom"/>
              </XAxis>
              <YAxis label={{ value: I18n.t('pages.statistics.graph1Yaxis'), angle: -90, position: 'insideLeft' }} />
              <Tooltip />
              <Legend />
              <Bar name={I18n.t('pages.statistics.superAdmin')} dataKey={"hit.total.superadmin."+statToDisplay} fill="#103CF9" stackId="a"> </Bar>
              <Bar name={I18n.t('pages.statistics.admin')} dataKey={"hit.total.manager."+statToDisplay} fill="#3FEEE6" stackId="a"> </Bar>
              <Bar name={I18n.t('pages.statistics.advUser')} dataKey={"hit.total.advuser."+statToDisplay} fill="#55BCC9" stackId="a"> </Bar>
              <Bar name={I18n.t('pages.statistics.guest')} dataKey={"hit.total.guest."+statToDisplay} fill="#32BEEB" stackId="a"> </Bar>
              <Bar name={I18n.t('pages.statistics.user')} dataKey={"hit.total.user."+statToDisplay} fill="#1abc9c" stackId="a"> </Bar>
              <Bar name={I18n.t('pages.statistics.mosaic')} dataKey={"hit.total.mosaic."+statToDisplay} fill="#CAFAFE" stackId="a"> 
                <LabelList dataKey="hit.infos.title" position="top"/>
              </Bar>
            </BarChart>
        </ResponsiveContainer>
      </div>
     )
  }

  renderGridExport() {
    const { gridApi } = this.state

    if (!gridApi) {
      return null
    }
    return(
      <div className="downloadButton">
      <ExportGridComponent
          gridApi={gridApi}
        />
      </div>
    )
  }

  renderGrid(dataStatsFiltered: any, statToDisplay: string) {
    return(
    <div className="ag-theme-alpine" style={{height: 450, width: "90%"}}>
      <AgGridReact
        rowData={dataStatsFiltered}
        onGridReady={this.onGridReady}
        headerHeight={100}
        defaultColDef={{flex: 1, filter: true, resizable: true, sortable: true, headerComponentParams: {
          template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>',
          }
        }}
        >
        <AgGridColumn headerName={I18n.t('pages.statistics.company')} field="hit.infos.company" flex={1} ></AgGridColumn>
        <AgGridColumn headerName={I18n.t('pages.statistics.label')} field="hit.infos.title" width={400} ></AgGridColumn>
        <AgGridColumn headerName={I18n.t('pages.statistics.kpiView') +" - "+ I18n.t('pages.statistics.total')} field={"hit.total."+statToDisplay} flex={1}></AgGridColumn>
        <AgGridColumn headerName={I18n.t('pages.statistics.kpiView') +" - "+ I18n.t('pages.statistics.superAdmin')} field={"hit.total.superadmin."+statToDisplay} flex={1}></AgGridColumn>
        <AgGridColumn headerName={I18n.t('pages.statistics.kpiView') +" - "+ I18n.t('pages.statistics.admin')} field={"hit.total.manager."+statToDisplay} flex={1}></AgGridColumn>
        <AgGridColumn headerName={I18n.t('pages.statistics.kpiView') +" - "+ I18n.t('pages.statistics.advUser')} field={"hit.total.advuser."+statToDisplay} flex={1}></AgGridColumn>
        <AgGridColumn headerName={I18n.t('pages.statistics.kpiView') +" - "+ I18n.t('pages.statistics.guest')} field={"hit.total.guest."+statToDisplay} flex={1}></AgGridColumn>
        <AgGridColumn headerName={I18n.t('pages.statistics.kpiView') +" - "+ I18n.t('pages.statistics.user')} field={"hit.total.user."+statToDisplay} flex={1}></AgGridColumn>
        <AgGridColumn headerName={I18n.t('pages.statistics.kpiView') +" - "+ I18n.t('pages.statistics.mosaic')} field={"hit.total.mosaic."+statToDisplay} flex={1}></AgGridColumn>
      </AgGridReact>
    </div>
    )
  }

  render() {
    const dataStats = this.props.statistics

    let statToDisplay: string
    switch (this.state.selectedOption.value) {
      case '0':
        statToDisplay = 'hit'
        break
      case '1':
        statToDisplay = 'hit'
        break
      case '2':
        statToDisplay = 'read'
        break
      case '3':
        statToDisplay = 'readFull'
        break
      case '4':
        statToDisplay = 'download'
        break
      default:
        statToDisplay = 'hit'
    }

    const dataStatsFiltered = filter(dataStats, (statistics: any) => {
       return ( statistics && statistics.statisticableType === this.state.selectedOption.prop1 ) }
    )

    return (
      <SignedInLayout>
        {this.renderHeader()}
        {this.renderStatisticSelect()}
        {this.renderGraph(dataStatsFiltered, statToDisplay)}
        {this.renderGrid(dataStatsFiltered, statToDisplay)}
        {this.renderGridExport()}
      </SignedInLayout>
      )
  }

}

const mapStateToProps = () => ({
    user: { currentUser },
    statistics: { allStatistics },
    companies: { allCompanies }
  }: Store) => ({
    user: currentUser,
    statistics: allStatistics,
    companies: allCompanies
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getStatistics: (companyId: string) => dispatch(getStatisticsForCompany(companyId))
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
  )(SurveysScene) as any)
