import { UPDATE_USER, GET_USERS_COLLABORATOR } from '../constants'
import { User } from '../types'
import {
  getCollaborators as apiGetCollaborators
} from '../api'

export const updateUser = (payload: User) => ({
  payload,
  type: UPDATE_USER,
})

export const getCollaborators = () => ({
  type: GET_USERS_COLLABORATOR,
  promise: apiGetCollaborators(),
})
