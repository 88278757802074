import React from 'react'
import { Dispatch } from 'redux'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'

import { get } from 'lodash'

import { edit, trash } from '../../assets/icons'
import { CopyInput } from '../../components'

import './styles.sass'

// import styled, { css } from 'styled-components'

import { Can } from '../../config/ability-context'
import { Company, Mosaic, Store } from '../../types'

import {
  deleteMosaic as deleteMosaicAction
} from '../../actions'

interface IProps {
  mosaic: Mosaic
  company: Company
  deleteMosaic: (id: string) => void
}

const mosaicRow = (props: IProps) => {
  const { mosaic, company, deleteMosaic } = props

  const handleDelete = () => {
    if (window.confirm(I18n.t('alerts.mosaic.delete.confirm'))) {
      deleteMosaic(get(mosaic, 'id'))
    }
  }

  return (
    <div className="mosaic-row">
      <Can I="see" this="companies">
        <span className="company">
          {company ? company.name : null}
        </span>
      </Can>

      <span className="title">
        {mosaic.title}
      </span>

      <span className="share-link">
        { mosaic.shared ? <CopyInput value={`${window.location.origin}/mosaics/public?access_token=${mosaic.accessToken}`} /> : null }
      </span>

      <span className="testimonies">
        {mosaic.testimonyCount}
      </span>

      <span className="mosaic-hit">
        { get(mosaic, 'statistic.hit.total.hit', 0) }
      </span>

      <span className="actions">
        <NavLink to={`/mosaics/${mosaic.id}`} className="button-icon">
          <img src={edit} />
        </NavLink>
        <button
          className="button-icon"
          onClick={handleDelete}
        >
          <img src={trash} />
        </button>
      </span>

    </div>
  )
}


const mapStateToProps = ({
}: Store) => ({
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteMosaic: (id: string) => dispatch(deleteMosaicAction(id))
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(mosaicRow)
