import React, { useState } from 'react'
import { arrowDown } from '../../assets/icons'

import './styles.sass'

const HelpQuestion = ({ title: Title, children }: any) => {
  const [isOpen, setOpenState] = useState(false)

  return (
    <div className="help-question-card"  onClick={() => setOpenState(!isOpen)} >
      <Title/>
      {isOpen && children}
      <img className="help-question-icon" src={arrowDown} />
    </div>
  )
}

export default HelpQuestion
