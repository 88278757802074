import React from 'react'
import { withRouter } from 'react-router'
import { Button, TextInput } from 'tyaw-components'
import queryString from 'query-string'
import { I18n } from 'react-redux-i18n'
import { toastr } from 'react-redux-toastr'

import { signUp, getToken as apiGetToken } from '../../api'
import { LoginLayout } from '../../components'

interface IProps {}

interface IState {
  email: string
  password: string
}

class LoginScene extends React.Component<IProps, IState> {
  token: string = ''

  constructor(props: IProps) {
    super(props)

    this.state = {
      email: '',
      password: '',
    }
  }

  componentDidMount() {
    this.token = queryString.parse(location.search).invitation_token as string
    apiGetToken(this.token).then((data: any) => {
      if (data.userSignedUp) {
        const params = queryString.stringify({ email: data.email, signed_up: true })
        window.location = `/login?${params}` as any
      }
      this.setState({ email: data.email })
    })
  }

  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    signUp({
      ...this.state,
      managerInvitationToken: queryString.parse(location.search).invitation_token as string,
    }).then(() => {
      const params = queryString.stringify({ email: this.state.email })
      window.location = `/login?${params}` as any
    }).catch((error) => {
      let message = I18n.t('alerts.resetPassword.error.message')
      if (error.response) {
        message = error.response.data.errors[0]
      }

      toastr.error(
        I18n.t('alerts.resetPassword.error.title'),
        message
      )
    })
  }

  render() {
    const { email, password } = this.state

    return (
      <LoginLayout>
        <h1>{I18n.t('pages.signup.title')}</h1>
        <p>{I18n.t('pages.signup.subtitle')}</p>
        <p>{I18n.t('pages.resetPassword.subtitle')}</p>
        <form onSubmit={e => this.handleSubmit(e)}>
          <div className="inputs-container">
            <TextInput
              label={I18n.t('models.user.email')}
              placeholder=""
              onChangeText={(email: string) => this.setState({ email })}
              value={email}
            />
            <TextInput
              label={I18n.t('models.user.password')}
              placeholder=""
              onChangeText={(password: string) => this.setState({ password })}
              type="password"
              value={password}
            />
            <Button type="submit">{I18n.t(`pages.signup.button`)}</Button>
          </div>
        </form>
      </LoginLayout>
    )
  }
}

export default withRouter(LoginScene as any)
