import React, { useState } from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

/* redux */
import { I18n } from 'react-redux-i18n'

/* lib */
import { get } from 'lodash'
import Switch from 'react-switch'

import { UserSurvey, User } from '../../types'
import styled, { css } from 'styled-components'
import Icon from '../icon'
import { Can } from '../../config/ability-context'

import './styles.sass'

interface IProps {
  survey: UserSurvey
  onChangeOption: (state: boolean, optionChanged: string) => void
  key: string
  user: User
  displayEmails: boolean
}

interface ContainerProps {
  survey: UserSurvey
}

const Container = styled.div.attrs<IProps>(({ survey }: ContainerProps) => ({
  className: classnames('user-survey-card-component', get(survey, 'companySurvey.target', '')),
})) <ContainerProps>`
  ${props =>
    css`
      &.thank_you .title {
        color: ${props.theme.thankYouColor};
      }

      &.welcome .title {
        color: ${props.theme.welcomeColor};
      }
    `};
`

const SurveyCard = ({ survey, onChangeOption, user, displayEmails }: IProps) => {
  if (!survey) {
    return null
  }

  const [showTags, setShowTags] = useState(false)
  const [editable, setEditable] = useState(get(survey, 'editable', true))
  const [downloadable, setDownloadable] = useState(get(survey, 'downloadable', true))

  const tags = get(survey, 'tagNames', [])

  const email = get(survey, 'user.email', '')

  const surveyLabel = get(survey, 'companySurvey.label', '')

  const renderShowAllButton = () => {
    if (showTags) {
      return <li className="show-more" onClick={() => setShowTags(false)}>{I18n.t('actions.showLess')}</li>
    } else {
      return <li className="show-more" onClick={() => setShowTags(true)}>{I18n.t('actions.showMore')}</li>
    }
  }

  const renderTags = () => {
    return (
      <div className='tags-wrapper'>
        <Icon name='tags' />
        <ul>
          { tags.map((tag: string, index: number) => {
            if (!showTags && index > 2) {
              return
            }
            return <li key={index}>{tag}</li>
          }) }
          { tags.length > 3 ? renderShowAllButton() : null }
        </ul>
      </div>
    )
  }

  const switchEditable = (checked: boolean) => {
    setEditable(checked)
    onChangeOption(checked, 'editable')
  }

  const switchDownloadable = (checked: boolean) => {
    setDownloadable(checked)
    onChangeOption(checked, 'downloadable')
  }

  const renderImg = () => {
    return (
      <img src={survey.videoThumbnailSrc} className="survey-thumbnail" alt="Video thumbnail" />
    )
  }

  const renderOptions = () => {
    return (
      <div className="options">
        <Icon name="settings" />
        <label>
          <Switch
            onChange={switchEditable}
            checked={editable}
            handleDiameter={15}
            height={20}
            width={40}
            onColor="#006AB4"
            offColor="#8593a6"
          />

          <small>
            { editable ? I18n.t('components.surveyCard.editable') : I18n.t('components.surveyCard.notEditable') }
          </small>
        </label>
        <br />
        <label>
          <Switch
            onChange={switchDownloadable}
            checked={downloadable}
            handleDiameter={15}
            height={20}
            width={40}
            onColor="#006AB4"
            offColor="#8593a6"
          />

          <small>
            { downloadable ? I18n.t('components.surveyCard.downloadable') : I18n.t('components.surveyCard.notDownloadable') }
          </small>
        </label>
      </div>
    )
  }

  const renderEmails = () => {
    if ( displayEmails ) {
      return(
        <small>
        { I18n.t('pages.userSurvey.contactMe') } <a href={"mailto: " + email + "?subject=" + surveyLabel }>{email}</a>
        </small>
      )
    }
  }

  return (
    <Container survey={survey}>
      <div className="survey-row">
        <div className="survey-info">
          <Link className="title" to={`/user_surveys/${survey.id}`}>
            {surveyLabel}
            <small>{`Organisation - ${get(survey, 'companySurvey.company.name', '')}`}</small>
          </Link>
          <span className="name">
            {`${get(survey, 'user.firstName', '')} ${get(survey, 'user.lastName', '')}`}
          </span>
          {renderEmails()}
          <span className="job-title">{get(survey, 'jobTitle', '')}</span>
          <span className="page-hit">{I18n.t('models.mosaic.mosaicHit')}: {get(survey, 'statisticHit.total.hit', 0) }</span>
          <Can I="access" this="userInvitationLink">
            <a href={get(survey, 'userInvitationLink') } className="user-survey-link" target="_blank">{I18n.t('pages.userSurvey.surveylink')} </a>
          </Can>
        </div>
        { survey.videoThumbnailSrc === null ? null : renderImg() }
      </div>
      { renderOptions() }
      { tags.length > 0 ? renderTags() : null }
    </Container>
  )
}
export default SurveyCard
