import React, { useEffect } from 'react'

/* redux */
import { I18n } from 'react-redux-i18n'

/* AG Grid */
import { FirstDataRenderedEvent } from 'ag-grid-community/main.d'
import { AgGridReact, AgGridColumn } from 'ag-grid-react'

/* libs */
import { upperCase, pull, map, get, find } from 'lodash'

/* app */
import { booleanOptions } from '../../helpers/ag-grid-helper'

import { LOCALES } from '../../i18n'

import { TemplateQuestion, Question, QuestionType, SelectOption, GridSharedProps } from '../../types'

import { booleanCellRenderer, gridSkeleton } from '../../components/ag-grid'

interface IProps extends GridSharedProps {
  data: {
    locale: string,
    rows: TemplateQuestion[] | Question[],
    questionTypes: QuestionType[],
    selectedIds?: string[]
  }
  mode: string // can be "manage", "select", "edit"
  handleDelete?: (question: TemplateQuestion | Question) => Promise<boolean>
}

/*
  manage mode = a super admin is managing template questions (TemplateQuestion Type)
  select mode = a manager is selecting template questions to create a survey
  edit mode = a manager is editing questions copied from templates for a survey (Question Type)
*/

let appliedFilter: string = 'all' // from official documentation, should be put outside of component :-/

const questionsGrid = (props: IProps) => {
  const { gridReady, rowDragEnd, refreshCells, firstDataRendered, handleDelete, gridApi, data, mode, options } = props
  const editable: boolean = mode !== 'select'
  const managing: boolean = mode === 'manage'
  const editing: boolean  = mode === 'edit'

  const { locale, questionTypes, selectedIds } = data

  const categoryOptions: SelectOption[] = map(I18n.t('categories'), (label: string, value: string) => {
    return { value, label }
  })
  const questionTypesOptions: SelectOption[] = map(questionTypes, (qt: QuestionType) => {
    return { value: qt.id, label: qt.name }
  })

  const translatedColumns: JSX.Element[] = []

  useEffect(() => {
    if (!gridApi) {
      return
    }

    if (selectedIds && gridApi) {
      selectedIds.forEach((id) => {
        const rowNode = gridApi.getRowNode(id)
        if (rowNode) {
          rowNode.setSelected(true)
        }
      })
    }
  }, [selectedIds, gridApi !== undefined])

  const applyDefaultFilter = (event: FirstDataRenderedEvent) => {
    if (!editing && event.api) {
      event.api.setFilterModel({
        'active': {
          filterType: 'text',
          type: 'equals',
          filter: 'true'
        }
      })
      event.api.onFilterChanged()
    }
  }

  const applyFilter = (filterType: string): void => {
    if (gridApi) {
      appliedFilter = filterType
      gridApi.onFilterChanged()
    }
  }

  pull(LOCALES, locale).forEach((lang: string) => {
    translatedColumns.push(
      <AgGridColumn
        field={`labelI18n.${lang}`}
        key={`labelI18n-${lang}`}
        headerName={`${upperCase(lang)} - ${I18n.t('models.question.label')}`}
        editable={editable}
      />,
      <AgGridColumn
        field={`tooltipI18n.${lang}`}
        key={`tooltipI18n-${lang}`}
        headerName={`${upperCase(lang)} - ${I18n.t('models.question.tooltip')}`}
        editable={editable}
      />
    )
  })

  const renderActions = () => {
    return (
      <AgGridColumn
        colId="actions"
        cellRenderer="actionsRenderer"
        cellRendererParams={
          {
            showButtons: { edit: editable, delete: handleDelete !== undefined },
            confirmText: I18n.t('models.question.delete.alert'),
            onDeleteAction: (question: TemplateQuestion) => { handleDelete && handleDelete(question) }
          }
        }
        headerName=""
        suppressSizeToFit={true}
        editable={false}
        filter={false}
        sortable={false}
        resizable={false}
        width={100}
      ></AgGridColumn>
    )
  }

  const renderFilters = () => {
     return (
      <div className="custom-filters">
        {I18n.t('actions.filter')}
        <label>
          <input type="radio" name="filter" id="all-templates" onChange={() => applyFilter('all')} />
          {I18n.t('components.agGrid.all')}
        </label>
        <label>
          <input type="radio" name="filter" id="selected-templates" onChange={() => applyFilter('selected')} />
          {I18n.t('components.agGrid.yourSelection')}
        </label>
      </div>
    )
  }

  return (

    <div className="ag-theme-alpine ag-grid">
      { editing ? null : renderFilters() }

      <AgGridReact
        gridOptions={options}
        enableCellTextSelection={false}
        onGridReady={gridReady}
        rowData={data.rows}
        onRowDataUpdated={refreshCells}
        onFilterChanged={refreshCells}
        onFirstDataRendered={(event: FirstDataRenderedEvent) => {
          firstDataRendered(event)
          applyDefaultFilter(event)
        }}
        onRowDragEnd={rowDragEnd}
        isExternalFilterPresent={() => (appliedFilter !== undefined && appliedFilter !== 'all') }
        doesExternalFilterPass={(node: any) => node.selected }
      >
        <AgGridColumn
          colId="first-column"
          field="order"
          headerName="#"
          maxWidth={80}
          valueGetter={(params) => {
            const rowNum = params!.node!.rowIndex
            return rowNum! + 1
          }}
          editable={!editable}
          checkboxSelection={ !editing }
          headerCheckboxSelection={ !editing }
          headerCheckboxSelectionFilteredOnly={ !editing }
          rowDrag={ editing }
        ></AgGridColumn>

        <AgGridColumn
          field={`labelI18n.${locale}`}
          headerName={I18n.t('models.question.label')}
          suppressSizeToFit={ true }
          width={500}
          tooltipField="label"
          editable={editable}
        ></AgGridColumn>

        <AgGridColumn
          field={`tooltipI18n.${locale}`}
          headerName={I18n.t('models.question.tooltip')}
          editable={editable}
        ></AgGridColumn>

        { translatedColumns }

        <AgGridColumn
          field="target"
          headerName={I18n.t('models.question.category')}
          cellEditor="reactSelectCellEditor"
          cellEditorParams={{options: categoryOptions}}
          cellRenderer={(cell: any) => {
            return get(I18n.t('categories'), cell.value)
          }}
          editable={managing}
          hide={!managing}
        ></AgGridColumn>

        <AgGridColumn
          field="questionTypeId"
          cellEditor="reactSelectCellEditor"
          cellEditorParams={{options: questionTypesOptions, valueFrom: 'label'}}
          valueGetter={(params) => {
            const questionType: QuestionType = find(questionTypes, (qt: QuestionType) => {return qt.id === params.data.questionTypeId})
            return get(questionType, "name")
          }}
          headerName={I18n.t('models.question.type')}
          editable={managing}
        ></AgGridColumn>

        <AgGridColumn
          field="active"
          headerName={I18n.t('models.question.active')}
          cellRenderer={booleanCellRenderer}
          cellEditor="reactSelectCellEditor"
          cellEditorParams={{
            options: booleanOptions()
          }}
          minWidth={100}
          width={100}
          editable={managing}
          hide={!managing}
        ></AgGridColumn>

        <AgGridColumn
          field="updatedAt"
          headerName={I18n.t('models.question.updatedAt')}
          editable={false}
          hide={!managing}
        ></AgGridColumn>

        { renderActions() }

      </AgGridReact>
    </div>
  )
}

export default gridSkeleton(questionsGrid)
