import { apiClient } from '../constants'
import { Hierarchy } from '../types'

export const getHierarchies = (companyId: string) =>
  apiClient.get(`/companies/${companyId}/hierarchies`).then(({ data }) => data)

export const deleteHierarchy = (companyId: string, hierarchyId: number) =>
  apiClient.delete(`/companies/${companyId}/hierarchies/${hierarchyId}`).then(({ data }) => data)

export const updateHierarchy = (companyId: string, hierarchyId: number, hierarchy: Hierarchy) =>
  apiClient.patch(`/companies/${companyId}/hierarchies/${hierarchyId}`, { hierarchy }).then(({ data }) => data)

export const createHierarchy = (companyId: string, hierarchy: Hierarchy) =>
  apiClient.post(`/companies/${companyId}/hierarchies`, { hierarchy }).then(({ data }) => data)
