import React, { Fragment, useState, useRef, useEffect } from 'react'
import { Translate, I18n } from 'react-redux-i18n'

import Modal from 'react-modal'
import Select from 'react-select'

import { get, find, map, set, isEmpty } from 'lodash'

import { Button, TextInput } from 'tyaw-components'

import { FileInput } from '..'
import { Tile, UserSurvey, SelectOption, Mosaic } from '../../types'
import { plus } from '../../assets/images'


import './styles.sass'

interface IProps {
  width: number
  height: number
  maxPosition: number
  key: string
  tile: Tile
  mode: string // accepts two modes: show and edit
  surveys?: UserSurvey[]
  mosaics?: Mosaic[],
  handleTileChange?: (oldTile: Tile, tile: Tile) => void
  onRemoveImage?: (tile: Tile) => void
  onRemoveTile?: (tile: Tile) => void
}

const surveyOption = (survey: UserSurvey) => {
  if (survey) {
    return `${get(survey, 'companySurvey.label', '')} - ${get(survey, 'user.firstName', '')} ${get(survey, 'user.lastName', '')}`
  } else {
    return ''
  }
}

const getBackgroundImage = (tile: Tile, selectedSurvey: UserSurvey, mode: string): string => {
  let url: string = ''
  const videoThumbnailSrc: string = get(selectedSurvey, 'videoThumbnailSrc')

  if ( !isEmpty(get(tile, 'pictureUrl')) ) {
    url = get(tile, 'pictureUrl')
  } else if (videoThumbnailSrc) {
    url = videoThumbnailSrc
  }

  return url
}

const isValid = (tile: Tile, attribute: string): Boolean => {
  return !isEmpty(get(tile, attribute, null))
}

const tileComponent = (props: IProps) => {
  const { width, height, mode, maxPosition, surveys, mosaics, handleTileChange, onRemoveImage, onRemoveTile } = props
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [tile, setTile] = useState<Tile>(props.tile)
  const [hasErrors, setHasErrors] = useState<Boolean>(false)
  const currentSurvey = find(surveys, { id: tile.userSurveyId })
  const currentMosaic = find(mosaics, { id: tile.linkedMosaicId })
  const fileField = useRef()

  console.log(mosaics)

  useEffect(() => {
    setTile(props.tile)
  }, [props.tile])

  let customStyle = {
    width: `${width}px`,
    minHeight: `${height}px`,
    backgroundImage: `url("${getBackgroundImage(tile, currentSurvey, mode)}")`
  }

  Modal.setAppElement('body')

  const cancelChanges = () => {
    setTile(props.tile)
    setHasErrors(false)
    setOpenModal(false)
  }

  const removeTile = async() => {
    onRemoveTile && onRemoveTile(tile)
  }

  const displayErrors = (attribute: string): Boolean => {
    return hasErrors && !isValid(tile, attribute)
  }

  const emptyImageField = () => {
    set(fileField, 'current.value', null)

    /* Otherwise, we just want to empty or restore the file input */
    setTile({
      ...tile,
      picture: undefined,
      uploadedPicture: false,
      pictureUrl: props.tile.pictureUrl
    })
  }

  const generateCaption = (survey: UserSurvey) => {
    let caption: string = `${get(survey, 'user.firstName')} ${get(survey, 'user.lastName')}\n`
    caption += `${get(survey, 'jobTitle')} - ${get(survey, 'companySurvey.company.name')}`

    return caption
  }

  const removeTileImage = async () => {
    /* If the tile is already saved in DB and the image wasn't updated we want to delete the image */
    if (tile.createdAt && tile.pictureUrl === props.tile.pictureUrl) {
      onRemoveImage && onRemoveImage(tile)
    } else {
      emptyImageField()
    }
  }

  const isFormValid = (): Boolean => {
    let valid: Boolean;

    switch (get(tile, 'linkType', 'survey')) {
      case 'mosaic':
        valid = isValid(tile, 'linkedMosaicId')
        break;
      case 'external_link':
        valid = isValid(tile, 'link')
        break;
      default:
        valid = isValid(tile, 'userSurveyId')
        break;
    }

    if (valid) {
      setHasErrors(false)
    } else {
      setHasErrors(true)
    }
    return valid
  }

  const saveTile = (evt: any) => {
    evt.preventDefault()
    if (isFormValid()) {
      handleTileChange && handleTileChange(props.tile, tile)
      setOpenModal(false)
    }
  }

  const surveySelected = (survey: any) => {
    const updateCaption = isEmpty(tile.caption) || tile.caption === generateCaption(currentSurvey)

    setTile({
      ...tile,
      caption: updateCaption ? generateCaption(survey.details) : tile.caption,
      userSurveyId: survey.value
    })
  }

  const openTestimony = () => {
    let url: string;
    let win: Window | null;

    switch (get(tile, 'linkType', 'survey')) {
      case 'mosaic':
        url = get(tile, 'linkedMosaicUrl')
        win = window.open(url, '_top')
      case 'external_link':
        url = get(tile, 'link')
        win = window.open(url, '_blank')
      default:
        url = get(tile, 'userSurveyUrl')
        win = window.open(url, 'mosaicTestimony', `left=50,top=50,width=${window.innerWidth},height=${window.innerHeight}`)
    }

    win && win.focus()
  }

  const renderCaption = () => {
    return (
      <div className="caption">
        { get(tile, 'caption') }
      </div>
    )
  }

  const renderSelectTypeField = () => {
    const options: SelectOption[] = map(['survey', 'external_link', 'mosaic'], (elt: string) => {
      return {
        value: elt,
        label: I18n.t(`models.tile.linkTypeOptions.${elt}`)
      }
    })

    const value: string = get(tile, 'linkType', 'survey')
    const currentValue: SelectOption = {
      value: value,
      label: I18n.t(`models.tile.linkTypeOptions.${value}`)
    }

    return (
      <div
        className="link-type-select-wrapper"
      >
        <label htmlFor={`${tile.key}-link-type`}>
          <Translate value="models.tile.linkType" /> *
        </label>
        <Select
          id={`${tile.key}-link-type`}
          value={currentValue}
          options={options}
          onChange={(option: any) => {
            setTile({ ...tile, linkType: option.value })
          }}
        />
      </div>
    )
  }

  const renderMainField = () => {
    switch (get(tile, 'linkType', 'survey')) {
      case 'mosaic':
        return renderSelectMosaic();
      case 'external_link':
        return renderLinkField();
      default:
        return renderSelectSurvey();
    }
  }

  const renderSelectMosaic = () => {
    const mosaicsOptions: SelectOption[] = map(mosaics, (mosaic: Mosaic) => {
      return {
        details: mosaic,
        value: get(mosaic, 'id'),
        label: get(mosaic, 'title')
      }
    })
    const currentValue: SelectOption = {
      details: currentMosaic,
      value: get(tile, 'linkedMosaicId', ''),
      label: get(currentMosaic, 'title')
    }

    return (
      <div
        className={`mosaic-select-wrapper ${displayErrors('linkedMosaicId') ? 'error' : ''}`}
      >
        <label htmlFor={`${tile.key}-mosaic`}>
          <Translate value="models.tile.linkedMosaic" /> *
        </label>
        <Select
          id={`${tile.key}-mosaic`}
          value={currentValue}
          options={mosaicsOptions}
          onChange={(mosaicOption: any) => {
            setTile({
              ...tile,
              linkedMosaicId: mosaicOption.value
            })
          }}
        />
        {
          displayErrors('linkedMosaicId') ?
            <small className="error">
              <Translate value="models.tile.errors.emptyMosaic" />
            </small> : null
        }
      </div>
    )
  }

  const renderSelectSurvey = () => {
    const surveysOptions: SelectOption[] = map(surveys, (survey: UserSurvey) => {
      return {
        details: survey,
        value: get(survey, 'id'),
        label: surveyOption(survey)
      }
    })
    const currentValue: SelectOption = {
      details: currentSurvey,
      value: get(tile, 'userSurveyId', ''),
      label: surveyOption(currentSurvey)
    }

    return (
      <div
        className={`survey-select-wrapper ${displayErrors('userSurveyId') ? 'error' : ''}`}
      >
        <label htmlFor={`${tile.key}-survey`}>
          <Translate value="models.tile.userSurvey" /> *
        </label>
        <Select
          id={`${tile.key}-survey`}
          value={currentValue}
          options={surveysOptions}
          onChange={surveySelected}
        />
        {
          displayErrors('userSurveyId') ?
            <small className="error">
              <Translate value="models.tile.errors.emptySurvey" />
            </small> : null
        }
      </div>
    )
  }

  const renderLinkField = () => {
    return (
      <div className="link-field-wrapper">
        <TextInput
          label={`${I18n.t('models.tile.link')} *`}
          placeholder="https://www.example.com/article.html"
          value={get(tile, 'link', '')}
          onChangeText={(e: any) =>
            setTile({ ...tile, link: e })
          }
          className={
            displayErrors('link') ? 'error' : null
          }
        />
        {
          displayErrors('link') ?
            <small className="error">
              <Translate value="models.tile.errors.emptyLink" />
            </small> : null
        }
        <small>{I18n.t('components.tile.linkHint')}</small>
      </div>
    )
  }

  const renderRemoveLink = () => {
    return (
      <a href="#" onClick={() => removeTileImage()}>
        <small>
          { (tile.pictureUrl !== props.tile.pictureUrl) ? I18n.t('components.tile.cancelImage') : I18n.t('components.tile.removeImage') }
        </small>
      </a>
    )
  }

  const renderModal = () => {
    return (
      <Modal
        isOpen={openModal}
        onRequestClose={(e: any) => saveTile(e)}
        key={`${tile.key}-modal`}
      >
        <form className="modal-container tile-modal" onSubmit={saveTile}>
          <h2><Translate value="components.tile.title" /></h2>

          { renderSelectTypeField() }
          <br />
          { renderMainField() }

          <FileInput
            ref={fileField}
            fileName="picture"
            fileUrl={get(tile, 'uploadedPicture') ? tile.pictureUrl : undefined}
            currentFile={tile.picture}
            handleChange={(e: any) => {
              const file = e.target.files[0];
              setTile({
                ...tile,
                picture: file as Blob,
                pictureUrl: URL.createObjectURL(file),
                uploadedPicture: true
              })
            }}
            id={`${tile.key}-picture`}
          />
          { get(tile, 'uploadedPicture') ? renderRemoveLink() : <small>{I18n.t('components.tile.pictureHint')}</small> }

          <div className="options-wrapper">
            <div className="text-input-component with-label">
              <label className="text-input-label">
                <Translate value="models.tile.caption" />
              </label>
              <textarea
                className="text-input-content"
                value={get(tile, 'caption', '')}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setTile({ ...tile, caption: e.target.value })
                }
              />
            </div>

            <TextInput
              label={`${I18n.t('models.tile.position')} *`}
              type="number"
              value={get(tile, 'position')}
              onChangeText={(e: any) => {
                if (e >= 1 && e <= maxPosition)
                  setTile({ ...tile, position: e })
              }}
            />
          </div>

          <div className="buttons">
            <Button type="submit">
              {I18n.t('actions.validate')}
            </Button>

            <Button onClick={() => cancelChanges()} className="button-danger">
              {I18n.t('actions.cancel')}
            </Button>

            <Button onClick={() => removeTile()} className="button-danger">
              {I18n.t('actions.delete')}
            </Button>
          </div>
        </form>
      </Modal>
    )
  }

  return (
    <Fragment>
      { openModal ? renderModal() : null }
      <div
        className={`frame ${mode}-frame`}
        id={`frame-${tile.key}`}
        style={customStyle}
        onClick={() => mode === 'edit' ? setOpenModal(!openModal) : openTestimony()}
      >
        { (tile.pictureUrl || tile.caption || mode !== 'edit') ? null : <img src={plus} /> }
        { tile.caption ? renderCaption() : null }
      </div>
    </Fragment>
  )
}


export default tileComponent