import React from 'react'
import ReactQuill from 'react-quill'

import { initializeQuill } from '../../helpers/editor-helper'
import './styles.sass'
import 'react-quill/dist/quill.snow.css'

import {useState} from 'react'

interface IProps {
  label: string
  attributeId: string
  value: string
  handleChange: (text: any) => void
  displayed: boolean
}

const quillOptions = initializeQuill()

const textEditor = ({ label, attributeId, handleChange, value, displayed }: IProps) => {

  const [isDisplayed, setDisplayedState] = useState(displayed)

  let displayIcon: string = isDisplayed ? '-' : '+'

  const hideDiv = (idToHide: string) => {
      
    if ( !isDisplayed ) {
      setDisplayedState(true)
      displayIcon = '-'
    } else {
      setDisplayedState(false)
      displayIcon = '+'
    }

}

  return (
    <div className='textarea-card'>
      <label className='textarea-label' htmlFor={attributeId}>
        {label}
        <button className='button-display' onClick={() => hideDiv(attributeId)}>{displayIcon}</button>
      </label>
      {isDisplayed
        ? <div>
          <ReactQuill
          modules={quillOptions.modulesQuill}
          formats={quillOptions.formatsQuill}
          className='textarea-input'
          id={attributeId}
          onChange={handleChange}
          value={value}
          />
        </div>
        : <div></div>
      }
    </div>
  )
}

export default textEditor
