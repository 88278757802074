import React from 'react'

import { TreeData, TreeNode } from 'react-dropdown-tree-select'

import { I18n } from 'react-redux-i18n'
import { get } from 'lodash'

import Icon from '../icon'
import { Company } from '../../types'

import './styles.sass'

interface IProps {
  tags: TreeData,
  selectedTags?: TreeNode[]
  company?: Company
}

const tagsList = ({tags, selectedTags, company}: IProps) => {

  if (!selectedTags || selectedTags.length === 0) {
    return null
  }

  const primaryColor = get(company, 'primaryColor', '')
  const list: any = []

  selectedTags.forEach((tag: TreeNode) => {
    const parent = get(tags, tag.rootPath)
    const mainColor = parent.color === null ? primaryColor : parent.color
    if (parent) {
      list.push(
        <li
          key={tag.value}
          style={{
            backgroundColor: mainColor
          }}
        >
          <span className="selected-tag-parent">
            {parent.label}
          </span>
          <span
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.7)'
            }}
            className="selected-tag-name"
          >
            {tag.label}
          </span>
        </li>
      )
    }
  })

  return (
    <div className="selected-tags-wrapper">
      <ul className="tags-list">
        <li className="tags-label"><Icon name="tags" />{I18n.t(`components.sidebar.steps.tags`)}</li>
        { list }
      </ul>
    </div>
  )
}

export default tagsList
