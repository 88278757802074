import {
  getMosaic as apiGetMosaic,
  getMosaicFromToken as apiGetMosaicFromToken,
  getMosaicsForCompany as apiGetMosaicsForCompany,
  getMosaics as apiGetMosaics,
  createMosaic as apiCreateMosaic,
  updateMosaic as apiUpdateMosaic,
  removeTileImage as apiRemoveTileImage,
  deleteMosaic as apiDeleteMosaic,
  removeTile as apiDeleteTile
} from '../api'

import {
  GET_MOSAIC,
  GET_MOSAICS,
  CREATE_MOSAIC,
  UPDATE_MOSAIC,
  DELETE_MOSAIC
} from '../constants'

import { Tile } from '../types'

export const getMosaic = (id: string, meta: any) => ({
  meta,
  type: GET_MOSAIC,
  promise: apiGetMosaic(id)
})

export const getMosaicFromToken = (token: string, meta: any) => ({
  meta,
  type: GET_MOSAIC,
  promise: apiGetMosaicFromToken(token)
})

export const getMosaics = () => ({
  type: GET_MOSAICS,
  promise: apiGetMosaics()
})

export const getMosaicsForCompany = (companyId: string) => ({
  type: GET_MOSAICS,
  promise: apiGetMosaicsForCompany(companyId),
})

export const createMosaic = (params: FormData, meta: any) => ({
  meta,
  type: CREATE_MOSAIC,
  promise: apiCreateMosaic(params)
})

export const updateMosaic = (params: FormData, id: string, meta: any) => ({
  meta,
  type: UPDATE_MOSAIC,
  promise: apiUpdateMosaic(params, id)
})

export const removeTileImage = (mosaicId: string, tile: Tile, meta: any) => ({
  meta,
  type: UPDATE_MOSAIC,
  promise: apiRemoveTileImage(mosaicId, tile)
})

export const removeTile = (mosaicId: string, tile: Tile, meta: any) => ({
  type: UPDATE_MOSAIC,
  promise: apiDeleteTile(mosaicId, tile)
})

export const deleteMosaic = (id: string) => ({
  type: DELETE_MOSAIC,
  promise: apiDeleteMosaic(id),
})
