import { appendObjectToFormData } from './normalizer_methods'
import { Company } from '../types'

export const formattedDataCompany = (state: Company, id?: string) => {
  const formData = new FormData()
  const skippedParams = ['createdAt', 'updatedAt', 'backgroundUrl', 'logoUrl', 'options', 'emails']

  appendObjectToFormData(state, 'company', formData, skippedParams)

  if (state.emails) {
    appendObjectToFormData(state.emails, 'company[emails]', formData, [])
  }

  return formData
}
