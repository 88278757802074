import { handle } from 'redux-pack'
import {
  GET_STATISTIC,
  GET_STATISTICS,

} from '../constants'
import { Statistic } from '../types'

interface IState {
  allStatistics: Statistic[]
  statistic: Statistic
  loading: boolean
}

const initialState: IState = {
  allStatistics: [],
  statistic: {},
  loading: false,
}

export default (state = initialState, action: any ) => {
  const { type, payload } = action
  switch (type) {
    case GET_STATISTIC:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          statistic: payload
        })
      })
    case GET_STATISTICS:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allStatistics: payload
        })
      })

    default:
      return state

  }
}
