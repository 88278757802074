import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { Translate, I18n } from 'react-redux-i18n'
import { toastr } from 'react-redux-toastr'

import { Button, TextInput } from 'tyaw-components'

import { LoginLayout } from '../../components'
import { forgotPassword } from '../../api'

interface IProps {}

interface IState {
  email: string
}

class ForgotPasswordScene extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    const email = process.env.NODE_ENV === 'development' ? 'tyaw+manager@derniercri.io' : ''

    this.state = { email }
  }

  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    forgotPassword(this.state)
      .then((data) => {
        toastr.success(
          I18n.t('alerts.forgotPassword.success.title'),
          I18n.t('alerts.forgotPassword.success.message')
        )
      }).catch((err) => {
        toastr.error(
          I18n.t('alerts.forgotPassword.error.title'),
          I18n.t('alerts.forgotPassword.error.message')
        )
      })
  }

  render() {
    const { email } = this.state

    return (
      <LoginLayout>
        <h1 className="login-title">
          <Translate value="pages.forgotPassword.title"/>
        </h1>
        <form onSubmit={e => this.handleSubmit(e)}>
          <div className="inputs-container">
            <TextInput
              label={<Translate value="models.user.email"/>}
              placeholder=""
              onChangeText={(email: string) => this.setState({ email })}
              value={email}
            />
            <Button type="submit">
              <Translate value="pages.forgotPassword.button"/>
            </Button>
            <Link
              className="login-page-link"
              to={{ pathname: '/login' }}
            >
              <Translate value="actions.signIn"/>
            </Link>
          </div>
        </form>
      </LoginLayout>
    )
  }
}

export default withRouter(ForgotPasswordScene as any)
