import { apiClient } from '../constants'
import { User } from '../types/user'
import { decamelizeKeys } from 'humps'

export const getCurrentUser = () => apiClient.get('/user').then(({ data }) => data)

export const updateUser = (user: User) =>
  apiClient.patch('/user', { user }).then(({ data }) => data)

export const updateUserCompany = (user: any) =>
  apiClient.patch('/user', { user }).then(({ data }) => data)

export const getCompanyUsers = (companyId: string) =>
  apiClient.get('/users', { params: { company_id: companyId }}).then(({ data }) => data)

export const getCollaborators = () =>
  apiClient.get('/user_invitation_tokens').then(({ data }) => data)

export const deleteUserInvitationToken = (invitationTokenId: string) =>
  apiClient.delete(`/user_invitation_tokens/${invitationTokenId}`).then(({ data }) => data)

export const deleteUser = (userId: string, infoType: string, companyId?: string) =>
  apiClient.delete(`/users/${userId}`, decamelizeKeys({ params: {infoType, companyId}}) ).then(({ data }) => data)
