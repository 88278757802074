import { handle } from 'redux-pack'
import { map } from 'lodash'
import { GET_SURVEYS, CREATE_SURVEY, UPDATE_SURVEY } from '../constants'
import { Survey } from '../types'

interface IState {
  allSurveys: Survey[]
  loading: boolean
}

const initialState: IState = {
  allSurveys: [],
  loading: false,
}

export default (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case CREATE_SURVEY:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allSurveys: [
            ...prevState.allSurveys,
            payload,
          ],
        }),
      })
    case UPDATE_SURVEY:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allSurveys: map(prevState.allSurveys, (stateSurvey: any) =>
            stateSurvey.id === payload.id ? payload : stateSurvey),
        }),
      })
    case GET_SURVEYS:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({ ...prevState, allSurveys: payload }),
      })
    default:
      return state
  }
}
