import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import Select from 'react-select'

import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { I18n } from 'react-redux-i18n'
import { get, isEmpty, map } from 'lodash'
import { Button, Loader, TextInput } from 'tyaw-components'

import { toastr } from 'react-redux-toastr'

import './styles.sass'

import {
  createSurvey as createSurveyAction,
  createCampaign as createCampaignAction,
} from '../../actions'
import {
  Campaign,
  CampaignStep,
  Company,
  SelectOption,
  User,
  Store,
  Survey,
  SurveyTarget
} from '../../types'
import { SignedInLayout } from '../../components'
import { campaignUrl } from '../../helpers/campaign-helper'

interface IProps extends RouteComponentProps<any> {
  user: User
  companyId: string
  companies: Company[]
  surveys: Survey[]
  loading: boolean
  createSurvey: (survey: Survey, meta: any) => void
  createCampaign: (survey: Campaign, meta: any) => void
}

interface IState {
  survey: Survey | null
  templateSurveys: Survey[]
  target: SurveyTarget
  campaignStep: CampaignStep | null
  label: string
  selectedCompany: SelectOption
}

class NewCampaignScene extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    const { match: { params: { target } } } = props

    this.state = {
      target,
      survey: null,
      templateSurveys: [],
      campaignStep: null,
      label: '',
      selectedCompany: {label: '', value: ''}
    }
  }

  saveSurvey() {
    const { companyId, createSurvey, createCampaign, history } = this.props
    const { survey, label, selectedCompany, target } = this.state

    const setCompanyId = isEmpty(companyId) ? selectedCompany.value : companyId

    createSurvey(
      {
        ...survey,
        label,
        target,
        companyId: setCompanyId,
      } as Survey,
      {
        onSuccess: (resp: any) =>
          createCampaign(
            {
              company_id: get(resp, 'company.id'),
              target: target,
              title: label,
              campaign_steps_attributes: [
                {
                  days: 0,
                  company_survey_id: get(resp, 'id'),
                },
              ],
            },
            {
              onSuccess: (newCampaign: Campaign) => {
                history.push(campaignUrl(newCampaign))
                toastr.success('Questionnaire créé', 'Votre questionnaire a bien été créé')
              },
            },
          ),
        onFailure: () => {
          toastr.error('Erreur', 'Erreur lors de la création, veuillez choisir une Organisation')
        }
      },
    )
  }

  switchTarget() {
    const { history } = this.props
    const { target } = this.state
    const newTarget = target === 'welcome' ? 'thank_you' : 'welcome'
    history.replace(`/new-campaign/${newTarget}`)
    this.setState({ target: newTarget })
  }

  handleChange(option: SelectOption) {
    this.setState({ selectedCompany: option })
  }

  render() {
    const { companies, user, loading } = this.props
    const { label, selectedCompany, target } = this.state
    const primaryColor = get(user, 'company.primaryColor')

    if (loading) {
      return (
        <SignedInLayout>
          <Loader />
        </SignedInLayout>
      )
    }

    return (
      <SignedInLayout>
        <h2>
          {I18n.t('pages.campaignStep.title.create')}
          {I18n.t(`categories.${target}`)}
        </h2>
        <small className="switch" onClick={() => this.switchTarget()}>{I18n.t(`pages.campaignStep.switch.${target}`)}</small>
        <form>
          <TextInput
            label={I18n.t("models.companySurvey.label")}
            placeholder=""
            onChangeText={(newLabel: string) => this.setState({ label: newLabel })}
            className="white"
            value={label}
          />
          {get(user, 'admin') ? (
            <div className="select-container">
              <label>{I18n.t('pages.campaignStep.chooseCompany')}</label>
              <Select
                onChange={(e: any) => this.handleChange(e)}
                options={map(companies,
                  (company: Company) => {
                    return ({label: company.name, value: company.id})
                  })
                }
                value={selectedCompany}
              />
            </div>
          ) : null}

          <div className="buttons-container">

            <Button
              key="save"
              type="button"
              onClick={() => this.saveSurvey()}
              primaryColor={primaryColor}
            >
              {I18n.t('actions.create')}
            </Button>

          </div>
        </form>
      </SignedInLayout>
    )
  }
}

const mapStateToProps = () => ({
  surveys: { allSurveys },
  companies: { allCompanies, loading },
  user: { currentUser }
}: Store) => ({
  companyId: get(currentUser, 'company.id'),
  surveys: allSurveys,
  companies: allCompanies,
  user: currentUser,
  loading: loading
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createSurvey: (data: Survey, meta: any) => dispatch(createSurveyAction(data, meta)),
  createCampaign: (data: Campaign, meta: any) => dispatch(createCampaignAction(data, meta)),
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewCampaignScene) as any)
