import React, { useState, forwardRef, useImperativeHandle } from 'react'
import Select from 'react-select'

import { find, get } from 'lodash'

import { SelectOption } from '../../types'

interface IProps {
  options: SelectOption[]
  value: string
  valueFrom: string
}

import './styles.scss'

const reactSelectCellEditor = (props: IProps, ref: any) => {
  const { value, options } = props
  const valueFrom: string = get(props, 'valueFrom', 'value')

  const initialOption: SelectOption | undefined = find(props.options, (option: any) => {
    return value && get(option, valueFrom) === value.toString()
  })

  const [current, setCurrent] = useState<SelectOption | undefined>(initialOption)

  const handleChange = (selected: SelectOption) => {
    setCurrent(selected)
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => { return get(current, 'value', '') },
      isPopup: () => { return false }
    }
  })

  return (
    <div>
      <Select
        classNamePrefix="ag-grid-select"
        onChange={(e: any) => {handleChange(e)}}
        options={options}
        value={current}
        menuPlacement="auto"
        menuPortalTarget={document.body}
      />
    </div>
  )
}

export default forwardRef(reactSelectCellEditor)
