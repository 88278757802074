import React, { useState, Fragment, useEffect } from 'react'
import Modal from 'react-modal'

import { I18n } from 'react-redux-i18n'

import { Loader, Button } from 'tyaw-components'
import Select from 'react-select'
import { GroupedOption, SelectOption, User } from '../../types'
import { getCompanyUsers } from '../../api'
import { forEach, get, map } from 'lodash'

import './styles.sass'

interface IProps {
  currentOwner: User
  primaryColor: string
  companyId: string
  handleOwnerChange: (newOwner: User) => void
}

const changeSurveyOwnerModal = ({ primaryColor, currentOwner, companyId, handleOwnerChange }: IProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [updating, setUpdating] = useState<boolean>(false)
  const [owner, setOwner] = useState<SelectOption | undefined>(undefined)
  const [possibleOwners, setPossibleOwners] = useState<User[]>([])

  let ownerOptions: GroupedOption[] = [];

  useEffect(() => {
    if (companyId) {
      getCompanyUsers(companyId).then((data: User[]) => {
        setPossibleOwners(data)
      })
    }
  }, [companyId])

  useEffect(() => {
    if (currentOwner) {
      setOwner({
        details: currentOwner,
        value: get(currentOwner, 'id', ''),
        label: get(currentOwner, 'fullName')
      })
    }
  }, [currentOwner])

  forEach(possibleOwners, (users: User[], label: string) => {
    ownerOptions.push({
      label: I18n.t(`components.changeSurveyOwnerModal.${label}`),
      options: map(users, (user: User) => {
        return {
          details: user,
          value: get(user, 'id'),
          label: get(user, 'fullName')
        }
      })
    })
  })

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setUpdating(true)
    if (owner) {
      await handleOwnerChange(owner.details)
    }
    setUpdating(false)
    setOpenModal(false)
  }

  const formatGroupLabel = (data: any) => (
    <div className="select-group">
      <span>{data.label}</span>
      <span className="select-group__badge">{data.options.length}</span>
    </div>
  )

  const formatOptionLabel = (data: any) => {
    if (data.details) {
      return (<div className="select-option">
        <span>{data.label}</span>
        <small>({ data.details.email })</small>
      </div>)
    } else {
      return data.label
    }
  }

  return (
    <Fragment>
      <div className='change-owner'>
        <Button
          type="button"
          primaryColor={primaryColor}
          className="button-small"
          onClick={() => setOpenModal(true)}
        >
          {I18n.t('pages.changeSurveyOwner.button')}
        </Button>
      </div>

      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        key="modal-change-owner"
      >
        <div className="modal-container">
          { updating ? <Loader /> : null }
          <h2>{I18n.t(`pages.changeSurveyOwner.title`)}</h2>
          <p>{I18n.t(`pages.changeSurveyOwner.subTitle`)}</p>

          <form onSubmit={(e: any) => handleSubmit(e)}>
            <div className='select-container'>
              <Select
                value={owner}
                options={ownerOptions}
                formatGroupLabel={formatGroupLabel}
                formatOptionLabel={formatOptionLabel}
                onChange={(newOwner: any) => {
                  setOwner(newOwner)
                }}
              />
            </div>

            <Button type="submit" primaryColor={primaryColor}>
              {I18n.t(`pages.changeSurveyOwner.button`)}
            </Button>
          </form>
        </div>
      </Modal>
    </Fragment>
  )
}

export default changeSurveyOwnerModal
