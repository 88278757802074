import {
  getTemplateQuestions as apiGetTemplateQuestions,
  unactiveTemplateQuestion as apiunactiveTemplateQuestion,
  updateTemplateQuestion as apiUpdateTemplateQuestion,
  createTemplateQuestion as apiCreateTemplateQuestion,
  batchUpdateTemplateQuestions as apiBatchUpdateTemplateQuestions
} from '../api'
import {
  GET_TEMPLATE_QUESTIONS,
  UNACTIVE_TEMPLATE_QUESTIONS,
  UPDATE_TEMPLATE_QUESTION,
  BATCH_UPDATE_TEMPLATE_QUESTION,
  CREATE_TEMPLATE_QUESTION,
} from '../constants'
import { SurveyTarget, TemplateQuestion } from '../types'

export const getTemplateQuestions = (params?: SurveyTarget, companyId?: string) => ({
  type: GET_TEMPLATE_QUESTIONS,
  promise: apiGetTemplateQuestions(params, companyId),
})

export const unactiveTemplateQuestion = (id: string) => ({
  type: UNACTIVE_TEMPLATE_QUESTIONS,
  promise: apiunactiveTemplateQuestion(id),
})

export const updateTemplateQuestion = (id: string, data: TemplateQuestion, meta: any) => ({
  meta,
  type: UPDATE_TEMPLATE_QUESTION,
  promise: apiUpdateTemplateQuestion(id, data),
})

export const batchUpdateTemplateQuestions = (ids: string[], data: TemplateQuestion, meta: any) => ({
  meta,
  type: BATCH_UPDATE_TEMPLATE_QUESTION,
  promise: apiBatchUpdateTemplateQuestions(ids, data),
})

export const createTemplateQuestion = (data: TemplateQuestion, meta: any) => ({
  meta,
  type: CREATE_TEMPLATE_QUESTION,
  promise: apiCreateTemplateQuestion(data),
})
