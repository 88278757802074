import React, { useState, Fragment } from 'react'
import Modal from 'react-modal'

import { get } from 'lodash'
import { I18n } from 'react-redux-i18n'

import { Loader, TextInput, Button } from 'tyaw-components'

import { Guest } from '../../types'

interface IProps {
  primaryColor: string
  handleInvitation: (guest: Guest) => void
}

const inviteModal = ({ primaryColor, handleInvitation }: IProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [inviting, setInviting] = useState<boolean>(false)
  const [guest, setGuest] = useState<Guest>({})

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setInviting(true)
    await handleInvitation(guest)
    setInviting(false)
    setOpenModal(false)
  }

  return (
    <Fragment>
      <Button
        type="button"
        onClick={() => setOpenModal(true)}
        primaryColor={primaryColor}
      >
        {I18n.t('actions.invite')}
      </Button>
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        key="modal-invitation"
      >
        <div className="modal-container">
          { inviting ? <Loader /> : null }
          <h2>{I18n.t('components.inviteModal.title')}</h2>

          <form onSubmit={(e: any) => handleSubmit(e)}>
            <TextInput
              placeholder={I18n.t('models.guest.firstName')}
              value={get(guest, 'firstName', '')}
              onChangeText={(e: any) =>
                setGuest({...guest, firstName: e })
              }
            />
            <TextInput
              placeholder={I18n.t('models.guest.lastName')}
              value={get(guest, 'lastName', '')}
              onChangeText={(e: any) =>
                setGuest({...guest, lastName: e })
              }
            />
            <TextInput
              placeholder={I18n.t('models.guest.email')}
              value={get(guest, 'email', '')}
              onChangeText={(e: any) =>
                setGuest({...guest, email: e })
              }
            />
            <TextInput
              placeholder={I18n.t('models.guest.jobTitle')}
              value={get(guest, 'jobTitle', '')}
              onChangeText={(e: any) =>
                setGuest({...guest, jobTitle: e })
              }
            />
            <Button type="submit" primaryColor={primaryColor}>
              {I18n.t('actions.validate')}
            </Button>
          </form>
        </div>
      </Modal>
    </Fragment>
  )
}

export default inviteModal
