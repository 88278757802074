import React from 'react'
import { I18n } from 'react-redux-i18n'

import { uploadIcon } from '../../assets/icons'

import './styles.sass'

interface IProps {
  fileName: string
  fileUrl?: string
  currentFile?: Blob | null
  id?: string
  ref?: any
  handleChange: (e: any) => void
}

const fileInput = React.forwardRef(({ fileName, fileUrl, currentFile, handleChange, id }: IProps, forwardedRef: any) => {
  if (!id){
    id = fileName
  }

  return (
    <div className="file-card">
      <label htmlFor={id}>{I18n.t(`components.fileInput.${fileName}`)}</label>
      <label className="file-input-format">{I18n.t('components.fileInput.imgFormat')}</label>
      <div className="file-input-container">
        <img className="file-input-icon" src={uploadIcon} />
        <input
          ref={forwardedRef}
          type="file"
          id={id}
          name={fileName}
          accept="image/png, image/jpeg"
          onChange={handleChange}
        />
        <p>{I18n.t(`components.fileInput.fileText`)}</p>
        <img className="file-image" src={currentFile ? URL.createObjectURL(currentFile) : fileUrl} />
      </div>
    </div>
  )
})

export default fileInput
