import React, { useEffect, useState } from 'react'
import Select from 'react-select'

/* redux */
import { I18n } from 'react-redux-i18n'

/* lib */
import { find, get } from 'lodash'
import { Button } from 'tyaw-components'

/* Ag Grid */
import { GridApi, ColDef, ColGroupDef } from 'ag-grid-community/main.d'

/* app */
import { SelectOption } from '../../types'

import { trash } from '../../assets/icons'

import './styles.scss'

interface IProps {
  gridApi: GridApi
  fields: string[]
  mode: string // can be 'manage', 'select'
  onChange?: (rows: any[], field: string, newValue: any) => void
  onDelete?: (rows: any[], gridApi: GridApi) => void
  onSelect?: (rows: any[]) => void
}

const batchActionsComponent = (props: IProps) => {
  const { gridApi, fields, onChange, onDelete, onSelect, mode } = props

  const [selectedRows, setSelectedRows] = useState<any[]>(gridApi.getSelectedRows())

  const onRowSelection = (evt: any) => {
    setSelectedRows(gridApi.getSelectedRows())
  }

  const selectRows = () => {
    onSelect && onSelect(selectedRows)
  }

  const updateRows = (field: string, selectedValue: SelectOption) => {
    onChange && onChange(selectedRows, field, get(selectedValue, 'value'))
  }

  const deleteRows = () => {
    onDelete && onDelete(selectedRows, gridApi)
  }

  const renderSelect = (column: ColDef, field: string) => {
    return <Select
      onChange={(e: any) => updateRows(field, e)}
      options={get(column, 'cellEditorParams.options') as SelectOption[]}
      menuPlacement="auto"
      key={field}
      placeholder={get(column, 'headerName')}
      className="batch-select"
    />
  }

  const renderField = (field: string) => {
    const column: ColDef = find(gridApi.getColumnDefs(), (colDef: ColDef | ColGroupDef) => get(colDef, 'field') === field ) as ColDef

    if (column.cellEditor === "reactSelectCellEditor") {
      return renderSelect(column, field)
    }
  }

  useEffect(() => {
    gridApi.addEventListener('selectionChanged', onRowSelection)

    return () => {
      gridApi.removeEventListener('selectionChanged', onRowSelection)
    }
  }, [])

  if (!selectedRows || selectedRows.length === 0) {
    return null
  }

  if (mode === "manage") {
    return (
      <div className="batch-edit-container">
        <p>
          {I18n.t("components.agGrid.batchEdition")}
          <br />
          <small>{ selectedRows.length } {I18n.t("components.agGrid.selectedQuestions")}</small>
        </p>
        { fields.map(renderField) }
        <button
          className="button-icon"
          onClick={deleteRows}
        >
          <img src={trash} />
        </button>
      </div>
    )
  } else {
    return (
      <div className="batch-select-container">
        <small>{ selectedRows.length } {I18n.t("components.agGrid.selectedQuestions")}</small>
        <Button
          type="button"
          onClick={() => selectRows()}
        >
          {I18n.t('actions.select')}
        </Button>
      </div>
    )
  }
}

export default batchActionsComponent
