import {
  getQuestionTypes as apiGetQuestionTypes
} from '../api'
import {
  GET_QUESTION_TYPES
} from '../constants'

export const getQuestionTypes = () => ({
  type: GET_QUESTION_TYPES,
  promise: apiGetQuestionTypes(),
})
