import React from 'react'

import { camelize } from 'humps'
import { Link } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'
import moment from 'moment'
import 'moment/locale/fr' 
import { get } from 'lodash'
import styled, { css } from 'styled-components'

import './styles.sass'

import { duplicate as duplicateIcon, trashWhite as deleteIcon } from '../../assets/icons'
import { Campaign } from '../../types'
import { campaignUrl } from '../../helpers/campaign-helper'

interface IProps {
  campaign: Campaign
  onDuplicate: (campaign: Campaign) => void
  onDelete: (campaign: Campaign) => void
  admin?: boolean
}

interface StyledProps {
  target: string
}

const Container = styled.div.attrs<StyledProps>(() => ({
  className: 'campaign-card-component',
}))<StyledProps>`
  border-radius: 8px
  color: #fff
  background: yellow
  display: flex
  flex-direction: column
  margin: 1rem 0
  padding: 2rem

  ${props =>
    css`
      background: ${props.theme[`${camelize(get(props, 'target'))}Color`]};
    `};`

const CampaignCard = ({ campaign, onDuplicate, onDelete, admin }: IProps) => {
  moment.locale('fr')
  const thankYou = get(campaign, 'target') === 'thank_you'
  const publicationDate = thankYou
    ? get(campaign, 'campaignSteps[0].companySurvey.publishedAt')
    : campaign.publishedAt
  const updatedDate = thankYou
  ? get(campaign, 'campaignSteps[0].companySurvey.updatedAt')
  : campaign.updatedAt
  const title = thankYou ? get(campaign, 'campaignSteps[0].companySurvey.label') : campaign.title

  return (
    <Container target={get(campaign, 'target')}>
      <div className="topInfos">
        {publicationDate
          ? `Publié le ${moment(new Date(publicationDate)).format('DD MMMM YYYY')}`
          : `En attente de publication  (mis à jour le ${moment(new Date(updatedDate)).format('DD MMMM YYYY')} )`
          }
        <span className="companyName">
          {get(campaign, 'company.name')}
        </span>
      </div>
      <Link className="title" to={campaignUrl(campaign)}>
        {title}
      </Link>
      <div className="actions">
        <button className="duplicate-button" onClick={() => onDuplicate(campaign)}>
          <img src={duplicateIcon} />
          {I18n.t('components.campaign.card.button')}
        </button>
        {admin ? (
          <button className="delete-button" onClick={() => onDelete(campaign)}>
            <img src={deleteIcon} />
            {I18n.t('components.campaign.card.buttonDelete')}
          </button>
        ) : null}
      </div>
    </Container>
  )
}

export default CampaignCard
