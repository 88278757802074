import React, { Fragment } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { NavLink } from 'react-router-dom'
// import Cookie from 'js-cookie'
// import { authTokenCookieKey } from '../../constants/cookies'

/* redux */
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'

/* libs */
import { get, map, filter, maxBy, find } from 'lodash'
import { Can } from '../../config/ability-context'

import { Button, TextInput, Checkbox, Loader } from 'tyaw-components'

/* app */
import { notification } from '../../helpers/application-helper'
import { Company, Store, User, Mosaic, UserSurvey, Tile, SelectOption } from '../../types'

import { formattedDataMosaic } from '../../normalizers/mosaic_normalizer'
import { SignedInLayout, MosaicView, CopyInput, CompanySelect } from '../../components'

import { pickerColor } from '../../assets/icons'

import { downloadMosaic, removeTile } from '../../api'


import {
  getMosaic    as getMosaicAction,
  getCompanies as getCompaniesAction,
  updateMosaic as updateMosaicAction,
  createMosaic as createMosaicAction,
  removeTileImage as removeTileImageAction,
  getUserSurveysForCompany,
  getMosaicsForCompany as getMosaicsForCompanyAction
} from '../../actions'

import './styles.sass'

interface IProps extends RouteComponentProps<any> {
  user: User
  companies: Company[]
  mosaic: Mosaic
  loading: Boolean
  mosaics: Mosaic[]
  validatedSurveys: UserSurvey[]
  getMosaic: (id: string, meta: any) => void
  getMosaics: (companyId: string) => void
  createMosaic: (mosaic: FormData, meta: any) => void
  updateMosaic: (mosaic: FormData, id: string, meta: any) => void
  getCompanies: () => void
  getSurveys: (companyId: string) => void
  removeTileImage: (mosaicId: string, tile: Tile, meta: any) => void
}

interface IState {
  status: string,
  editedMosaic: Mosaic,
  company: Company,
  selectedCompany: SelectOption
}

class MosaicScene extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    const { match: { params: { id }} } = props

    super(props)

    this.state = {
      status: (id === 'new') ? 'create' : 'edit',
      editedMosaic: {
        tileWidth: 250,
        tileHeight: 150
      },
      company: {},
      selectedCompany: {
        value: '',
        label: ''
      }
    }
  }

  componentDidMount() {
    const { match: { params: { id }}, user, getMosaic } = this.props
    const { status } = this.state

    if (status === 'edit') {
      getMosaic(id, {
        onSuccess: (resp: any) => {
          this.setStateFromApi(resp)
        }
      })
    } else if (status === 'create' && user.company) {
      this.selectCompany({
        value: get(user, 'company.id'),
        label: get(user, 'company.name')
      })
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const { status } = this.state
    const { user } = this.props

    if (status === 'create' && user !== prevProps.user) {

      if (user.company) {
        this.selectCompany({
          value: get(user, 'company.id'),
          label: get(user, 'company.name')
        })
      }
    }
  }

  /*
    We want to check if the save won't delete any tiles
    It does when testimonyCount is < than tiles max position
  */
  confirmSave() {
    const { editedMosaic } = this.state
    const maxTile = maxBy(editedMosaic.tiles, 'position')
    const maxPosition = get(maxTile, 'position', 0)

    if (get(editedMosaic, 'testimonyCount') < maxPosition ) {
      if (window.confirm(I18n.t('alerts.mosaic.edit.confirm'))) {
        return true
      } else {
        this.setState({
          editedMosaic: { ...editedMosaic, testimonyCount: maxPosition }
        })
        return false
      }
    } else {
      return true
    }
  }

  changeTilePosition(oldTile: Tile, newPosition: string) {
    const { editedMosaic } = this.state
    let tiles = get(editedMosaic, 'tiles', [])
    const oldPos = get(oldTile, 'position')
    const pos = parseInt(newPosition)
    let tileToModify: Tile

    if (pos < 1) {
      oldTile.position = 1
      return
    }
    if (pos > get(editedMosaic, 'testimonyCount')) {
      oldTile.position = get(editedMosaic, 'testimonyCount')
      return
    }

    if ( pos < oldPos ) {
      // move down
      for(let i: number = oldPos - 1; i >= pos; i-- ) {
        tileToModify = find(tiles, { position: i })

        if (tileToModify) {
          tileToModify.position = i + 1
        }
      }
    } else if (pos > oldPos) {
      // move up
      for(let i: number = oldPos + 1; i <= pos; i++ ) {
        tileToModify = find(tiles, { position: i })

        if (tileToModify) {
          tileToModify.position = i - 1
        }
      }
    }

    oldTile.position = pos

    // this.setState({
    //   editedMosaic: {...editedMosaic, tiles: tiles }
    // })
  }

  onRemoveTileImage(tile: Tile): Promise<Boolean> {
    const { removeTileImage } = this.props
    const { editedMosaic } = this.state

    return new Promise((resolve, reject) => {
      if (window.confirm(I18n.t('alerts.mosaic.removeTileImage.confirm'))) {
        removeTileImage(get(editedMosaic, 'id'), tile, {
          onSuccess: (resp: any) => {
            this.setStateFromApi(resp)
            notification('success', 'mosaic.removeTileImage')

            resolve(true)
          },
          onFailure: (resp: any, x: any) => {
            notification('error', 'mosaic.removeTileImage')

            reject(false)
          }
        })
      } else {
        reject(false)
      }
    })

  }

  onRemoveTile(tile: Tile): Promise<Boolean> {
    const { editedMosaic } = this.state

    return new Promise((resolve, reject) => {
      if (window.confirm(I18n.t('alerts.tile.delete.confirm'))) {
        removeTile(get(editedMosaic, 'id'), tile)
          .then((mosaic: Mosaic) => {
            this.setStateFromApi(mosaic)
            notification('success', 'mosaic.removeTile')

            resolve(true)
          }).catch((resp: any) => {
            notification('error', 'mosaic.removeTile')

            reject(false)
          })
      } else {
        reject(false)
      }
    })

  }

  setStateFromApi(apiResponse: any) {
    const { getSurveys, getMosaics } = this.props

    this.setState({
      editedMosaic: { ...apiResponse },
      company: apiResponse.company,
      status: 'edit',
      selectedCompany: { value: apiResponse.company.id, label: apiResponse.company.name }
    })

    getSurveys(get(apiResponse, 'companyId'))
    getMosaics(get(apiResponse, 'companyId'))
  }

  saveMosaic(action?: string) {
    const { match: { params: { id }}, createMosaic, updateMosaic, history } = this.props
    const { editedMosaic } = this.state
    const formData = formattedDataMosaic(editedMosaic)

    if (id === 'new') {
      createMosaic(formData, {
        onSuccess: (resp: any) => {
          // window.history.pushState('edit-mosaic', '', `/mosaics/${resp.id}`)
          history.push(`/mosaics/${resp.id}`)
          this.setStateFromApi(resp)
          notification('success', 'mosaic.create')
        },
        onFailure: (resp: any, x: any) => {
          notification('error', 'mosaic.create')
        }
      })
    } else {
      if (action === 'regenerateLink') {
        formData.append('mosaic[regenerate_access_token]', 'true')
      }

      if (this.confirmSave()) {
        updateMosaic(formData, id, {
          onSuccess: (resp: any) => {
            this.setStateFromApi(resp)
            notification('success', 'mosaic.edit')
          },
          onFailure: (resp: any, x: any) => {
            notification('error', 'mosaic.edit')
          }
        })
      }
    }
  }

  selectCompany(selectedCompany: SelectOption) {
    const { editedMosaic } = this.state

    return this.setState({
      selectedCompany,
      editedMosaic: {
        ...editedMosaic,
        companyId: selectedCompany.value
      }
    })
  }

  tileChanged(oldTile: Tile, changedTile: Tile) {
    const { editedMosaic } = this.state
    let isNew: boolean = true

    let tiles = map(editedMosaic.tiles, (tile: Tile) => {
      if (tile.position === changedTile.position) {
        isNew = false
        return changedTile
      }
      return tile
    })

    if (isNew) {
      tiles.push(changedTile)
    }

    if (oldTile.position !== changedTile.position) {
      this.changeTilePosition(oldTile, get(changedTile, 'position'))
    }

    this.setState({
      editedMosaic: { ...editedMosaic, tiles }
    })
  }

  renderAccessToken() {
    const { editedMosaic } = this.state

    return (
      <div className="access-token-wrapper">
        <div className="access-token">
          <Translate value="pages.mosaic.accessLink" />
          <CopyInput value={`${window.location.origin}/mosaics/public?access_token=${editedMosaic.accessToken}`}/>
        </div>
        <a href="#" onClick={() => this.saveMosaic('regenerateLink')}>
          <Translate value="pages.mosaic.regenerateLink" />
        </a>
      </div>
    )
  }

  /* Todo: make a tyaw component? */
  renderColorInput(colorName: string) {
    const { editedMosaic } = this.state

    return (
      <div className="color-picker-card">
        <img className="color-input-icon" src={pickerColor} />
        <input
          className="color-input"
          type="color"
          id={colorName}
          name={colorName}
          onChange={(e: any) => {
            this.setState({
              editedMosaic: {
                ...editedMosaic,
                [colorName]: e.target.value
              }
            })
          }}
          value={get(editedMosaic, colorName, '')}
        />
        <label className="color-input-label" htmlFor={colorName}>
          {I18n.t(`models.mosaic.${colorName}`)}
        </label>
      </div>
    )
  }

  renderMenu() {
    const { editedMosaic } = this.state

    return (
      <Fragment>
        <div className="left">
          <div className="col-2">
            <TextInput
              placeholder={`${I18n.t('models.mosaic.title')} *`}
              value={get(editedMosaic, 'title', '')}
              onChangeText={(e: any) => this.setState({ editedMosaic: {...editedMosaic, title: e } })}
              className="white"
            />

            <TextInput
              placeholder={I18n.t('models.mosaic.subtitle')}
              value={get(editedMosaic, 'subtitle', '')}
              onChangeText={(e: any) => this.setState({ editedMosaic: {...editedMosaic, subtitle: e } })}
              className="white"
            />

            <Checkbox
              label={I18n.t('models.mosaic.showCaption')}
              isChecked={get(editedMosaic, 'showCaption', false)}
              onChange={(showCaption: boolean) =>
                this.setState({editedMosaic: { ...editedMosaic, showCaption }})
              }
            />
          </div>

          <div className="col-2 inline-fields">
            <TextInput
              label={`${I18n.t('models.mosaic.testimonyCount')} *`}
              type="number"
              value={get(editedMosaic, 'testimonyCount', '')}
              onChangeText={(e: any) => this.setState({ editedMosaic: {...editedMosaic, testimonyCount: e } })}
              placeholder="12"
              className="white"
            />

            <TextInput
              label={`${I18n.t('models.mosaic.perLines')} *`}
              type="number"
              value={get(editedMosaic, 'perLines', '')}
              onChangeText={(e: any) => this.setState({ editedMosaic: {...editedMosaic, perLines: e } })}
              placeholder="5"
              className="white"
            />
          </div>

          <div className="col-2 inline-fields">
            <TextInput
              label={
                <Fragment>
                  { I18n.t('models.mosaic.tileWidth') } *
                  <br />
                  <small>{I18n.t('pages.mosaic.pixels')}</small>
                </Fragment>
              }
              type="number"
              value={get(editedMosaic, 'tileWidth', 250)}
              onChangeText={(e: any) => this.setState({ editedMosaic: {...editedMosaic, tileWidth: e } })}
              placeholder="250"
              className="white"
            />

            <TextInput
              label={
                <Fragment>
                  { I18n.t('models.mosaic.tileHeight') } *
                  <br />
                  <small>{I18n.t('pages.mosaic.pixels')}</small>
                </Fragment>
              }
              type="number"
              value={get(editedMosaic, 'tileHeight', 150)}
              onChangeText={(e: any) => this.setState({ editedMosaic: {...editedMosaic, tileHeight: e } })}
              placeholder="150"
              className="white"
            />
          </div>

          <div className="col-2">
            {this.renderColorInput('backgroundColor')}
            {this.renderColorInput('textColor')}
          </div>
        </div>

        <div className="right">
          <div className="col-2">
            <Checkbox
              label={I18n.t('models.mosaic.shared')}
              isChecked={get(editedMosaic, 'shared', false)}
              onChange={(shared: boolean) =>
                this.setState({editedMosaic: { ...editedMosaic, shared }})
              }
            />
            { editedMosaic.accessToken && editedMosaic.shared ? this.renderAccessToken() : null }
          </div>
        </div>
      </Fragment>
    )
  }

  renderPreview() {
    const { editedMosaic, company } = this.state
    const { validatedSurveys, mosaics } = this.props
    const companyValidatedSurveys = filter(validatedSurveys, { companySurvey: { companyId: company.id }})
    const companySharedMosaics: Mosaic[] = filter(mosaics, (mosaic: Mosaic) => {
      return (mosaic.shared && mosaic.id !== get(editedMosaic, 'id'))
    })

    return (
      <div className="preview">
        <h3>{I18n.t('pages.mosaic.preview')}</h3>
        <MosaicView
          mode={'edit'}
          mosaic={editedMosaic}
          company={company}
          surveys={companyValidatedSurveys}
          mosaics={companySharedMosaics}
          tileChanged={(oldTile: Tile, tile: Tile) => this.tileChanged(oldTile, tile)}
          onRemoveImage={(tile: Tile) => this.onRemoveTileImage(tile)}
          onRemoveTile={(tile: Tile) => this.onRemoveTile(tile)}
        />
      </div>
    )
  }

  renderSaveButton(user: User, status: string) {
    const primaryColor = get(user, 'company.primaryColor')

    return (
      <Button
        type="button"
        primaryColor={primaryColor}
        onClick={() => this.saveMosaic()}
      >
        {I18n.t(`actions.${status}`)}
      </Button>
    )
  }

  renderDownloadButton(user: User) {
    const { editedMosaic } = this.state
    const primaryColor = get(user, 'company.primaryColor')
    // const cookieAuthent = Cookie.get(authTokenCookieKey)

    return (
      <Button
        type="button"
        primaryColor={primaryColor}
        onClick={() => {
          downloadMosaic(get(editedMosaic, 'id')).then((data: Mosaic) => {
            notification('success', 'mosaic.download')
          }).catch((error: any) => {
            notification('error', 'mosaic.download')
          })
        }}
      >
        {I18n.t('actions.download')}
      </Button>
    )
  }

  renderCompanySelect() {
    const { companies } = this.props
    const { selectedCompany } = this.state

    return (
      <CompanySelect
        companies={companies}
        selectedCompany={selectedCompany}
        handleSelect={(company: SelectOption) => this.selectCompany(company)}
      />
    )
  }

  render() {
    const { user, mosaic, loading } = this.props
    const { status, selectedCompany } = this.state

    if (loading && !mosaic.id) {
      return (
        <SignedInLayout>
          <Loader />
        </SignedInLayout>
      )
    }

      if (status === 'edit' && (!mosaic.id)) {
      return (
        <SignedInLayout>
          {I18n.t('pages.mosaic.noMosaic')}
        </SignedInLayout>
      )
    }

    return (
      <SignedInLayout>
        <div className="mosaic-page">
          <div className="header">
            <div>
              <h2 className="page-title">
                {I18n.t(`pages.mosaic.${status}`)} { mosaic.title }
              </h2>
              { status === 'create' ? this.renderCompanySelect() : I18n.t('models.mosaic.company', { name: selectedCompany.label }) }
            </div>

            <div className="buttons">
              { loading ? <Loader /> : null }
              { (status === 'edit' || selectedCompany.value) ? this.renderSaveButton(user, status) : null }
              <NavLink to="/mosaics" className="back">
                <Button type="button" className="button-danger">
                  {I18n.t('pages.back')}
                </Button>
              </NavLink>
              <Can I="download" this="localVersion">
                { this.renderDownloadButton(user) }
              </Can>
            </div>
          </div>

          <div className="menu">
            { this.renderMenu() }
          </div>

          { (status === 'edit') ? this.renderPreview() : null }
        </div>
      </SignedInLayout>
    )
  }
}


const mapStateToProps = () => ({
  user: { currentUser },
  mosaics: { mosaic, allMosaics, loading },
  companies: { allCompanies },
  userSurveys: { allSurveys }
}: Store) => ({
  mosaic,
  loading,
  user: currentUser,
  companies: allCompanies,
  mosaics: allMosaics,
  validatedSurveys: filter(allSurveys, { status: 'validated' })
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getSurveys: (companyId: string) => dispatch(getUserSurveysForCompany(companyId)),
  getMosaic: (id: string, meta: any) => dispatch(getMosaicAction(id, meta)),
  getMosaics: (companyId: string) => dispatch(getMosaicsForCompanyAction(companyId)),
  createMosaic: (data: FormData, meta: any) => dispatch(createMosaicAction(data, meta)),
  updateMosaic: (data: FormData, id: string, meta: any) => dispatch(updateMosaicAction(data, id, meta)),
  getCompanies: () => dispatch(getCompaniesAction()),
  removeTileImage: (mosaicId: string, tile: Tile, meta: any) => dispatch(removeTileImageAction(mosaicId, tile, meta))
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(MosaicScene) as any)
