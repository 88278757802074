import { apiClient } from '../constants'

export const getManagers = () => apiClient.get('/managers').then(({ data }) => data)

export const createManagerInvitationToken = (managerInvitationToken: any) =>
  apiClient.post('/manager_invitation_tokens', { managerInvitationToken }).then(({ data }) => data)

export const getManagerInvitationTokens = () =>
  apiClient.get('/manager_invitation_tokens').then(({ data }) => data)

export const deleteManagerInvitationToken = (id: string) =>
  apiClient.delete(`/manager_invitation_tokens/${id}`).then(({ data }) => data)

export const deleteManager = (id: string) =>
  apiClient.delete(`/users/${id}`).then(({ data }) => data)

export const updateManagerCompany = (userId: string, companyId: string) =>
  apiClient
    .patch(`/managers/${userId}`, {
      manager: {
        company_id: companyId,
      },
    })
    .then(({ data }) => data)
