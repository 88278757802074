import React from 'react'

/* redux */
import { I18n } from 'react-redux-i18n'

/* Ag Grid */
import { AgGridReact, AgGridColumn } from 'ag-grid-react'

import { gridSkeleton } from '../../components/ag-grid'

/* libs */
import { upperCase, pull } from 'lodash'

/* app */
import { LOCALES } from '../../i18n'
import { Hierarchy, GridSharedProps } from '../../types'

interface IProps extends GridSharedProps {
  data: {
    locale: string,
    rows: Hierarchy[]
  }
  handleDelete?: (hierarchy: Hierarchy) => Promise<boolean>
}

const hierarchysGrid = (props: IProps) => {
  const { data, firstDataRendered, refreshCells, handleDelete, gridReady, options } = props

  const translatedColumns: JSX.Element[] = []

  pull(LOCALES, data.locale).forEach((lang: string) => {
    translatedColumns.push(
      <AgGridColumn
        field={`nameI18n.${lang}`}
        key={`nameI18n-${lang}`}
        headerName={`${upperCase(lang)} - ${I18n.t('models.hierarchy.name')}`}
        editable={true}
      />
    )
  })

  const renderActions = () => {
    return (
      <AgGridColumn
        colId="actions"
        cellRenderer="actionsRenderer"
        cellRendererParams={
          {
            showButtons: { edit: true, add: true, delete: handleDelete !== undefined, settings: true },
            confirmText: I18n.t('models.hierarchy.delete.alert'),
            onDeleteAction: (hierarchy: Hierarchy) => { handleDelete && handleDelete(hierarchy) },
            showPath: "hierarchies/"
          }
        }
        headerName=""
        suppressSizeToFit={true}
        editable={false}
        filter={false}
        sortable={false}
        resizable={false}
        width={120}
      ></AgGridColumn>
    )
  }

  return (
    <div className="ag-theme-alpine ag-grid">
      <AgGridReact
        gridOptions={options}
        rowData={data.rows}
        onFirstDataRendered={firstDataRendered}
        onFilterChanged={refreshCells}
        onGridReady={gridReady}
        onRowDataUpdated={refreshCells}
      >
        <AgGridColumn
          colId="first-column"
          field={`nameI18n.${data.locale}`}
          headerName={I18n.t('models.hierarchy.name')}
          suppressSizeToFit={ true }
          width={400}
          editable={true}
        ></AgGridColumn>

        { translatedColumns }

        <AgGridColumn
          field="tagsCount"
          headerName={I18n.t('models.hierarchy.tagsCount')}
          editable={false}
        ></AgGridColumn>

        { renderActions() }
      </AgGridReact>
    </div>
  )
}

export default gridSkeleton(hierarchysGrid, {
  rowDragManaged: false
})
