import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { GridApi, ColumnApi, RowNode, RowEditingStartedEvent, RowEditingStoppedEvent, Column } from 'ag-grid-community/main.d'

import { get } from 'lodash'

import { isEmptyRow, addRow } from '../../helpers/ag-grid-helper'
import { useComponentWillMount } from '../../helpers/application-helper'

import { trash, edit, iconClose, checkGreen, settings } from '../../assets/icons'

interface IProps {
  node: RowNode
  api: GridApi
  initData: any
  columnApi: ColumnApi
  column: Column
  rowIndex: number
  data: any
  confirmText: string
  showButtons?: { edit: boolean, add: boolean, delete: boolean, settings: boolean }
  onDeleteAction: (id: string) => Promise<boolean>
  showPath?: string
}

const actionsRenderer = (props: IProps) => {
  const { showButtons, onDeleteAction, showPath, data,
    confirmText, api, columnApi, node, rowIndex, column } = props

  const [editing, setEditing] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)

  useComponentWillMount(() => {
    let editingCells = api.getEditingCells()
    if (editingCells.length !== 0) {
      setDisabled(true)
    }
  })

  useEffect(() => {
    api.addEventListener('rowEditingStarted', onRowEditingStarted)
    api.addEventListener('rowEditingStopped', onRowEditingStopped)

    return () => {
      api.removeEventListener('rowEditingStarted', onRowEditingStarted)
      api.removeEventListener('rowEditingStopped', onRowEditingStopped)
    }
  }, [])

  const onRowEditingStarted = (params: RowEditingStartedEvent) => {
    if (node === params.node) {
      setEditing(true)
    } else {
      setDisabled(true)
    }
  }

  const onRowEditingStopped = (params: RowEditingStoppedEvent) => {
    if (props.node === params.node) {
      if (isEmptyRow(params.data) || params.data.isNew) {
        deleteRow()
      } else {
        setEditing(false)
      }
    } else {
      setDisabled(false)
    }
  }

  const startEditing = (index: number) => {
    api.startEditingCell({
      rowIndex: index,
      colKey: column.getColId()
    })
  }

  const stopEditing = (cancel: boolean) => {
    api.stopEditing(cancel)

    if (cancel && data.isNew) {
      deleteRow()
    }
  }

  const deleteRow = async () => {
    let deleted = data.isNew

    if (!data.isNew && window.confirm(`${confirmText}`)) {
      deleted = await onDeleteAction(data)
    }

    if (deleted) {
      api.applyTransaction({ remove: [data] })
    }
  }

  const addChildRow = (e: any) => {
    addRow(
      api,
      columnApi,
      {
        labelI18n: {},
        descI18n: {},
        parentId: get(node, 'data.id'),
        depth: get(node, 'data.depth', 0) + 1
      },
      get(node, 'rowIndex', -1) + 1
    )
  }

  let buttons: any = []

  if (editing) {
    buttons = [
      <button
        key="ok-button"
        className="button-icon"
        onClick={() => stopEditing(false)}
        disabled={disabled}
      >
        <img src={checkGreen} />
      </button>,
      <button
        key="cancel-button"
        className="button-icon"
        onClick={() => stopEditing(true)}
        disabled={disabled}
      >
        <img src={iconClose} />
      </button>
    ]
  }

  if (!editing && !disabled) {

    if ( get(showButtons, 'add', false) && data.depth <= 2) {
      buttons.push(
        <button
          key="add-button"
          className="button-icon add-button"
          onClick={addChildRow}
        >
          +
        </button>
      )
    }

    if (get(showButtons, 'edit', true)) {

      buttons.push(
        <button
          key="edit-button"
          className="button-icon"
          onClick={() => startEditing(rowIndex)}
        >
          <img src={edit} />
        </button>
      )
    }

    if ( get(showButtons, 'settings', false) && showPath ) {
      buttons.push(
        <Link
          key="settings-button"
          className="button-icon"
          to={`${showPath}${get(node, 'data.id')}`}
        >
          <img src={settings} />
        </Link>
      )
    }

    if ( get(showButtons, 'delete', true) ) {
      buttons.push(
        <button
          key="delete-button"
          className="button-icon"
          onClick={deleteRow}
        >
          <img src={trash} />
        </button>
      )
    }
  }

  return (
    <div className="ag-grid-actions" key={`actions-data-${data.id}`}>
      {buttons}
    </div>
  )
}

export default actionsRenderer
