import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'

/* redux */
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Link } from 'react-router-dom'

/* libs */
import moment from 'moment'
import Select from 'react-select'
import { get, set, isEmpty } from 'lodash'
import { Loader, TextInput, Button, Checkbox } from 'tyaw-components'

/* AG Grid */
import { GridApi, GridReadyEvent, RowValueChangedEvent, RowDragEndEvent, RowNode } from 'ag-grid-community/main.d'

/* app */
import { notification } from '../../helpers/application-helper'
import {
  CampaignStep,
  Guest,
  Question,
  QuestionType,
  SelectOption,
  Store,
  Survey,
  SurveyTarget,
  Hierarchy,
  TemplateQuestion,
  User,
  UserSurvey
} from '../../types'

import {
  getTemplateQuestions as getTemplateQuestionsAction,
  getQuestionTypes as getQuestionTypesAction,
  updateSurvey as updateSurveyAction,
  getCampaignStep as getCampaignStepAction,
  createUserSurvey as createUserSurveyAction
} from '../../actions'
import { selectQuestions, getHierarchies } from '../../api'
import { QuestionsGrid, SignedInLayout, InviteModal, TextEditor } from '../../components'
import { BatchActionsComponent } from '../../components/ag-grid'

import './styles.scss'

interface IProps extends RouteComponentProps<any> {
  questionTypes: QuestionType[]
  templateQuestions: TemplateQuestion[]
  user: User
  locale: string
  loading: boolean
  getCampaignStep: (id: string, meta: any) => void
  updateSurvey: (survey: Survey, meta: any) => void
  createUserSurvey: (userSurvey: UserSurvey, meta: any) => void
  getTemplateQuestions: (target: SurveyTarget) => void
  getQuestionTypes: () => void
}

interface IState {
  editedSurvey: Survey | null
  editedCampaignStep: CampaignStep | null
  campaignDelay: number
  mode: string // can be select / edit
  gridApi: GridApi | undefined
  showHelp: boolean
  hierarchies: Hierarchy[]
}

class CampaignStepScene extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props)

    this.state = {
      editedSurvey: null,
      editedCampaignStep: null,
      campaignDelay: 0,
      mode: 'select',
      showHelp: false,
      gridApi: undefined,
      hierarchies: []
    }
  }

  componentDidMount() {
    this.loadSurvey()
  }

  async loadSurvey() {
    const {
      match: {
        params: { id },
      },
      getCampaignStep,
      getQuestionTypes,
      getTemplateQuestions
    } = this.props

    await getQuestionTypes()

    getCampaignStep(id, {
      onSuccess: (resp: any) => {
        const survey = get(resp, 'companySurvey')
        this.setState({
          editedCampaignStep: resp,
          editedSurvey: survey,
          mode: get(survey, 'questions', []).length > 0 ? 'edit' : 'select',
          campaignDelay: get(resp, 'days', 0) / 93600
        })
        getTemplateQuestions(get(survey, 'target'))
        getHierarchies(get(survey, 'company.id')).then((hierarchies) => {
          this.setState({ hierarchies })
        })
      },
      onFailure: () => {
        notification('error', 'campaignStep.get')
      }
    })
  }

  /* This also reset gridApi when changing mode: new mode => new grid */
  switchMode(mode: string): void {
    if (mode === 'select') {
      this.setState({ mode: 'edit', gridApi: undefined })
      //gridApi && gridApi.refreshClientSideRowModel()
    } else {
      this.setState({ mode: 'select', gridApi: undefined })
    }
  }

  isThankYou(): boolean {
    const { editedSurvey } = this.state
    return get(editedSurvey, 'target', 'thank_you') === 'thank_you'
  }

  publishedAt() {
    const { editedSurvey, editedCampaignStep } = this.state
    if (this.isThankYou()) {
      return get(editedSurvey, 'publishedAt')
    } else {
      return get(editedCampaignStep, 'campaignPublished')
    }
  }

  /* Handlers */
  async handleInvitationSubmit(guest: Guest) {
    const {
      match: {
        params: { id },
      },
      createUserSurvey
    } = this.props
    const { editedSurvey } = this.state
    const companyId: string = get(editedSurvey, 'company.id')

    createUserSurvey({
      companyId,
      jobTitle: get(guest, 'jobTitle'),
      campaignStepId: id,
      userAttributes: guest
    }, {
      onSuccess: () => {
        notification('success', 'invitation')
      },
      onFailure: () => {
        notification('error', 'invitation')
      }
    })
  }

  handleTQsSelection(templateQuestions: TemplateQuestion[]) {
    const { editedSurvey } = this.state
    if (editedSurvey) {
      selectQuestions( editedSurvey, templateQuestions.map((tq) => get(tq, 'id')) ).then((data) => {
        notification('success', 'campaignStep.selection')
        this.setState({ editedSurvey: data })
      }).catch((err) => {
        notification('error', 'campaignStep.selection')
      })
    }
  }

  handleChanges(event: RowValueChangedEvent) {
    const { mode, editedSurvey } = this.state
    const data: any = get(event, 'data')

    if (editedSurvey === null || mode !== 'edit') {
      return
    }

    editedSurvey.questionsAttributes = get(editedSurvey, 'questions', []).map((question: Question) => {
      return question.id === data.id ? data : question
    })
    this.update(editedSurvey)
  }

  save(publish: boolean) {
    const { editedSurvey } = this.state

    if (editedSurvey === null) {
      return
    }

    if (publish) {
      set(editedSurvey, 'publishedAt', moment().toISOString())
    }
    this.update(editedSurvey)
  }

  /* Events management */
  onGridReady(event: GridReadyEvent) {
    this.setState({
      gridApi: event.api
    })
  }

  onRowDragEnd(event: RowDragEndEvent) {
    const { editedSurvey } = this.state
    const api: GridApi = event.api

    if (editedSurvey === null) {
      return
    }

    editedSurvey.questionsAttributes = []

    api.forEachNode((node: RowNode, index: number) => {
      if (editedSurvey.questionsAttributes) {
        editedSurvey.questionsAttributes.push({
          ...node.data,
          order: index+1
        })
      }
    })

    this.update(editedSurvey)
  }

  update(survey: Survey) {
    const { updateSurvey } = this.props
    const { campaignDelay, editedCampaignStep } = this.state

    survey.campaignStepAttributes = {
      id: get(editedCampaignStep, 'id'),
      days: campaignDelay * 93600
    }

    updateSurvey(survey, {
      onSuccess: (resp: any) => {
        this.setState({ editedSurvey: resp })
        notification('success', 'survey.update')
      },
      onFailure: (err: any) => {
        notification('error', 'survey.update', { apiErrors: get(err, 'response.data', []) })
      }
    })
  }

  renderBackButton() {
    const { editedCampaignStep } = this.state

    return (
      <Link
        className="button-component button-danger button-back"
        to={`/campaigns/${get(editedCampaignStep, 'campaignId')}`}
      >
        {I18n.t('pages.campaignStep.backLink')}
      </Link>
    )
  }

  renderTextArea(textAreaName: string, updatedSubkey?: string) {
    const { editedSurvey } = this.state

    if (editedSurvey === null) {
      return null
    }

    const attributeId: string = updatedSubkey ? `${updatedSubkey}.${textAreaName}` : textAreaName

    return (
      <TextEditor
        label={I18n.t(`models.companySurvey.${attributeId}`)}
        attributeId={attributeId}
        value={get(editedSurvey, attributeId, '')}
        handleChange={(text: string) => {
          if (updatedSubkey) {
            const subkeyInfo = get(editedSurvey, updatedSubkey)
            this.setState({ editedSurvey: { ...editedSurvey, [updatedSubkey]: { ...subkeyInfo, [textAreaName]: text } } })
          } else {
            this.setState({ editedSurvey: { ...editedSurvey, [textAreaName]: text } })
          }
        }}
        displayed={true}
      />
    )
  }

  renderEmailsInput(updatedKey: string, translationKey: string) {
    const { editedSurvey } = this.state
    const emails = get(editedSurvey, 'emails', {})

    if (editedSurvey === null) {
      return
    }

    return (
      <TextInput
        label={I18n.t(`shared.emails.${translationKey}`)}
        placeholder=""
        onChangeText={(text: string) => this.setState(
          {
            editedSurvey: {
              ...editedSurvey,
              emails: { ...emails, [updatedKey]: text }
            }
          }
        )}
        className="white"
        value={get(emails, updatedKey, '')}
      />
    )
  }

  /* renders */
  renderThankYouMenu() {
    const { user } = this.props
    const primaryColor = get(user, 'company.primaryColor')

    let elt;

    if (this.publishedAt()) {
      elt = <InviteModal
        primaryColor={primaryColor}
        handleInvitation={(guest: Guest) => this.handleInvitationSubmit(guest)}
      />
    } else {
      elt = <Button
        type="button"
        onClick={() => this.save(true)}
        primaryColor={primaryColor}
      >
        {I18n.t('actions.publish')}
      </Button>
    }

    return (
      <div className="buttons-container">
        { elt }
      </div>
    )
  }

  renderWelcomeFields() {
    const { campaignDelay } = this.state

    return (
      <TextInput
        label={I18n.t('models.campaignStep.day', {days: campaignDelay })}
        onChangeText={(campaignDelay: string) =>
          this.setState({ campaignDelay: parseInt(campaignDelay, 10) })
        }
        type="number"
        value={campaignDelay || 0}
        className="white"
      />
    )
  }

  renderGridMenu() {
    const { gridApi, mode } = this.state
    const { user } = this.props
    const primaryColor = get(user, 'company.primaryColor')

    if (!gridApi) {
      return null
    }

    return (
      <div className="ag-grid-tools">
        { this.publishedAt() ? null :
          <Button
            className="button-selection"
            type="button"
            onClick={() => this.switchMode(mode)}
            primaryColor={primaryColor}
          >
            { I18n.t(`pages.campaignStep.switch.${mode}`) }
          </Button>
        }

        <BatchActionsComponent
          key={`${mode}-batch-actions`}
          gridApi={gridApi}
          fields={[]}
          mode={mode}
          onSelect={(tqs: TemplateQuestion[]) => this.handleTQsSelection(tqs)}
        />
        <TextInput
          placeholder={I18n.t('actions.search')}
          className="ag-grid-filter white"
          onChangeText={(e: any) => gridApi && gridApi.setQuickFilter(e)}
        />
      </div>
    )
  }

  renderGrid() {
    const { questionTypes, templateQuestions, locale } = this.props
    const { editedSurvey, mode } = this.state
    let selectedIds: string[]
    let rows: TemplateQuestion[] | Question[]

    if (mode === 'select') {
      selectedIds = get(editedSurvey, 'questions', []).map((q: Question) => q.templateQuestionId)
      rows = templateQuestions
    } else {
      selectedIds = []
      rows = get(editedSurvey, 'questions', [])
    }

    return (
      <QuestionsGrid
        key={`ag-grid-${mode}-mode`}
        onGridReady={ (event: GridReadyEvent) => this.onGridReady(event) }
        onRowDragEnd={ (event: RowDragEndEvent) => this.onRowDragEnd(event) }
        handleChanges={ (event: RowValueChangedEvent) => this.handleChanges(event) }
        data={{
          locale,
          questionTypes,
          selectedIds,
          rows: rows,
        }}
        mode={mode}
      />
    )
  }

  renderMessages() {
    return (
      <div className="custom-messages-container">
        <h3>
          {I18n.t('pages.campaignStep.messages.title')}
        </h3>

        <div className="textareas-container">
          {this.renderTextArea("context")}
          {this.renderTextArea("thankingMessage")}
        </div>
      </div>
    )
  }

  renderEmails() {
    return (
      <div className="custom-emails-container">
        <h3>{I18n.t('shared.emails.title')}</h3>

        <div className="textareas-container">

          <p><Translate value="shared.emails.help" dangerousHTML /></p>

          <h4>{I18n.t('shared.emails.guest.title')}</h4>
          <div className='emails-inputs'>
            {this.renderEmailsInput('guestInvitationSubject', 'subject')}
            {this.renderEmailsInput('guestInvitationButton', 'button')}
          </div>

          {this.renderTextArea("guestInvitationBody", "emails")}
          <small><Translate value="shared.emails.guest.shortcodes" dangerousHTML /></small>

          <h4>{I18n.t('shared.emails.user.title')}</h4>
          <div className='emails-inputs'>
            {this.renderEmailsInput('userInvitationSubject', 'subject')}
            {this.renderEmailsInput('userInvitationButton', 'button')}
          </div>

          {this.renderTextArea("userInvitationBody", "emails")}
          <small><Translate value="shared.emails.user.shortcodes" dangerousHTML /></small>
        </div>
      </div>
    )
  }

  renderHierarchiesOptions() {
    const { hierarchies, editedSurvey } = this.state

    if (!editedSurvey) { return null }

    const hierarchyOptions: SelectOption[] = hierarchies.map((hierarchy: Hierarchy) => {
      return {
        value: get(hierarchy, 'id'),
        label: get(hierarchy, 'name'),
        details: hierarchy
      }
    })

    const selectedHierarchies: SelectOption[] = get(editedSurvey, 'hierarchies', []).map((hierarchy: Hierarchy) => {
      return {
        value: get(hierarchy, 'id'),
        label: get(hierarchy, 'name'),
        details: hierarchy
      }
    })

    return (
      <div className="inputs-container tags-options">
        <h3>{ I18n.t('pages.campaignStep.tags.title') }</h3>
        <TextInput
          label={I18n.t("models.companySurvey.tagsHint")}
          value={
            get(editedSurvey, 'tagsHint', I18n.t('pages.campaignStep.tags.defaultHint') )
          }
          onChangeText={(tagsHint: string) => this.setState({ editedSurvey: { ...editedSurvey, tagsHint } })}
          className="white"
        />
        <Select
          onChange={(selections: any) => {
            this.setState({
              editedSurvey: {
                ...editedSurvey,
                hierarchies: selections.map((t: SelectOption) => t.details)
              }
            })
          }}
          placeholder={I18n.t("pages.campaignStep.tags.placeholder")}
          value={selectedHierarchies}
          isMulti={true}
          options={hierarchyOptions}
        />
        <small>{I18n.t("pages.campaignStep.tags.hint")}</small>
      </div>
    )
  }

  render() {
    const { templateQuestions, loading, user } = this.props
    const { editedSurvey, showHelp, mode, hierarchies } = this.state
    const primaryColor = get(user, 'company.primaryColor')
    const activatedTags = get(editedSurvey, 'activatedTags', false)
    const downloadable = get(editedSurvey, 'downloadable', true)
    const customMessages = get(editedSurvey, 'customMessages', false)
    const customEmails = get(editedSurvey, 'customEmails', false)
    const hideQuestionsEmails = get(editedSurvey, 'hideQuestionsEmails', false)

    if (!editedSurvey || loading) {
      return (
        <SignedInLayout>
          <Loader />
        </SignedInLayout>
      )
    }

    if (isEmpty(templateQuestions)) {
      return (
        <SignedInLayout>
          <p>{ I18n.t('pages.campaign.none') }</p>
        </SignedInLayout>
      )
    }

    return (
      <SignedInLayout>
        <div className="campaign-step-header">
          { this.isThankYou() ? this.renderThankYouMenu() : this.renderBackButton() }
          <h2 className="page-title">
            {I18n.t(`pages.campaignStep.title.${get(editedSurvey, 'target', 'thank_you')}`)}
          </h2>
          <small>
            {get(editedSurvey, 'company.name')} - {get(editedSurvey, 'label') }
            <br />
            { this.publishedAt() ?
              <span>
                <strong>
                  {I18n.t('models.companySurvey.publishedAt')}
                </strong>
                {moment(new Date(this.publishedAt())).format('LL')}
              </span>
              : null
            }
          </small>
        </div>

        <form>
          <div className="inputs-container company-survey-info">
            <h3>{I18n.t("pages.campaignStep.info")}</h3>
            <TextInput
              label={I18n.t("models.companySurvey.label")}
              placeholder=""
              onChangeText={(newLabel: string) => this.setState({
                  editedSurvey: { ...editedSurvey, label: newLabel }
                })
              }
              className="white"
              value={get(editedSurvey, 'label')}
            />
          </div>

          <div className="inputs-container company-survey-options">
            { !this.isThankYou() || hierarchies.length > 0 ? <h3>{I18n.t("pages.campaignStep.options.title")}</h3> : null }

            { this.isThankYou() ? null : this.renderWelcomeFields() }
            { hierarchies.length > 0 ?
              <Checkbox
                label={ I18n.t('pages.campaignStep.options.tags') }
                isChecked={ activatedTags }
                onChange={(activatedTags: boolean) => this.setState({ editedSurvey: { ...editedSurvey, activatedTags } })}
              /> : null
            }
            <Checkbox
              label={I18n.t('pages.campaignStep.options.messages')}
              isChecked={customMessages}
              onChange={(customMessages: boolean) => this.setState({ editedSurvey: { ...editedSurvey, customMessages } })}
            />

            <Checkbox
              label={I18n.t('pages.campaignStep.options.emails')}
              isChecked={customEmails}
              onChange={(customEmails: boolean) => this.setState({ editedSurvey: { ...editedSurvey, customEmails } })}
            />

            <Checkbox
              label={I18n.t('pages.campaignStep.options.hideQuestionsEmails')}
              isChecked={hideQuestionsEmails}
              onChange={(hideQuestionsEmails: boolean) => this.setState({ editedSurvey: { ...editedSurvey, hideQuestionsEmails } })}
            />

            <Checkbox
              label={I18n.t('pages.campaignStep.options.downloadable')}
              isChecked={downloadable}
              onChange={(downloadable: boolean) => this.setState({ editedSurvey: { ...editedSurvey, downloadable } })}
            />
            { !downloadable ?
              <small>
                { I18n.t(`pages.campaignStep.hints.downloadable`) }
              </small>
              : null
            }

          </div>

          { hierarchies.length > 0 && activatedTags ? this.renderHierarchiesOptions() : null }

        </form>

        <Button
          type="button"
          onClick={() => this.save(false)}
          primaryColor={primaryColor}
        >
          {I18n.t('actions.save')}
        </Button>

        { customMessages ? this.renderMessages() : null }

        { customEmails ? this.renderEmails() : null }

        <div className="ag-grid-container">
          <h3>
            { I18n.t(`pages.campaignStep.modes.${mode}`) }
            { I18n.t('pages.campaignStep.yourQuestions') }
          </h3>

          <small className="clickable" onClick={(e: any) => this.setState({ showHelp: !showHelp })}>
            {I18n.t('pages.campaignStep.help')}
          </small>

          { showHelp ?
            <p className="info-box">
              { I18n.t(`pages.campaignStep.helps.${mode}`) }
              <br />
              <small>
                { I18n.t(`pages.campaignStep.hints.${mode}`) }
              </small>
            </p>
            : null
          }

          { this.renderGridMenu() }

          { this.renderGrid() }
        </div>
      </SignedInLayout>
    )
  }
}

const mapStateToProps = () => ({
  templateQuestions: { allTemplateQuestions, loading },
  questionTypes: { allQuestionTypes },
  user: { currentUser },
  i18n: { locale }
}: Store) => ({
  locale,
  loading,
  questionTypes: allQuestionTypes,
  templateQuestions: allTemplateQuestions,
  user: currentUser
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getTemplateQuestions: (target: SurveyTarget) => dispatch(getTemplateQuestionsAction(target)),
  getQuestionTypes: () => dispatch(getQuestionTypesAction()),
  getCampaignStep: (id: string, meta: any) => dispatch(getCampaignStepAction(id, meta)),
  updateSurvey: (data: Survey, meta: any) => dispatch(updateSurveyAction(data, meta)),
  createUserSurvey: (userSurvey: UserSurvey, meta: any) => dispatch(createUserSurveyAction(userSurvey, meta))
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignStepScene) as any)
