import React, { useState, useEffect } from 'react'

import { GridApi, ColumnApi } from 'ag-grid-community/main.d'


import { Button } from 'tyaw-components'

import { addRow } from '../../helpers'

interface IProps {
  gridApi?: GridApi,
  columnApi?: ColumnApi,
  initData?: any,
  buttonLabel: string
}

const addRowComponent = (props: IProps) => {
  const { gridApi, columnApi, buttonLabel, initData } = props
  /* editing: usefull for full row editing */
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    if (gridApi) {
      gridApi.addEventListener("rowEditingStarted", onRowEditingStarted)
      gridApi.addEventListener("rowEditingStopped", onRowEditingStopped)
    }

    return () => {
      if (gridApi) {
        gridApi.removeEventListener("rowEditingStarted", onRowEditingStarted)
        gridApi.removeEventListener("rowEditingStopped", onRowEditingStopped)
      }
    };
  }, [gridApi])

  const onRowEditingStarted = () => {
    setEditing(true)
  }

  const onRowEditingStopped = () => {
    setEditing(false)
  }


  if (gridApi && columnApi) {
    return (
      <Button
        onClick={() => addRow(gridApi, columnApi, initData)}
        type="button"
        disabled={editing}
      >
        { buttonLabel }
      </Button>
    )
  } else {
    return null
  }
}

export default addRowComponent
