import { apiClient } from '../constants'
import { UserSurvey, GuestAuthorizationToken, User } from '../types'

export const getUserSurvey = (id: string, token: string | null, fromMosaic?: boolean) =>
  apiClient.get(`/user_surveys/${id}`, { params: { t: token, from_mosaic: fromMosaic }}).then(({ data }) => data)

export const getUserSurveys = (withTags: boolean = false) => apiClient.get('/user_surveys', { params: { with_tags: withTags } }).then(({ data }) => data)

export const getUserSurveysForCompany = (companyId: string, withTags: boolean = false) => apiClient.get('/user_surveys', {
    params: {
      company_id: companyId,
      with_videos: true,
      with_tags: withTags
    }
  }).then(({ data }) => data)

export const updateUserSurvey = (userSurveyId: string, params: UserSurvey) =>
  apiClient.patch(`/user_surveys/${userSurveyId}`, params).then(({ data }) => data)

export const createUserSurvey = (userSurvey: UserSurvey) =>
  apiClient.post('/user_surveys', { userSurvey }).then(({ data }) => data)

export const validateUserSurvey = (userSurvey: UserSurvey) =>
  apiClient.patch(`/user_surveys/${userSurvey.id}/validate`).then(({ data }) => data)

export const completeUserSurvey = (userSurvey: UserSurvey) =>
apiClient.patch(`/user_surveys/${userSurvey.id}/complete`).then(({ data }) => data)

export const unvalidateUserSurvey = (userSurvey: UserSurvey) =>
  apiClient.patch(`/user_surveys/${userSurvey.id}/unvalidate`).then(({ data }) => data)

export const uncompleteUserSurvey = (userSurvey: UserSurvey) =>
apiClient.patch(`/user_surveys/${userSurvey.id}/uncomplete`).then(({ data }) => data)

export const resendSurveyInvitation = (userSurvey: UserSurvey) =>
  apiClient.patch(`/user_surveys/${userSurvey.id}/resend-invitation`).then(({ data }) => data)

export const deleteUserSurvey = (userSurveyId: string) =>
  apiClient.delete(`/user_surveys/${userSurveyId}`).then(({ data }) => data)

export const shareUserSurvey = (guestAuthorizationToken: GuestAuthorizationToken) =>
  apiClient
    .post('/guest_authorization_tokens', { guestAuthorizationToken })
    .then(({ data }) => data)

export const deleteGuestAuthorizationToken = (guestAuthorizationTokenId: string) =>
  apiClient.delete(`/guest_authorization_tokens/${guestAuthorizationTokenId}`).then(({ data }) => data)

export const changeUserSurveyOwner = (userSurvey: UserSurvey, newOwner: User) =>
  apiClient.patch(`/user_surveys/${userSurvey.id}/change-owner`, { newOwner }).then(({ data }) => data)

export const downloadUserSurvey = (userSurvey: UserSurvey) =>
  apiClient.get(`/user_surveys/${userSurvey.id}/download`).then(({ data }) => data)
