import React from 'react'
import { Dispatch } from 'redux'
import { withRouter } from 'react-router'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { filter, get } from 'lodash'
import Modal from 'react-modal'
import Select from 'react-select'

import './styles.sass'

import { Store, Campaign, User, Company, SelectOption } from '../../types'
import { CampaignCard, SignedInLayout, CompanySelect } from '../../components'
import {
  getCampaignsForCompany,
  getCompanies as getCompaniesAction,
  deleteCampaign as deleteCampaignAction,
} from '../../actions'

import { duplicateCampaign, duplicateCampaignForCompany } from '../../api'
import { campaignUrl } from '../../helpers/campaign-helper'
import { companyOptions } from '../../helpers/company-helper'
import { duplicate as duplicateIcon } from '../../assets/icons'
import { defaultTheme } from '../../constants/theme'

import { Loader } from 'tyaw-components'

interface IProps {
  getCampaigns: (companyId: string, withTags: boolean) => void
  thankYouCampaigns: Campaign[]
  welcomeCampaigns: Campaign[]
  loading: Boolean
  user: User
  campaigns: {
  all: Campaign[]
  }
  companiesLoading: Boolean
  companies: Company[]
  getCompanies: () => void
  deleteCampaign: (campaign: Campaign) => void
}

interface IState {
  displayModal: boolean
  title: string
  target: 'thank_you' | 'welcome'
  openCompanyModal: boolean
  company: SelectOption
  filteredCompany: SelectOption
  currentCampaign: Campaign
}

class CampaignsScene extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    let company: Company = {}

    if (props.user.company) {
      company = props.user.company
    } else if (props.campaigns.all.length > 0) {
      company = get(props.campaigns.all, '[0].company')
    }


    this.state = {
      displayModal: false,
      title: '',
      target: 'thank_you',
      openCompanyModal: false,
      company: { value: '', label: '' },
      filteredCompany: {
        value: get(company, 'id', ''),
        label: get(company, 'name', ''),
      },
      currentCampaign: {},
    }
  }

  componentDidMount() {
    const { getCompanies } = this.props
    const { filteredCompany } = this.state

    if (filteredCompany.value !== '') {
      this.selectCompany(filteredCompany)
    }

    getCompanies()
  }

  componentDidUpdate(prevProps: IProps) {
    const { user } = this.props

    if (user !== prevProps.user && user.company) {
      this.selectCompany({
        value: get(user, 'company.id'),
        label: get(user, 'company.name'),
        details: user.company
      })
    }
  }

  selectCompany(filteredCompany: SelectOption) {
    const companyId: string = get(filteredCompany, 'value')
    this.props.getCampaigns(companyId, true)
    this.setState({filteredCompany})
  }

  renderHeader( ) {
    const { companies } = this.props
    const { filteredCompany } = this.state
    return(
    <div className="campaign-header">
      <div className="company-filter">
        <CompanySelect
          companies={companies}
          selectedCompany={filteredCompany}
          handleSelect={(company: SelectOption) => this.selectCompany(company)}
          hint={I18n.t('components.campaign.companySelectHint')}
        />
      </div>
    </div>
    )
  }

  render() {
    const { thankYouCampaigns, welcomeCampaigns, loading, user } = this.props

    if (loading) {
      return (
        <SignedInLayout>
          <Loader />
        </SignedInLayout>
      )
    }

    return (
      <SignedInLayout>
        {this.renderHeader()}
        <div className="campaigns-container">
          <div className="campaigns-list">
            <Link
              className="create-link"
              to={'/new-campaign/thank_you'}
              style={{ background: `${get(user, 'company.thankYouColor', defaultTheme.thankYouColor)}` }}
            >
              <img src={duplicateIcon} />
              {I18n.t('components.campaign.card.createTY')}
            </Link>
            {thankYouCampaigns.map(campaign => (
              <CampaignCard
                key={campaign.id}
                campaign={campaign}
                onDuplicate={(campaign: Campaign) => this.duplicate(campaign)}
                onDelete={(campaign: Campaign) =>  {if (window.confirm(I18n.t('components.campaign.card.delete.alert'))) {this.delete(campaign)}}}
                admin={user.admin}
              />
            ))}
          </div>
          <div className="campaigns-list">
            <Link
              className="create-link"
              to={'/new-campaign/welcome'}
              style={{ background: `${get(user, 'company.welcomeColor', defaultTheme.welcomeColor)}` }}
            >
              <img src={duplicateIcon} />
              {I18n.t('components.campaign.card.createWelcome')}
            </Link>
            {welcomeCampaigns.map(campaign => (
              <CampaignCard
                key={campaign.id}
                campaign={campaign}
                onDuplicate={(campaign: Campaign) => this.duplicate(campaign)}
                onDelete={(campaign: Campaign) => this.delete(campaign)}
                admin={user.admin}
              />
            ))}
          </div>
        </div>
        {this.renderCompanySelectModal()}
      </SignedInLayout>
    )
  }

  renderCompanySelectModal() {
    const { companiesLoading, companies } = this.props
    const company = this.state.company
    if (companiesLoading) {
      return <Loader />
    }

    return (
      <Modal
        isOpen={this.state.openCompanyModal}
        onRequestClose={() => this.setState({ openCompanyModal: false })}
        key="modal-invitation"
      >
        <div className="modal-container select-company-modal">
          <h2>{I18n.t(`modal.selectCompany.title`)}</h2>
          <Select
            value={company}
            onChange={(selectedOption: any) => this.setState({ company: selectedOption })}
            options={companyOptions(companies)}
          />
          {company.value !== '' && (
            <div className="button-component" onClick={() => this.onSubmitCompany()}>
              OK
            </div>
          )}
        </div>
      </Modal>
    )
  }

  onSubmitCompany() {
    const { currentCampaign, company } = this.state
    duplicateCampaignForCompany(currentCampaign, company.value).then((newCampaign: Campaign) => {
      window.location = campaignUrl(newCampaign) as any
    })
    this.setState({ openCompanyModal: false })
  }

  duplicate(campaign: Campaign) {
    if (this.props.user.admin) {
      this.setState({
        openCompanyModal: true,
        company: { label: '', value: '' },
        currentCampaign: campaign,
      })
    } else {
      duplicateCampaign(campaign).then((newCampaign: Campaign) => {
        window.location = campaignUrl(newCampaign) as any
      })
    }
  }

  delete(campaign: Campaign) {
    const { deleteCampaign } = this.props

    if (this.props.user.admin) {
      deleteCampaign(campaign)
    }
  }
}

const mapStateToProps = ({
  campaigns: { allCampaigns, loading },
  user: { currentUser },
  companies: { allCompanies, loading: companiesLoading },
}: Store) => ({
  loading,
  companiesLoading,
  thankYouCampaigns: filter(allCampaigns, { target: 'thank_you' }),
  welcomeCampaigns: filter(allCampaigns, { target: 'welcome' }),
  user: currentUser,
  companies: allCompanies,
  campaigns: {
    all: allCampaigns
  }
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCampaigns: (companyId: string, preview: boolean) => dispatch(getCampaignsForCompany(companyId, preview)),
  getCompanies: () => dispatch(getCompaniesAction()),
  deleteCampaign: (campaign: Campaign) => dispatch(deleteCampaignAction(campaign)),
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignsScene) as any)
