import { ActionsRenderer, ReactSelectCellEditor, ColorCellEditor } from '.'


export const gridOptions = {
  defaultColDef: {
    sortable: true,
    filter: true,
    resizable: true,
    editable: false,
    minWidth: 150,
    suppressKeyboardEvent: (params: any) => params.editing
  },
  accentedSort: true,
  animateRows: true,
  editType: "fullRow",
  enableCellChangeFlash: false,
  enableCellTextSelection: true,
  ensureDomOrder: true,
  immutableData: true,
  rowDragManaged: true,
  rowSelection: 'multiple',
  //singleClickEdit: true,
  suppressClickEdit: true,
  suppressRowClickSelection: true,
  tooltipShowDelay: 0,
  undoRedoCellEditing: true,
  undoRedoCellEditingLimit: 20,
  frameworkComponents: {
    reactSelectCellEditor: ReactSelectCellEditor,
    colorCellEditor: ColorCellEditor,
    actionsRenderer: ActionsRenderer
  },
  getRowNodeId: (data: any) => data.id
}
