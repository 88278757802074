import Cookie from 'js-cookie'

import { apiClient, authTokenCookieKey, secureCookie } from '../constants'

export const signIn = (payload: { email: string; password: string }) =>
  apiClient
    .post('/sessions', {
      session: {
        payload,
        strategy: 'password',
      },
    })
    .then(({ data }) => {
      Cookie.set(authTokenCookieKey, data.value, { secure: secureCookie })
      apiClient.defaults.headers.common['Authorization'] = data.value
      return data
    })

export const signUp = (payload: { email: string; password: string, managerInvitationToken: string }) =>
  apiClient
    .post('/users', {
      managerInvitationToken: payload.managerInvitationToken,
      user: {
        email: payload.email,
        password: payload.password,
      },
    })
    .then(({ data }) => data)

export const forgotPassword = (payload: { email: string} ) =>
  apiClient
    .post('/passwords', {
      user: {
        email: payload.email
      }
    })
    .then(({ data }) => data)

export const resetPassword = (payload: { password: string, passwordConfirmation: string, resetPasswordToken: string } ) =>
  apiClient
    .put('/passwords', {
      user: { ...payload }
    })
    .then(({ data }) => data)
