import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { I18n } from 'react-redux-i18n'
import Icon from '../icon'
import './styles.sass'
import { history } from '../../constants'
import { logo } from '../../assets/images'

import { Can } from '../../config/ability-context'

interface IProps {
  logoUrl?: string
}

interface StyledProps {}

const Sidebar = styled.div.attrs<StyledProps>(() => ({
  className: 'sidebar',
}))<StyledProps>`
  .tabs-container  {
    a.current {
      border-right: 7px solid;
      border-color: ${props => props.theme.primaryColor};
      color: ${props => props.theme.primaryColor};
      .icon {
        fill: ${props => props.theme.primaryColor};
      }
    }
  }
`

export default ({ logoUrl }: IProps) => {

  const links = [
    { name: "companies", icon: "settings" },
    { name: "admins", icon: "admin" },
    { name: "campaigns", icon: "question" },
    { name: "questions", icon: "question" },
    { name: "hierarchies", icon: "tags" },
    { name: "user_surveys", icon: "testimonial" },
    { name: "mosaics", icon: "dashboard" },
    { name: "statistics", icon: "statistics" }
  ]

  const navLink = (link: any) => {
    return (
      <Can key={link.name} I='access' this={link.name}>
        <NavLink activeClassName="current" to={`/${link.name}`}>
          <Icon name={link.icon} />
          {I18n.t(`components.sidebar.steps.${link.name}`)}
        </NavLink>
      </Can>
    )
  }

  return (
    <Sidebar>
      <div className="tabs-container">
        <img
          className="logo"
          src={logoUrl ? logoUrl : logo}
          onClick={() => history.push('/')}
        />
        { links.map(navLink)}
      </div>
      <div className="help-container">
        <NavLink className="help-button" to="/help">
          Besoin d'aide ?
        </NavLink>
      </div>
    </Sidebar>
  )
}
