import React, { useRef } from 'react'
import { I18n } from 'react-redux-i18n'

import { Button } from 'tyaw-components'
import { copyToClipboard } from '../../helpers/application-helper'

import Icon from '../../components/icon'

import './styles.sass'

interface IProps {
  value: string
}

const copyInput = ({value}: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <div className="copy-input-wrapper">
      <div className="text-input-component">
        <input
          className="text-input-content"
          type="text"
          readOnly={true}
          value={value}
          ref={inputRef}
        />
      </div>
      <Button
        title={I18n.t("pages.mosaic.copy")}
        type="button"
        onClick={() => copyToClipboard(inputRef.current)}
      >
        <Icon name="duplicate" size={16} />
      </Button>
    </div>
  )
}

export default copyInput
