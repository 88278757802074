import React, { useState, forwardRef, useImperativeHandle } from 'react'

interface IProps {
  value: string
}

import { iconClose } from '../../assets/icons'

const colorCellEditor = (props: IProps, ref: any) => {
  const [value, setValue] = useState<string>(props.value);

  useImperativeHandle(ref, () => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue: () => { return value }
    }
  })

  return (
    <div className="color-cell-editor">
      <input type="color"
         value={value}
         onChange={(event: any) => setValue(event.target.value)}
      />

      <button
        key="cancel-button"
        className="button-icon"
        onClick={() => setValue('')}
      >
        <img src={iconClose} />
      </button>
    </div>
  )
}

export default forwardRef(colorCellEditor)
