import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { withRouter, RouteComponentProps } from 'react-router'

/* redux */
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { I18n, Translate } from 'react-redux-i18n'

/* libs */
import { get } from 'lodash'
import { Button, Loader, Checkbox, TextInput } from 'tyaw-components'
import { Can } from '../../config/ability-context'
import { history } from '../../constants'

/* app */
import { Company, Store, User } from '../../types'
import { formattedDataCompany } from '../../normalizers/company_normalizer'
import { pickerColor } from '../../assets/icons'
import { SignedInLayout, FileInput, TextEditor } from '../../components'
import { notification } from '../../helpers/application-helper'

import { getCompany, updateCompany, createCompany } from '../../api'

import './styles.sass'

interface IProps extends RouteComponentProps<any> {
  user: User
}

interface IState {
  company: Company
  loading: boolean
  mode: string
  fileName: any
  displayUserMessages: boolean
  displayEmail: boolean
  displayEmailGuest: boolean
  displayEmailUser: boolean
  displayEmailAdmin: boolean
}


class CompanyScene extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const {
      match: {
        params: { id },
      }
    } = props

    this.state = {
      loading: (id === "new" ? false : true),
      mode: (id === "new" ? "create" : "edit"),
      company: { id: id, primaryColor: "#000", thankYouColor: "#000", welcomeColor: "#000" },
      fileName: null,
      displayUserMessages: false,
      displayEmail: false,
      displayEmailGuest: false,
      displayEmailUser: false,
      displayEmailAdmin: false
    }
  }

  componentDidMount() {
    const { company, mode } = this.state

    if (mode === "edit") {
      getCompany(get(company, "id")).then((company: Company) => {
        this.setState({ company, loading: false })
      })
    }
  }

  saveCompany(id?: string) {
    const { mode } = this.state
    const formData = formattedDataCompany(this.state.company, id)

    this.setState({loading: true})

    if (mode === "edit") {
      updateCompany(formData).then((company: Company) => {
        notification('success', 'company.update')
        this.setState({ company, loading: false })
      }).catch((error: any) => {
        this.setState({ loading: false })
        notification('error', 'company.update', { apiErrors: get(error, 'response.data', []) })
        return
      })
    } else {
      createCompany(formData).then((company: Company) => {
        notification('success', 'company.create')
        this.setState({ company, loading: false, mode: "edit" })
        history.push(`${company.id}`)
      }).catch((error: any) => {
        this.setState({ loading: false })
        notification('error', 'company.create', { apiErrors: get(error, 'response.data', []) })
        return
      })
    }
  }

  hideDiv(divToHide: string) {
    switch (divToHide) {
      case 'messages':
        this.state.displayUserMessages ? this.setState( {displayUserMessages: false}) 
        : this.setState({displayUserMessages: true})
        break
      case 'email':
        this.state.displayEmail ? this.setState( {displayEmail: false}) 
        : this.setState({displayEmail: true})
        break
      case 'guest':
        this.state.displayEmailGuest ? this.setState( {displayEmailGuest: false}) 
        : this.setState({displayEmailGuest: true})
        break
      case 'user':
        this.state.displayEmailUser ? this.setState( {displayEmailUser: false}) 
        : this.setState({displayEmailUser: true})
        break
      case 'admin':
        this.state.displayEmailAdmin ? this.setState( {displayEmailAdmin: false}) 
        : this.setState({displayEmailAdmin: true})
        break
    }

  }

  renderColorInput(colorName: string) {
    const {company} = this.state

    return (
      <Fragment>
        <img className="color-input-icon" src={pickerColor} />
        <input
          className="color-input"
          type="color"
          id={colorName}
          name={colorName}
          onChange={(e: any) => {
            this.setState({ company: { ...company, [colorName]: e.target.value } })
          }}
          value={get(company, colorName, '') || ''}
        />
        <label className="color-input-label" htmlFor={colorName}>
          {I18n.t(`models.company.${colorName}`)}
        </label>
      </Fragment>
    )
  }

  renderEmailsInput(updatedKey: string, translationKey: string) {
    const { company } = this.state
    const emails = get(company, 'emails', {})

    return(
      <TextInput
        label={I18n.t(`shared.emails.${translationKey}`)}
        placeholder=""
        onChangeText={(text: string) => this.setState(
          {
            company: {
              ...company,
              emails: { ...emails, [updatedKey]: text }
            }
          }
        )}
        className="white"
        value={get(emails, updatedKey, '')}
      />
    )
  }

  renderTextArea(displayed: boolean, textAreaName: string, updatedSubkey?: string) {
    const { company } = this.state

    const attributeId: string = updatedSubkey ? `${updatedSubkey}.${textAreaName}` : textAreaName

    return (
        <TextEditor
          label={I18n.t(`models.company.${attributeId}`)}
          attributeId={attributeId}
          value={get(company, attributeId, '')}
          handleChange={(text: string) => {
            if (updatedSubkey) {
              const subkeyInfo = get(company, updatedSubkey)
              this.setState({ company: { ...company, [updatedSubkey]: { ...subkeyInfo, [textAreaName]: text } } })
            } else {
              this.setState({ company: { ...company, [textAreaName]: text } })
            }
          }}
          displayed={displayed}
        />
    )
  }

  render() {
    const { company, loading, mode } = this.state
    const primaryColor = get(company, 'primaryColor')

    if (loading) {
      return (
        <SignedInLayout>
          <Loader />
        </SignedInLayout>
      )
    }

    return (
      <SignedInLayout>
        <div className="page-container">

          <div className="page-header">
            <NavLink to="/companies" className="back">
              <Button type="button" className="button-danger button-back">
                {I18n.t('pages.back')}
              </Button>
            </NavLink>

            <Button
              type="button"
              primaryColor={primaryColor}
              onClick={() => this.saveCompany(get(company, 'id'))}
            >
              {I18n.t(`pages.company.button.${mode}`)}
            </Button>

            <div className="page-title">
            {I18n.t(`pages.company.title.${mode}`, {company: get(company, 'name')})}
            </div>

          </div>

          <div className="settings-container-section">

            <div className="textareas-container">

              <h3>
                  {I18n.t('models.company.messages')}
                  <button className='button-display' onClick={() => this.hideDiv('messages')}>
                    {this.state.displayUserMessages ? '-' : '+'}
                  </button>
              </h3>

              {this.state.displayUserMessages
              ?
              <div className='customization-container-section'>

                {this.renderTextArea(false, 'context')}

                {this.renderTextArea(false, 'thankingMessage')}

              </div>
              : <div></div>
              }

              <h3>
                {I18n.t('shared.emails.title')}
                <button className='button-display' onClick={() => this.hideDiv('email')}>
                  {this.state.displayEmail ? '-' : '+'}
                </button>
              </h3>

              {this.state.displayEmail
              ?
              <div className='customization-container-section'>

                <p><Translate value="shared.emails.help" dangerousHTML /></p>

                <h4>
                  {I18n.t('shared.emails.guest.title')}
                  <button className='button-display' onClick={() => this.hideDiv('guest')}>
                    {this.state.displayEmailGuest ? '-' : '+'}
                  </button>
                </h4>
                
                {this.state.displayEmailGuest
                ?
                <div className='customization-container-section'>
                  <div className='emails-inputs'>
                    {this.renderEmailsInput('guestInvitationSubject', 'subject') }
                    {this.renderEmailsInput('guestInvitationButton', 'button') }
                  </div>

                  { this.renderTextArea(true, 'guestInvitationBody', 'emails') }
                  <small><Translate value="shared.emails.guest.shortcodes" dangerousHTML /></small>
                </div>
                : <div></div>
                }

                <h4>
                  {I18n.t('shared.emails.user.title')}
                  <button className='button-display' onClick={() => this.hideDiv('user')}>
                    {this.state.displayEmailUser ? '-' : '+'}
                  </button>
                </h4>

                {this.state.displayEmailUser
                ?
                <div className='customization-container-section'>
                  <div className='emails-inputs'>
                    {this.renderEmailsInput('userInvitationSubject', 'subject')}
                    {this.renderEmailsInput('userInvitationButton', 'button')}
                  </div>

                  {this.renderTextArea(true, 'userInvitationBody', 'emails')}
                  <small><Translate value="shared.emails.user.shortcodes" dangerousHTML /></small>
                </div>
                : <div></div>
                }

                <h4>
                  {I18n.t('shared.emails.manager.title')}
                  <button className='button-display' onClick={() => this.hideDiv('admin')}>
                    {this.state.displayEmailAdmin ? '-' : '+'}
                  </button>
                </h4>

                {this.state.displayEmailAdmin
                ?
                <div className='customization-container-section'>
                  <div className='emails-inputs'>
                    {this.renderEmailsInput('managerInvitationSubject', 'subject')}
                    {this.renderEmailsInput('managerInvitationButton', 'button')}
                  </div>

                  {this.renderTextArea(true, 'managerInvitationBody', 'emails')}
                  <small><Translate value="shared.emails.manager.shortcodes" dangerousHTML /></small>
                </div>
                : <div></div>
                }
              </div>
              : <div></div>
              }
            </div>

            <div className="settings-container">
              <h3 className="settings-container-title">
                {I18n.t(`pages.company.settings.${mode}`)}
              </h3>

              <TextInput
                label={I18n.t('models.company.name')}
                placeholder=""
                onChangeText={(name: string) => this.setState({ company: { ...company, name } })}
                value={get(company, 'name', "")}
              />

              <div className="color-pickers-container">
                <p><Translate value="pages.company.colors" /></p>

                <div className="color-picker-card">{this.renderColorInput('primaryColor')}</div>
                <div className="color-picker-card">{this.renderColorInput('thankYouColor')}</div>
                <div className="color-picker-card">{this.renderColorInput('welcomeColor')}</div>
              </div>

              <div className="files-container">
                <FileInput
                  fileName="logo"
                  fileUrl={get(company, 'logoUrl')}
                  currentFile={get(company, 'logo')}
                  handleChange={(e: any) => {
                    this.setState({ company: { ...company, logo: e.target.files[0] } })
                  }}
                />

                <FileInput
                  fileName="background"
                  fileUrl={get(company, 'backgroundUrl')}
                  currentFile={get(company, 'background')}
                  handleChange={(e: any) => {
                    this.setState({ company: { ...company, background: e.target.files[0] } })
                  }}
                />
              </div>

              <Checkbox
                label={ I18n.t('models.company.showEmails') }
                isChecked={ get(company, 'activatedDisplayEmails', false) }
                onChange={(activatedDisplayEmails: boolean) => {
                  this.setState({ company: { ...company, activatedDisplayEmails } })
                }}
              />

              <Can I="manage" this="tags">
                <Checkbox
                  label={ I18n.t('models.company.tags') }
                  isChecked={ get(company, 'activatedTags', false) }
                  onChange={(activatedTags: boolean) => {
                    this.setState({ company: { ...company, activatedTags } })
                  }}
                />
              </Can>
            </div>
          </div>
        </div>
      </SignedInLayout>
    )
  }
}

const mapStateToProps = ({
  user: { currentUser },
}: Store) => ({
  user: currentUser,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompanyScene) as any)
