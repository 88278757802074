import React, { useState } from 'react'

/* Ag grid */
import { GridApi, GridOptions, FirstDataRenderedEvent, GridReadyEvent, RowValueChangedEvent, RowDragEndEvent } from 'ag-grid-community/main.d'

/* app */
import { gridOptions } from '../../components/ag-grid'
import { GridSkeletonProps, GridSharedProps } from '../../types'

export function gridSkeleton<T extends GridSharedProps = GridSharedProps>(
  WrappedGrid: React.ComponentType<T>,
  optionsOverwrites: GridOptions = {}
) {

  const displayName =
    WrappedGrid.displayName || WrappedGrid.name || "Component";

  //
  const FullGrid = (props: Omit<T, keyof GridSkeletonProps>) => {
    const { onGridReady, handleChanges, onRowDragEnd } = props
    const options: GridOptions = { ...gridOptions, ...optionsOverwrites }

    const [gridApi, setGridApi] = useState<GridApi>()

    if (handleChanges) {
      options.onRowValueChanged = (event: RowValueChangedEvent) => handleChanges(event)
    }

    const gridProps: GridSkeletonProps = {
      options,
      gridApi,
      firstDataRendered: (event: FirstDataRenderedEvent) => {
        if (event.api) {
          event.api.sizeColumnsToFit()
        }
      },
      gridReady: (event: GridReadyEvent) => {
        onGridReady(event)
        setGridApi(event.api)
      },
      refreshCells: (event: any) => {
        event.api.refreshCells({ columns: ['actions', 'first-column'], force: true, suppressFlash: true})
      },
      rowDragEnd: (event: RowDragEndEvent) => {
        onRowDragEnd && onRowDragEnd(event)
      }
    }

    return (
      <div className="ag-theme-alpine ag-grid">
        <WrappedGrid {...gridProps} {...(props as T)} />
      </div>
    )
  }

  FullGrid.displayName = `gridSkeleton(${displayName})`

  return FullGrid
}
