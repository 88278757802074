import React from 'react'

import { GridApi } from 'ag-grid-community/main.d'

import { Button } from 'tyaw-components'

interface IProps {
  gridApi: GridApi
}

const exportGridComponent = (props: IProps) => {
  const { gridApi } = props

  const exportGrid = () => {
    gridApi.exportDataAsCsv()
  }

  if (gridApi) {
    return (
      <Button
        onClick={() => exportGrid()}
        type="button"
      >
        Exporter
      </Button>
    )
  } else {
    return null
  }
}

export default exportGridComponent
