import React from 'react'
import Navbar from '../../containers/navbar'
import Sidebar from '../../containers/sidebar'

import './styles.sass'

interface IProps {
  currentUser?: string,
  children: any,
  hideSidebar?: boolean
  logoUrl?: string
}

export default ({children, hideSidebar, logoUrl, currentUser}: IProps)  => (

  <div className="signed-in-layout">
    { hideSidebar ? <img className="logo" src={logoUrl} alt='logo' /> : <Sidebar />}
    <div className="layout-container">
      { hideSidebar ? null : <Navbar />}
      <div className="main-container">{children}</div>
    </div>
  </div>
)
