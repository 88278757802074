import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router'
import { setLocale, loadTranslations, syncTranslationWithStore } from 'react-redux-i18n'
import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider } from 'styled-components'
import ReduxToastr from 'react-redux-toastr'
import { get } from 'lodash'

import './index.css'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'

import { AbilityContext } from './config/ability-context'
import ability from './config/ability'
import { getToken, redirectHome } from './helpers/application-helper'
import {
  getCompanies as getCompaniesAction,
  getSurveys as getSurveysAction,
  updateUser,
} from './actions'
import { history } from './constants'
import {
  CampaignScene,
  CampaignsScene,
  CampaignStepScene,
  CompaniesScene,
  CompanyScene,
  ForgotPasswordScene,
  HelpScene,
  HierarchiesScene,
  LoginScene,
  MosaicScene,
  MosaicsScene,
  NewCampaignScene,
  PublicMosaicScene,
  QuestionsScene,
  ResetPasswordScene,
  SignupScene,
  StatisticsScene,
  TagsScene,
  UsersScene,
  UserSurveyScene,
  UserSurveysScene,
} from './scenes'

import translations from './i18n'
import store from './store'
import { User, Theme } from './types'
import { getCurrentUser } from './api/user'
import { defaultTheme } from './constants/theme'
import { getCompanies } from './api'

interface IState {
  user: User | null
  theme: Theme
}

class App extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props)

    this.setI18n()

    this.state = {
      user: null,
      theme: defaultTheme,
    }
  }

  componentDidMount() {
    // publicURLs = URLs accessible without login
    const publicURLs = ['/signup', '/forgot-password', '/reset-password', '/mosaics/public', '/temoignages/public']

    if (publicURLs.indexOf(window.location.pathname) === -1) {
      getCurrentUser()
        .then(data => {
          if (window.location.pathname === '/login') {
            redirectHome(data)
          } else {
            this.setState({ user: data })
            this.setTheme()
            this.getInitialData()
            store.dispatch(updateUser(data))
          }
        })
        .catch(() => {
          if (window.location.pathname !== '/login') {
            window.location = '/login' as any
          }
        })
    }
  }

  getInitialData() {
    store.dispatch(getSurveysAction())
    store.dispatch(getCompaniesAction())
  }

  setI18n() {
    syncTranslationWithStore(store)
    store.dispatch(loadTranslations(translations) as any)
    const lang = (getToken('lang') || '').toLowerCase() === 'en' ? 'en' : 'fr'

    store.dispatch(setLocale(lang) as any)
  }


  getToken(tokenName: string) {
    const urlString = window.location.href
    const url = new URL(urlString)

    return url.searchParams.get(tokenName)
  }

  isGuest() {
    return this.getToken('guest_token') || this.getToken('mosaic_token')
  }

  setTheme() {
    const { user } = this.state

    if (user && !user.admin) {
      getCompanies().then(companies =>
        this.setState({
          theme: {
            primaryColor: get(companies, '[0].primaryColor') || defaultTheme.primaryColor,
            thankYouColor: get(companies, '[0].thankYouColor') || defaultTheme.thankYouColor,
            welcomeColor: get(companies, '[0].welcomeColor') || defaultTheme.welcomeColor,
          },
        }),
      )
    }
  }

  home(user: User | null) {
    const canAccess = user && (user.admin || user.company !== null)
    if (canAccess && user) {
      if (user.admin) {
        return <Route render={() => <CompaniesScene />} />
      } else {
        return <Route render={() => <UserSurveysScene />} />
      }
    } else {
      return <Route render={() => <LoginScene />} />
    }
  }

  render() {
    const { user } = this.state

    return (
      <ThemeProvider theme={this.state.theme}>
        <AbilityContext.Provider value={ability(user)}>
          <Provider store={store}>
            <ReduxToastr
              timeOut={4000}
              newestOnTop={false}
              preventDuplicates={true}
              position="top-right"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar={true}
              closeOnToastrClick={true}
            />
            <ConnectedRouter history={history}>
              <Switch>
                { /* Public urls */ }
                <Route exact={true} path="/login" render={() => <LoginScene />} />
                <Route exact={true} path="/signup" render={() => <SignupScene />} />
                <Route exact={true} path="/forgot-password" render={() => <ForgotPasswordScene />} />
                <Route exact={true} path="/reset-password" render={() => <ResetPasswordScene />} />
                <Route exact={true} path="/mosaics/public" render={() => <PublicMosaicScene />} />
                <Route exact={true} path="/temoignages/public" render={() => <UserSurveyScene />} />
                { /* Logged in urls */ }
                <Route exact={true} path="/admins" render={() => <UsersScene />} />
                <Route exact={true} path="/companies" render={() => <CompaniesScene />} />
                <Route exact={true} path="/companies/:id" render={() => <CompanyScene />} />
                <Route exact={true} path="/help" render={() => <HelpScene />} />
                <Route exact={true} path="/campaigns" render={() => <CampaignsScene />} />
                <Route exact={true} path="/campaigns/:id" render={() => <CampaignScene />} />
                <Route exact={true} path="/questions" render={() => <QuestionsScene />} />
                <Route exact={true} path="/hierarchies" render={() => <HierarchiesScene />} />
                <Route exact={true} path="/hierarchies/:id" render={() => <TagsScene />} />
                <Route exact={true} path="/mosaics" render={() => <MosaicsScene />} />
                <Route exact={true} path="/mosaics/:id" render={() => <MosaicScene />} />
                <Route exact={true} path="/campaign-steps/:id" render={() => <CampaignStepScene />} />
                <Route exact={true} path="/new-campaign/:target" render={() => <NewCampaignScene />} />
                <Route exact={true} path="/user_surveys" render={() => <UserSurveysScene />} />
                <Route exact={true} path="/user_surveys/:id" render={() => <UserSurveyScene />} />
                <Route exact={true} path="/statistics/" render={() => <StatisticsScene />} />
                { this.home(user) }

              </Switch>
            </ConnectedRouter>
          </Provider>
        </AbilityContext.Provider>
      </ThemeProvider>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))
