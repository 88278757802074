import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'

import { get } from 'lodash'

import { Loader } from 'tyaw-components'

import { Mosaic, Store } from '../../types'

import { MosaicView } from '../../components'

import {
  getMosaicFromToken
} from '../../actions'

import './styles.sass'

interface IProps extends RouteComponentProps<any> {
  mosaic: Mosaic
  loading: Boolean
  getMosaic: (token: string, meta: any) => void
}

interface IState {
}

class PublicMosaic extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    // const { match: { params: { token }}}

    super(props)
  }

  componentDidMount() {
    const { getMosaic } = this.props
    const url = new URL(window.location.href)
    const token = url.searchParams.get('access_token') || ''

    getMosaic(token, {})
  }

  render() {
    const { mosaic, loading } = this.props

    if (loading) {
      return (
        <Loader />
      )
    }

    return (
      <div className="public-mosaic">
        { mosaic.shared ?
          <MosaicView
            mode={'show'}
            mosaic={mosaic}
            company={get(mosaic, 'company')}
          /> :

          <p className="info-box">{I18n.t('alerts.mosaic.show.error')}</p>
        }
      </div>
    )
  }
}

const mapStateToProps = () => ({
  mosaics: { mosaic, loading }
}: Store) => ({
  loading,
  mosaic
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getMosaic: (token: string, meta: any) => dispatch(getMosaicFromToken(token, meta))
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PublicMosaic) as any
)
