import { handle } from 'redux-pack'

import {
  GET_QUESTION_TYPES
} from '../constants'
import { QuestionType } from '../types'

interface IState {
  allQuestionTypes: QuestionType[]
  loading: boolean
}

const initialState: IState = {
  allQuestionTypes: [],
  loading: false
}

export default (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case GET_QUESTION_TYPES:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({ ...prevState, allQuestionTypes: payload }),
      })
    default:
      return state
  }
}
