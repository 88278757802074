import { apiClient } from '../constants'
import { Survey, SurveyTarget } from '../types'

export const getSurveys = (target?: SurveyTarget) =>
  apiClient.get('/company_surveys', { params: { target } }).then(({ data }) => data)

export const getSurvey = (id: string) =>
  apiClient.get(`/company_surveys/${id}`).then(({ data }) => data)

export const createSurvey = (companySurvey: Survey) =>
  apiClient.post('/company_surveys', { companySurvey })
    .then(({ data }) => data)

export const updateSurvey = (companySurvey: Survey) =>
  apiClient.patch(`/company_surveys/${companySurvey.id}`, { companySurvey })
    .then(({ data }) => data)

export const getTemplateSurveys = (target?: SurveyTarget) =>
  apiClient.get('/template_company_surveys', { params: { target } }).then(({ data }) => data)

export const selectQuestions = (companySurvey: Survey, templateQuestionIds: string[]) =>
  apiClient.patch(`/company_surveys/${companySurvey.id}/select_questions`, { templateQuestionIds }).then(({ data }) => data)
