import React from 'react'

import DropdownTreeSelect, { TreeData, TreeNode } from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'

import { isEqual } from 'lodash'

import { I18n } from 'react-redux-i18n'

import './styles.sass'

interface IProps {
  data: TreeData
  onChange: (currentNode: TreeNode, selectedNodes: TreeNode[]) => void
}

interface IState {
  data: TreeData
}

class treeData extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      data: props.data
    }
  }

  componentWillReceiveProps (nextProps: IProps) {
    if(!isEqual(nextProps.data, this.state.data)) {
      this.setState({ data: nextProps.data })
    }
  }

  shouldComponentUpdate(nextProps: IProps) {
    return !isEqual(nextProps.data, this.state.data)
  }

  render() {
    const { onChange } = this.props
    const { data } = this.state

    return (
      <DropdownTreeSelect
        texts={{
          placeholder: I18n.t('components.treeSelect.placeholder'),
          inlineSearchPlaceholder: I18n.t('components.treeSelect.inlineSearchPlaceholder'),
          noMatches: I18n.t('components.treeSelect.noMatches'),
          //label: I18n.t('components.treeSelect.label'),
          labelRemove: I18n.t('components.treeSelect.labelRemove')
        }}
        keepTreeOnSearch={true}
        keepChildrenOnSearch={true}
        showPartiallySelected={true}
        data={data}
        onChange={onChange}
      />
    )
  }
}

export default treeData
