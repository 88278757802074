import { Mosaic, Tile } from '../types'
import { appendObjectToFormData } from './normalizer_methods'
import { forEach } from 'lodash'

export const formattedDataMosaic = (mosaic: Mosaic) => {
  const formData = new FormData()
  const skippedParams = ['createdAt', 'updatedAt', 'company', 'tiles', 'key']

  appendObjectToFormData(mosaic, 'mosaic', formData, skippedParams)

  forEach(mosaic.tiles, (tile: Tile, index: number) =>
    appendObjectToFormData(tile, `mosaic[tiles_attributes][${index}]`, formData, skippedParams)
  )

  return formData
}
