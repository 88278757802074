import React, { Fragment } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import Modal from 'react-modal'
// import Cookie from 'js-cookie'
// import { authTokenCookieKey } from '../../constants/cookies'

/* redux */
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { I18n, Translate, Localize } from 'react-redux-i18n'
import { toastr } from 'react-redux-toastr'

/* libs */
import { get, map, isEmpty } from 'lodash'
import { Button, Loader, VideoPlayer, TextInput } from 'tyaw-components'
import { TreeData, TreeNode } from 'react-dropdown-tree-select'
import classnames from 'classnames'
import styled, { css } from 'styled-components'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.bubble.css'

/* app */
import { Can } from '../../config/ability-context'
import { answerIsEmpty } from '../../helpers/answer-helper'
import { notification } from '../../helpers/application-helper'
import { User, Store, UserSurvey, GuestAuthorizationToken, Answer, Company } from '../../types'
import { resendSurveyInvitation, validateUserSurvey, deleteUserSurvey, unvalidateUserSurvey, completeUserSurvey, uncompleteUserSurvey } from '../../actions'
import { getUserSurvey, shareUserSurvey, getSurveyTags, getGuestAuthorizationToken, createStatistic, changeUserSurveyOwner, downloadUserSurvey } from '../../api'
import { SignedInLayout, ListDocuments, TagsList, ChangeSurveyOwnerModal } from '../../components'

import './styles.sass'
import { logo } from '../../assets/images'
import { audioIcon } from '../../assets/icons'

interface IProps extends RouteComponentProps<any> {
  user: User
  resendSurveyInvitation: (_: any) => void
  validateSurvey: (_: any) => void
  unvalidateSurvey: (_: any) => void
  completeSurvey: (_: any) => void
  uncompleteSurvey: (_: any) => void
  deleteSurvey: (id: any, meta: any) => void
}

interface IState {
  survey: UserSurvey
  email: string
  answers: Answer[]
  currentIndex: number
  openModal: boolean
  loading: boolean
  tags: TreeData,
  selectedTags: TreeNode[]
  displayEmails: boolean
  token: string | null
}

interface CProps {
  survey: UserSurvey
}

const Container = styled.div.attrs<CProps>(({ survey }: CProps) => ({
  className: classnames('user-survey-scene', get(survey, 'companySurvey.target', '')),
}))<CProps>`
  ${props =>
    css`
      &.thank_you .order-number {
        background: ${props.theme.thankYouColor};
      }

      &.thank_you .container .navigation .question {
        border-left: 2px solid ${props.theme.thankYouColor};
        color: ${props.theme.thankYouColor};
      }

      &.welcome .order-number {
        background: ${props.theme.welcomeColor};
      }

      &.welcome .container .navigation .question {
        border-left: 2px solid ${props.theme.welcomeColor};
        color: ${props.theme.welcomeColor};
      }
    `};
`

class SurveysScene extends React.Component<IProps, IState> {

  fromMosaic: boolean

  constructor(props: IProps) {
    super(props)

    this.fromMosaic = false

    this.state = {
      email: '',
      survey: {},
      answers: [],
      tags: [],
      selectedTags: [],
      currentIndex: 0,
      loading: true,
      openModal: false,
      displayEmails: false,
      token: '',
    }
  }

  setToken() {
    const url: URL = new URL(window.location.href)
    let token: string | null = null

    if (url.searchParams.get('mosaic_token')) {
      token = url.searchParams.get('mosaic_token')
      this.fromMosaic = true
    } else {
      token = url.searchParams.get('guest_token')
    }

    this.setState({
      token
    })

    return token
  }

  handleVideoFirstPlay(played: boolean, currentAnswer: Answer) {
    createStatistic('Video', get(currentAnswer, 'video.id').toString(), 'read', this.fromMosaic, this.state.token)
  }

  handleVideoEnded(ended: boolean, currentAnswer: Answer) {
    createStatistic('Video', get(currentAnswer, 'video.id').toString(), 'read_full', this.fromMosaic, this.state.token)
  }

  handleOwnerChange(newOwner: User) {
    const { survey } = this.state

    changeUserSurveyOwner(survey, newOwner).then((survey: UserSurvey) => {
      this.setState({ survey })
      notification('success', 'survey.ownerChanged')
    }).catch(() => {
      notification('error', 'survey.ownerChanged')
    })
  }

  componentDidMount() {
    const url = new URL(window.location.href)

    const id: string = get(this.props, 'match.params.id', url.searchParams.get('survey_id'))
    let token: string | null = this.setToken()


    if (id) {
      getUserSurvey(id, token, this.fromMosaic).then((data: UserSurvey) => {
        this.setState({
          survey: data,
          answers: get(data, 'answers'),
          loading: false
        })

        getSurveyTags(id, token, this.fromMosaic).then(({ tags, selectedTags }) => {
          this.setState({ tags, selectedTags })
        })

        // use this when getAnswers API is ok
        // getAnswers(id, token).then((data: Answer[]) => {
        //   this.setState({
        //     answers: data
        //   })
        // })
      }).catch(() => {
        this.setState({ loading: false })
        notification('error', 'userSurvey.show')
      })
    }

    let displayEmails = false

    if (token) {
      getGuestAuthorizationToken(token).then((guestAuthorizationToken: GuestAuthorizationToken) => {
          displayEmails = get(guestAuthorizationToken, 'companyOptions.activatedDisplayEmails', false)
          this.setState({ displayEmails })
      })
    }

  }

  validate() {
    const { validateSurvey } = this.props
    const { survey } = this.state

    validateSurvey(survey)
    window.location.reload()
  }

  unvalidate() {
    const { unvalidateSurvey } = this.props
    const { survey } = this.state

    unvalidateSurvey(survey)
    window.location.reload()
  }

  complete() {
    const { completeSurvey } = this.props
    const { survey } = this.state

    completeSurvey(survey)
    window.location.reload()
  }

  uncomplete() {
    const { uncompleteSurvey } = this.props
    const { survey } = this.state

    uncompleteSurvey(survey)
    window.location.reload()
  }

  delete() {
    const { deleteSurvey, history } = this.props
    const { survey } = this.state

    deleteSurvey(
      {
        id: get(survey, 'id'),
      },
      {
        onSuccess: () => {
          history.push('/user_surveys')
          toastr.success('Témoignage bien supprimé', 'Le témoignage a bien été supprimé')
        },
        onFailure: () => {
          toastr.error('Erreur', 'Erreur lors de la suppression')
        }
      },
    )
  }

  resendInvitation() {
    const { resendSurveyInvitation } = this.props
    const { survey } = this.state

    resendSurveyInvitation(survey)
  }

  async handleShareSubmit(e: any) {
    const {
      survey: { id },
    } = this.state
    const { email } = this.state

    e.preventDefault()

    try {
      await shareUserSurvey({
        targetResourceType: 'UserSurvey',
        targetResourceId: id,
        guestEmail: email,
      })

      this.setState({ openModal: false }, () =>
        toastr.success('Partage Envoyé', 'Votre partage a bien été envoyé'),
      )
    } catch (err) {
      toastr.error('Erreur lors de votre partage', err as string)
    }
  }

  renderEmails(displayEmails: boolean, email: string, surveyLabel: string) {
    if ( displayEmails ) {
      return(
        <small>
        { I18n.t('pages.userSurvey.contactMe') } <a href={"mailto: " + email + "?subject=" + surveyLabel }>{email}</a>
        </small>
      )
    }
  }

  renderModal() {
    const { openModal, email, survey } = this.state
    const primaryColor = get(survey, 'company.primaryColor')

    return (
      <Modal
        isOpen={openModal}
        onRequestClose={() => this.setState({ openModal: false })}
        key="modal-share"
      >
        <div className="modal-container">
          <h2>{I18n.t(`pages.shareUserSurvey.title`)}</h2>
          <p>{I18n.t(`pages.shareUserSurvey.subTitle`)}</p>

          <form onSubmit={e => this.handleShareSubmit(e)}>
            <TextInput
              placeholder="email;email*"
              value={email}
              onChangeText={(e: any) => this.setState({ email: e })}
            />
            <Button type="submit" primaryColor={primaryColor}>
              {I18n.t(`pages.shareUserSurvey.button`)}
            </Button>
          </form>
        </div>
      </Modal>
    )
  }


  renderDates() {
    const { survey }  = this.state
    const sentAt      = survey.createdAt ? survey.createdAt.toString() : ''
    const resentAt    = survey.resentAt ? survey.resentAt.toString() : ''

    return (
      <Fragment>
        <p>
          <Translate value="pages.userSurvey.sentAt" />
          <Localize value={sentAt} dateFormat="date.short" />
          { resentAt &&
            <Fragment>
              <br />
              <Translate value="pages.userSurvey.resentAt" />
              <Localize value={resentAt} dateFormat="date.short" />
            </Fragment>
          }
          <br />
          <Can I="access" this="userInvitationLink">
            <a href={get(survey, 'userInvitationLink') } className="user-survey-link" target="_blank">{I18n.t('pages.userSurvey.surveylink')} </a>
          </Can>
        </p>
      </Fragment>
    )
  }

  renderAnswerLink(answer: Answer, idx: number) {
    const { currentIndex } = this.state

    if (answerIsEmpty(answer)) {
      return null
    } else {
      return (
        <div
          className={classnames('question', { active: currentIndex === idx })}
          key={answer.id}
          onClick={() => this.setState({ currentIndex: idx })}
        >
        <Can I="see" this="questionNumber">{get(answer, 'question.order', 0) + 1} -  </Can>
        {get(answer, 'question.questionType.name')}
        </div>
      )
    }
  }

  renderVideo(currentAnswer: Answer) {
    const mediaUrl: string = get(currentAnswer, 'video.publicSignedUrl')
    const audioOnly: boolean = get(currentAnswer, 'video.audioOnly')


    let mediaElt: any = undefined;

    if (mediaUrl) {
      if (audioOnly) {
        mediaElt = <div key="video" className="audio-only-container">
          <video
            controls={true}
            playsInline={true}
            poster={audioIcon}
            preload="metadata">
            <source src={mediaUrl} type="audio/webm">
            </source>
          </video>
        </div>
      } else {
        mediaElt = <div key="video">
            <VideoPlayer
              src={mediaUrl}
              autoplayOption={false}
              handleVideoFirstPlay={(played: boolean) => this.handleVideoFirstPlay(played, currentAnswer)}
              handleVideoEnded={(ended: boolean) => this.handleVideoEnded(ended, currentAnswer)}
            />
          </div>
      }
    }

    return (
      <Fragment>
        <div className="video-player-container">
          { mediaElt }
        </div>
        {/* Dirty fix for videoplayer vjs-control-bar on below components  */}
        <div><p><br></br></p></div>
        {isEmpty(get(currentAnswer, 'video')) ? null : (
        <Can I='download' this='videos'>
        <div className="videoActions-downloadButton">
              <Button
                className="delete-button"
                primaryColor={'primaryColor'}
                onClick={() => {
                  if ( window.confirm(I18n.t('alerts.video.message')))
                    window.open(mediaUrl,'_blank')
                    createStatistic('Video', get(currentAnswer, 'video.id').toString(), 'download', this.fromMosaic, this.state.token)
                } }
              >
                {I18n.t('pages.userSurvey.downloadVideoBtn')}
              </Button>
          </div>
          </Can>
        )}
      </Fragment>
    )
  }

  renderAnswer() {
    const { user } = this.props
    const { answers, currentIndex, loading, survey } = this.state
    const currentAnswer = get(answers, currentIndex)

    if (loading) {
      return (
        <Loader />
      )
    } else if (!currentAnswer) {
      if (survey.status === 'in_progress' && user.termsAcceptedAt !== undefined) {
        return (
          <p>{I18n.t('pages.userSurvey.errors.no_answers')}</p>
        )
      } else {
        return (
          <p>{I18n.t('pages.userSurvey.errors.load_answer')}</p>
        )
      }
    }

      const documents = get(currentAnswer, 'documents', [])
      const testimonial = get(currentAnswer, 'testimonial', '')

      return (
        <Fragment>
          <div className="answer-content">
            <div className="testimonial">
              <div className="answer-header">
              <Can I="see" this="questionNumber">
                <span className="order-number">{get(currentAnswer, 'question.order', 0) + 1}</span>
              </Can>
                <p>{get(currentAnswer, 'question.title', '')}</p>
              </div>
              <Can I="see" this="videoStats">
                <div>
                  <p>
                    {I18n.t(`pages.userSurvey.videoView1`)} {get(currentAnswer,'video.statistic.hit.total.read', '0')} {I18n.t(`pages.userSurvey.videoView2`)}
                    {I18n.t(`pages.userSurvey.videoDownload`)} {get(currentAnswer,'video.statistic.hit.total.download', '0')} {I18n.t(`pages.userSurvey.videoView2`)}
                  </p>
                </div>
              </Can>

              { this.renderVideo(currentAnswer) }

              { answerIsEmpty(currentAnswer) ?
                  <p className="text">
                    <Translate value="pages.userSurvey.noAnswer" />
                  </p>
                : null
              }
              { testimonial ?
                  <div className="text">
                    <h2>{I18n.t(`pages.userSurvey.title`)}</h2>
                    <ReactQuill
                      value= {testimonial}
                      readOnly={true}
                      theme={"bubble"}
                    />
                  </div>
                : null
              }
            </div>
          </div>
          <div className="navigation">
            <div className="questions">
              { map(answers, (answer: Answer, idx: number) => this.renderAnswerLink(answer, idx)) }
            </div>
            {documents.length > 0 && (
              <div className="documents-container">
                <ListDocuments documents={documents} />
              </div>
            )}
          </div>
        </Fragment>
      )
  }

  render() {
    const { user } = this.props
    const { survey } = this.state

    const surveyUser: User = get(survey, 'user')
    const email: string = get(surveyUser, 'email', '')

    const company: Company = get(survey, 'company')
    const primaryColor: string = get(company, 'primaryColor')
    const surveyLabel: string = get(survey, 'companySurvey.label', '')

    const buttons = []

    const isSuperAdmin = get(this.props.user, 'admin', false)
    const isAdmin = get(this.props.user, 'manager.role') === 'admin'
    let displayEmails = this.state.displayEmails

    // const cookieAuthent = Cookie.get(authTokenCookieKey)

    if (isSuperAdmin || isAdmin || get(company, 'activatedDisplayEmails', false) || displayEmails ) {
      displayEmails = true
    }
    else {displayEmails = false}

    if ( survey.status !== 'validated' ) {
      buttons.push(
        <Can I="manage" this="userSurvey" key="button-resend">
          <Button type="button" primaryColor={primaryColor}
            onClick={() => this.resendInvitation()}
          >
            {I18n.t('pages.userSurvey.resendInvitation')}
          </Button>
        </Can>
      )
    }

    if (survey.status !== 'pending') {
      buttons.push(
        <Can I="share" this="userSurvey" key="button-share">
          <Button type="button" primaryColor={primaryColor}
            onClick={() => this.setState({ openModal: true })}
          >
            {I18n.t('pages.userSurvey.share')}
          </Button>
        </Can>
      )
    }

    if ( survey.status === 'completed' ) {
      buttons.push(
        <Can I="manage" this="userSurvey" key="button-validate">
          <Button type="button" primaryColor={primaryColor}
            onClick={() => this.validate()}
          >
            {I18n.t('pages.userSurvey.validate')}
          </Button>
        </Can>
      )
    }

    if ( survey.status === 'validated' ) {
      buttons.push(
        <Can I="manage" this="userSurvey" key="button-validate">
          <Button type="button" primaryColor={primaryColor}
            onClick={() => this.unvalidate()}
          >
            {I18n.t('pages.userSurvey.unvalidate')}
          </Button>
        </Can>
      )
    }

    if ( survey.status === 'in_progress' ) {
      buttons.push(
        <Can I="manage" this="workflow" key="button-validate">
          <Button type="button" primaryColor={primaryColor}
            onClick={() => {
              if ( window.confirm(I18n.t('alerts.userSurvey.workflowUpdate.message')))
                this.complete()
            }
          }
          >
            {I18n.t('pages.userSurvey.complete')}
          </Button>
        </Can>
      )
    }

    if ( survey.status === 'completed' ) {
      buttons.push(
        <Can I="manage" this="workflow" key="button-validate">
          <Button type="button" primaryColor={primaryColor}
            onClick={() => {
              if ( window.confirm(I18n.t('alerts.userSurvey.workflowUpdate.message')))
                this.uncomplete()
            }
          }
          >
            {I18n.t('pages.userSurvey.uncomplete')}
          </Button>
        </Can>
      )
    }

    buttons.push(
      <Can I="download" this="localVersion" key="button-download">
        <Button type="button" primaryColor={primaryColor}
            onClick={() => {
              downloadUserSurvey(survey).then((data: UserSurvey) => {
                notification('success', 'userSurvey.download')
              }).catch((error: any) => {
                notification('error', 'userSurvey.download')
              })
            }}
          >
            {I18n.t('pages.userSurvey.download')}
          </Button>
      </Can>

    )

    buttons.push(
      <Can I="manage" this="userSurvey" key="button-delete">
        <Button type="button" primaryColor={'#e74930'}
          onClick={() => {
            if (window.confirm(I18n.t('pages.userSurvey.alertDelete'))) this.delete()
          }}
        >
          {I18n.t('pages.userSurvey.actionDelete')}
        </Button>
      </Can>
    )

    return (
      <SignedInLayout hideSidebar={isEmpty(user)} logoUrl={get(company, 'logoUrl', logo)}>
        <Container survey={survey}>
          <div className="user-survey-header">
            <div>
              <Translate value="pages.userSurvey.survey" />
              { surveyLabel }
              <br />
              <Translate value="pages.userSurvey.owner" />
              { get(surveyUser, 'firstName', '') }
              &nbsp;
              { get(surveyUser, 'lastName', '') }
              <br />
              {this.renderEmails(displayEmails, email, surveyLabel)}

              <Can I="set" this="userSurveyOwner">
                <ChangeSurveyOwnerModal
                  currentOwner={surveyUser}
                  primaryColor={primaryColor}
                  companyId={get(company, 'id')}
                  handleOwnerChange={ (newOwner: User) => { this.handleOwnerChange(newOwner) }}
                ></ChangeSurveyOwnerModal>
              </Can>
            </div>



            <Can I="manage" this="userSurvey">
              { this.renderDates() }
            </Can>
            <div className="user-survey-headerButtons">
              { buttons }
            </div>
          </div>

          <TagsList
            selectedTags={this.state.selectedTags}
            tags={this.state.tags}
            company={company}
          />
          <div className="container">
            { survey.status !== 'pending' ? this.renderAnswer() : null }
          </div>
          {this.renderModal()}
        </Container>
      </SignedInLayout>
    )
  }
}

const mapStateToProps = ({ user: { currentUser } }: Store) => ({
  user: currentUser,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteSurvey: (id: any, meta: any) => dispatch(deleteUserSurvey(id, meta)),
  resendSurveyInvitation: (survey: any) => dispatch(resendSurveyInvitation(survey)),
  validateSurvey: (survey: any) => dispatch(validateUserSurvey(survey)),
  unvalidateSurvey: (survey: any) => dispatch(unvalidateUserSurvey(survey)),
  completeSurvey: (survey: any) => dispatch(completeUserSurvey(survey)),
  uncompleteSurvey: (survey: any) => dispatch(uncompleteUserSurvey(survey)),
})

const mergeProps = (stateProps: any, dispatchProps: any, ownProps: any) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(SurveysScene) as any)
