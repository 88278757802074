import React from 'react'
import { withRouter } from 'react-router'

/* Redux */
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { I18n } from 'react-redux-i18n'

/* lib */
import { filter, get, map, intersection, isEmpty } from 'lodash'
import { TreeData, TreeNode } from 'react-dropdown-tree-select'
import Select from 'react-select'

/* app */
import { getCompanyTags, updateUserSurvey } from '../../api'
import { Store, UserSurvey, Company, SelectOption, User, HierarchyTree } from '../../types'
import { getUserSurveysForCompany } from '../../actions'
import { SignedInLayout, SurveyCard, TreeSelect, CompanySelect } from '../../components'

import { Can } from '../../config/ability-context'

import './styles.sass'
interface IProps {
  getSurveys: (companyId: string, withTags: boolean) => void
  user: User
  surveys: {
    all: UserSurvey[],
    completed: UserSurvey[],
    pending: UserSurvey[],
    inProgress: UserSurvey[],
    validated: UserSurvey[]
  }
  companies: Company[]
}

interface IState {
  tags: TreeData
  hierarchies: HierarchyTree[]
  tagIds: number[]
  selectedCompany: SelectOption
  currentCategory: SelectOption
}

class SurveysScene extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    let company: Company = {}

    if (props.user.company) {
      company = props.user.company
    } else if (props.surveys.all.length > 0) {
      company = get(props.surveys.all, '[0].companySurvey.company')
    }

    this.state = {
      tags: [],
      tagIds: [],
      hierarchies: [],
      currentCategory: { label: I18n.t('pages.surveys.categories.all'), value: 'all'},
      selectedCompany: {
        value: get(company, 'id', ''),
        label: get(company, 'name', ''),
        details: company
      }
    }
  }

  getToken(tokenName: string) {
    const urlString = window.location.href
    const url = new URL(urlString)

    return url.searchParams.get(tokenName)
  }

  componentDidMount() {
    const { selectedCompany } = this.state

    if (selectedCompany.value !== '') {
      this.selectCompany(selectedCompany)
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const { user } = this.props

    if (user !== prevProps.user && user.company) {
      this.selectCompany({
        value: get(user, 'company.id'),
        label: get(user, 'company.name'),
        details: user.company
      })
    }
  }

  private collectNodes(fromNode: TreeNode, nodes: TreeNode[]) {
    if (!fromNode) {
      return nodes
    }

    if (fromNode.children && fromNode.children.length > 0) {
      fromNode.children.forEach((child: TreeNode) => {
        this.collectNodes(child, nodes)
      })
    } else {
      nodes.push(fromNode)
    }
  }

  private tagSelected(currentNode: TreeNode, selectedNodes: TreeNode[]) {
    const { tags, hierarchies } = this.state
    //const root: TreeNode = get(this.state.tags, currentNode.rootPath)
    const allChildren: TreeNode[] = []

    /* getting all selected children */
    selectedNodes.forEach((node: TreeNode) => {
      if (node.isHierarchy) {
        this.collectNodes(get(hierarchies, node.path), allChildren)
      } else {
        this.collectNodes(get(tags, node.path), allChildren)
      }
    })

    const tagIds: number[] = map(allChildren, (node: TreeNode) => {
      return node.value
    })

    this.setState({ tagIds })
  }

  changeSurveyOption(surveyId: string, state: boolean, optionChanged: string) {
    updateUserSurvey(surveyId, { [optionChanged]: state })
  }

  selectCompany(selectedCompany: SelectOption) {
    const companyId: string = get(selectedCompany, 'value')
    this.props.getSurveys(companyId, true)

    if (get(selectedCompany, 'details.activatedTags', false)) {
      getCompanyTags(companyId).then((data: any) => {
        this.setState({
          selectedCompany,
          hierarchies: get(data, 'hierarchies', []),
          tags: get(data, 'tags', []),
          tagIds: []
        })
      })
    } else {
      this.setState({
        selectedCompany,
        hierarchies: [],
        tags: [],
        tagIds: []
      })
    }

  }

  private renderTreeSelect() {
    return (
      <div className="tags-select-wrapper">
        <label className="select-tags-label">{I18n.t('pages.surveys.selectTags')}</label>
        <TreeSelect
          data={this.state.hierarchies}
          onChange={(currentNode: TreeNode, selectedNodes: TreeNode[]) => this.tagSelected(currentNode, selectedNodes)}
        />
      </div>
    )
  }

  renderHeader() {
    const { currentCategory, tags } = this.state

    const categoryOptions: SelectOption[] = map(I18n.t('pages.surveys.categories'), (label: string, value: string) => {
      return { value, label }
    })

    return (
      <div className="surveys-header">
        <div className="category-filter">
          <label>{I18n.t('pages.surveys.selectCategory')}</label>
          <Select
            onChange={(e: any) => this.setState({currentCategory: e})}
            options={categoryOptions}
            value={currentCategory}
            menuPlacement="auto"
          />
        </div>
        <Can I='access' this="tags">
          { isEmpty(tags) ? null : this.renderTreeSelect() }
        </Can>
      </div>
    )
  }

  renderSurveys(state: string, surveys: UserSurvey[]) {
    const { currentCategory, tagIds } = this.state
    let filteredSurveys: UserSurvey[] = surveys
    if (!surveys || surveys.length === 0) {
      return null
    }

    const isSuperAdmin = get(this.props.user, 'admin', false)
    const isAdmin = get(this.props.user, 'manager.role') === 'admin'
    let displayEmails = false

    if (isSuperAdmin || isAdmin ) {
          displayEmails = true
    }
    else { displayEmails = false}


    if (currentCategory.value !== 'all') {
      filteredSurveys = filter(surveys, (survey: UserSurvey) => {
        return get(survey, 'companySurvey.target') === currentCategory.value
      })
    }

    if (tagIds.length > 0) {
      filteredSurveys = filter(filteredSurveys, (survey: UserSurvey) => {
        return intersection(get(survey, 'tagIds'), tagIds).length > 0
      })
    }

    return (
      <div className="surveys-list" key={state}>
        <h2 className="surveys-title">
          {I18n.t(`pages.surveys.${state}Surveys`)}
        </h2>
        <div className="survey-cards-container">
          {
            filteredSurveys.map((survey: UserSurvey) => (
              <SurveyCard
                survey={survey}
                key={get(survey, 'id', '')}
                user={this.props.user}
                onChangeOption={(state: boolean, optionChanged: string) => this.changeSurveyOption(get(survey, 'id'), state, optionChanged )}
                displayEmails={displayEmails}
              />
            ))
          }
        </div>
      </div>
    )
  }

  render() {
    const { surveys, companies } = this.props
    const { selectedCompany } = this.state

    return (
      <SignedInLayout>
        <CompanySelect
          companies={companies}
          selectedCompany={selectedCompany}
          handleSelect={(company: SelectOption) => this.selectCompany(company)}
          hint={I18n.t('pages.surveys.companySelectHint')}
        />
        { this.renderHeader() }
        {
          ['completed', 'inProgress', 'pending', 'validated'].map((state: string) => {
            return this.renderSurveys(state, get(surveys, state))
          })
        }
      </SignedInLayout>
    )
  }
}

const mapStateToProps = ({
  userSurveys: { allSurveys },
  companies: { allCompanies },
  user: { currentUser },
}: Store) => ({
  companies: allCompanies,
  user: currentUser,
  surveys: {
    all: allSurveys,
    completed: filter(allSurveys, { status: 'completed' }),
    pending: filter(allSurveys, { status: 'pending' }),
    inProgress: filter(allSurveys, { status: 'in_progress' }),
    validated: filter(allSurveys, { status: 'validated' })
  }
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getSurveys: (companyId: string, withTags: boolean) => dispatch(getUserSurveysForCompany(companyId, withTags)),
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SurveysScene) as any)
