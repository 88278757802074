import React, { Fragment } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'

import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { I18n } from 'react-redux-i18n'
import { get, map } from 'lodash'
import { Button, Loader } from 'tyaw-components'
import { Can } from '../../config/ability-context'
import { Company, Store, User } from '../../types'

import { edit, trash } from '../../assets/icons'
import { SignedInLayout } from '../../components'

import {
  getCompanies as getCompaniesAction,
  deleteCompany as deleteCompanyAction,
} from '../../actions'

import './styles.sass'

interface IProps extends RouteComponentProps<any>  {
  companies: Company[]
  loading: Boolean
  getCompanies: () => void
  deleteCompany: (companyId: string) => void
  user: User
}

interface IState {
}

class CompaniesScene extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    const { getCompanies } = this.props

    getCompanies()
  }

  componentDidUpdate(prevProps: IProps) {
    const { companies, history } = this.props

    if (companies && companies.length === 1) {
      history.push(`/companies/${get(companies, "[0].id")}`)
    }
  }

  submitDeleteCompany(company: Company) {
    const { deleteCompany } = this.props
    deleteCompany(get(company, 'id'))
  }

  renderReadOnlyColorInput(company: Company) {
    return (
      <Fragment>
        <input
          className="company-list-color-input"
          type="color"
          value={company.primaryColor || ""}
          disabled={true}
        />
        <input
          className="company-list-color-input"
          type="color"
          value={company.welcomeColor || ""}
          disabled={true}
        />
        <input
          className="company-list-color-input"
          type="color"
          value={company.thankYouColor || ""}
          disabled={true}
        />
      </Fragment>
    )
  }

  renderList(list: Company[]) {
    return map(list, (company: Company, index: number) => {
      return (
        <tr key={index}>
          <td>{company.name}</td>
          <td>{this.renderReadOnlyColorInput(company)}</td>
          <td>
            {company.logoUrl && (
              <img className="company-list-logo" src={company.logoUrl} />
            )}
          </td>
          <td className="update-column">
            <NavLink to={`/companies/${company.id}`}>
              <img src={edit} className="button-icon" />
            </NavLink>
          </td>
          <Can I="delete" this="companies">
            <td className="last-column">
              <button
                className="button-icon"
                onClick={() => {
                  if (window.confirm(I18n.t('models.company.delete.alert'))) {
                    this.submitDeleteCompany(company)
                  }
                }}
              >
                <img src={trash} />
              </button>
            </td>
          </Can>
        </tr>
      )
    })
  }

  render() {
    const { companies, user, loading } = this.props

    if (loading || !user.id) {
      return (
        <Loader />
      )
    }

    return (
      <SignedInLayout>
        <div className="page-container">
          <div className="page-header">
            <span></span>
            <NavLink to={'/companies/new'}>
              <Button type="button">
                {I18n.t("pages.companies.create")}
              </Button>
            </NavLink>
          </div>

          <Can I="see" this="companies">
            <div className="settings-container-section">
              <div className="settings-container-admin">
                <p className="page-title">{I18n.t('pages.companies.title')}</p>
                <div className="list-container">
                  <table className="list-table">
                    <tbody>
                      <tr>
                        <th>{I18n.t('models.company.name')}</th>
                        <th>{I18n.t('models.company.colors')}</th>
                        <th>{I18n.t('models.company.logo')}</th>
                        <th className="update-column">{I18n.t('models.company.actions.update')}</th>

                        <Can I="delete" this="companies">
                          <th className="last-column">{I18n.t('models.company.actions.delete')}</th>
                        </Can>
                      </tr>
                      {this.renderList(companies)}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Can>
        </div>
      </SignedInLayout>
    )
  }
}

const mapStateToProps = ({
  companies: { allCompanies, loading },
  user: { currentUser },
}: Store) => ({
  loading,
  companies: allCompanies,
  user: currentUser,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCompanies: () => dispatch(getCompaniesAction()),
  deleteCompany: (companyId: string) => dispatch(deleteCompanyAction(companyId)),
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompaniesScene) as any)
