import React from 'react'

import { v4 as uuidv4 } from 'uuid'

import { get, find } from 'lodash'

import { Tile as TileComponent } from '../'

import { Company, Mosaic, UserSurvey, Tile } from '../../types'

import { logo } from '../../assets/images'

import './styles.sass'

interface IProps {
  mosaic: Mosaic
  company: Company
  mode: string // accepts two modes: show and edit
  surveys?: UserSurvey[]
  mosaics?: Mosaic[]
  tileChanged?: (oldTile: Tile, tile: Tile) => void
  onRemoveImage?: (tile: Tile) => void
  onRemoveTile?: (tile: Tile) => void
}

const mosaicView = ({ mosaic, company, mode, surveys, mosaics, tileChanged, onRemoveImage, onRemoveTile }:IProps) => {
  const testimonyCount = get(mosaic, 'testimonyCount')
  const perLines = get(mosaic, 'perLines', 1)
  const tileWidth = get(mosaic, 'tileWidth', 250)
  const framesWrapperWidth = perLines * tileWidth + perLines * 32

  const customStyle: any = {
    "mosaicView": {
      "backgroundColor": get(mosaic, 'backgroundColor', '#ffffff'),
      "color": get(mosaic, 'textColor', '#000000')
    },
    "framesWrapper": {
      width: `${framesWrapperWidth}px`
    }
  }

  const renderFramesGrid = () => {
    return (
      <div className="frames-wrapper" style={customStyle.framesWrapper}>
        {renderFrames(testimonyCount)}
      </div>
    )
  }

  const renderFrames = (framesNb: number) => {
    let frames = []

    for (let i = 1; i <= framesNb; i++) {
      let tile: Tile = find( mosaic.tiles, { position: i })

      if ( tile === undefined && mode === 'edit' ) {
        tile = {
          position: i,
          key: uuidv4(),
          mosaicId: mosaic.id
        }
      }

      if (tile) {
        frames.push(
          <TileComponent
            key={`frame-${get(tile, 'key')}`}
            width={get(mosaic, 'tileWidth', 250) as number}
            height={get(mosaic, 'tileHeight', 150) as number}
            tile={tile}
            maxPosition={get(mosaic, 'testimonyCount') as number}
            surveys={surveys}
            mosaics={mosaics}
            handleTileChange={tileChanged}
            onRemoveImage={onRemoveImage}
            onRemoveTile={onRemoveTile}
            mode={mode}
          />
        )
      }
    }

    return frames
  }

  return (
    <div className={`mosaic-view ${get(mosaic, 'showCaption', false) ? 'show-caption' : 'hide-caption' }`} style={customStyle.mosaicView}>
      <div className="mosaic-header">
        <img
          className="mosaic-logo"
          src={get(company, 'logoUrl', logo)}
        />
        <h1>
          {mosaic.title}
        </h1>
        <h2>{get(mosaic, 'subtitle')}</h2>
      </div>
      { renderFramesGrid() }
    </div>
  )
}

export default mosaicView
