import React from 'react'
import Cookies from 'js-cookie'
import { get } from 'lodash'

import './styles.sass'

import { authTokenCookieKey } from '../../constants'
import { off } from '../../assets/icons'
import { Can } from '../../config/ability-context'

interface IProps {
  currentUser?: object
}

const signOut = () => {
  Cookies.remove(authTokenCookieKey)
  window.location = '/login' as any
}

export default ({ currentUser }: IProps) => {
  const changelogURL = `${process.env.REACT_APP_API_URL}/changelogs`
  return (
    <div className="navbar">
      <Can I="see" this="version">
        <a href={ changelogURL } target="_blank">Voir les mises à jour</a>
      </Can>
      <h3>{get(currentUser, 'email', '')}</h3>
      <button className="sign-out-button" onClick={signOut}>
        <img src={off} />
      </button>
    </div>
  )
}
