import { ICellRendererParams } from 'ag-grid-community/main.d'

/*
 * Boolean rendered
 */
export const booleanCellRenderer = (params: ICellRendererParams) => {
  const valueCleaned: boolean | null = booleanCleaner(params.value)
  const eGui: HTMLElement = document.createElement('span')

  if (valueCleaned === true) {
    eGui.setAttribute('title', 'true')
    eGui.setAttribute('class', 'ag-icon ag-icon-tick content-icon')
  } else {
    if (valueCleaned === false) {
      eGui.setAttribute('title', 'false')
      eGui.setAttribute('class', 'ag-icon ag-icon-cross content-icon')
    } else {
      if (params.value && params.value !== null) {
        eGui.innerHTML = params.value.toString()
      }
    }
  }

  return eGui
}

const booleanCleaner = (value: any): boolean | null => {
  if (value === "true" || value === true || value === 1) {
    return true
  }

  if (value === "false" || value === false || value === 0) {
    return false
  }

  return null
}

/*
 * Color cell renderer
*/

export const colorRenderer = (params: ICellRendererParams) => {
  if (!params.value) {
    return ''
  }

  const eGui: HTMLSpanElement = document.createElement('span')
  eGui.setAttribute('class', 'color-cell')
  eGui.innerHTML = `<span class='color-square' style='background-color: ${params.value}'></span> ${params.value}`

  return eGui
}

/*
 * Simple tree renderer
*/

export const treeRenderer = (params: ICellRendererParams) => {
  if (!params.value) {
    return ''
  }

  const eGui: HTMLSpanElement = document.createElement('span')
  let icon: string = ''
  let padding: number = params.data.depth * 15

  if (params.data.childrenCount > 0) {
    if (params.data.expanded) {
      icon = '<i class="ag-icon ag-icon-tree-open content-icon"></i>'
    } else {
      icon = '<i class="ag-icon ag-icon-tree-closed content-icon"></i>'
    }
  } else {
    padding += 15
  }

  eGui.setAttribute('style', `padding-left: ${padding}px;`)
  eGui.innerHTML = `${icon} <span class="node-label">${params.value}</span>`
/*
  eGui.addEventListener('click', (e: any) => {
    expandNode(params)
  })*/

  return eGui
}

export const badgeCellRenderer = (params: ICellRendererParams) => {
  const eGui: HTMLSpanElement = document.createElement('span')

  if (params.value) {
    eGui.innerHTML = params.value
    eGui.className = `badge small ${params.data.status}`
  }

  return eGui
}

/*
const expandNode = (params: ICellRendererParams) => {
  const node: any = params.data
  node.expanded = !node.expanded

  if (node.expanded) {
    params.api.applyTransaction({
      add: node.children,
      addIndex: (params.rowIndex + 1)
    })
  } else {
    params.api.applyTransaction({
      remove: node.children
    })
  }
}*/
