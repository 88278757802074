import { handle } from 'redux-pack'
import { filter, map } from 'lodash'
import {
  GET_MANAGERS,
  CREATE_MANAGER_INVITATION_TOKEN,
  GET_MANAGER_INVITATION_TOKENS,
  UPDATE_MANAGER_COMPANY,
  DELETE_MANAGERS,
} from '../constants'
import { User } from '../types/'
import { ManagerInvitationToken } from '../types'

interface IState {
  allManagers: any[]
  allManagerInvitationTokens: ManagerInvitationToken[]
  loading: boolean
}

const initialState: IState = {
  allManagers: [],
  allManagerInvitationTokens: [],
  loading: false,
}

export default (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case CREATE_MANAGER_INVITATION_TOKEN:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allManagerInvitationTokens: [...prevState.allManagerInvitationTokens, payload],
        }),
      })
    case GET_MANAGERS:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({ ...prevState, allManagers: payload }),
      })
    case GET_MANAGER_INVITATION_TOKENS:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({ ...prevState, allManagerInvitationTokens: payload }),
      })
    case DELETE_MANAGERS:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allManagers: filter(prevState.allManagers, (a: User) => a.id !== payload.id),
        }),
      })
    case UPDATE_MANAGER_COMPANY:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allManagers: map(prevState.allManagers, (stateManager: any) =>
            stateManager.id === payload.id ? payload : stateManager,
          ),
        }),
      })
    default:
      return state
  }
}
