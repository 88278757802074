import React from 'react'

/* redux */
import { I18n } from 'react-redux-i18n'

/* Ag Grid */
import { AgGridReact, AgGridColumn } from 'ag-grid-react'

/* libs */
import { gridSkeleton, badgeCellRenderer } from '../../components/ag-grid'

/* app */
import { User, GridSharedProps } from '../../types'

interface IProps extends GridSharedProps {
  data: {
    locale: string
    rows: User[]
    showCompany: boolean
  }
  handleDelete?: (data: any) => Promise<boolean>
}

const usersGrid = (props: IProps) => {
  const { data, options, handleDelete, refreshCells, firstDataRendered, gridReady } = props

  const renderActions = () => {
    return (
      <AgGridColumn
        colId="actions"
        cellRenderer="actionsRenderer"
        cellRendererParams={
          {
            showButtons: { edit: false, add: false, delete: handleDelete !== undefined },
            confirmText: I18n.t('models.user.delete.alert'),
            onDeleteAction: (data: any) => { handleDelete && handleDelete(data) }
          }
        }
        headerName=""
        suppressSizeToFit={true}
        editable={false}
        filter={false}
        sortable={false}
        resizable={false}
        width={120}
      ></AgGridColumn>
    )
  }

  return (
    <AgGridReact
      gridOptions={{ ...options,
        getRowNodeId: (data: any) => `${data.companyId}_${data.id}`
      }}
      rowData={data.rows}
      onFirstDataRendered={firstDataRendered}
      onFilterChanged={refreshCells}
      onGridReady={gridReady}
      onRowDataUpdated={refreshCells}
    >
      <AgGridColumn
        colId="first-column"
        field="companyName"
        headerName={I18n.t('models.user.company')}
        hide={!data.showCompany}
      ></AgGridColumn>

      <AgGridColumn
        colId={ data.showCompany ? "email" : "first-column"}
        field="email"
        headerName={I18n.t('models.user.email')}
        suppressSizeToFit={ true }
        width={400}
      ></AgGridColumn>

      <AgGridColumn
        field="translatedRole"
        headerName={I18n.t('models.user.role')}
      ></AgGridColumn>

      <AgGridColumn
        field="translatedStatus"
        headerName={I18n.t('models.user.status')}
        minWidth={100}
        width={100}
        cellRenderer={badgeCellRenderer}
      ></AgGridColumn>

      <AgGridColumn
        field="createdAt"
        headerName={I18n.t('models.user.createdAt')}
        minWidth={100}
        width={100}
      ></AgGridColumn>

      { renderActions() }

    </AgGridReact>
  )
}

export default gridSkeleton(usersGrid, {
  rowDragManaged: false,
  suppressMoveWhenRowDragging: true
})
