import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import Select from 'react-select'

/* redux */
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'

/* libs */
import { get } from 'lodash'
import { TextInput } from 'tyaw-components'

/* AG Grid */
import { ColumnApi, GridApi, RowValueChangedEvent, GridReadyEvent } from 'ag-grid-community/main.d'

/* app */
import { AddRowComponent, BatchActionsComponent } from '../../components/ag-grid'
import { notification, questionIsInvalid, companyOptions } from '../../helpers'

import './styles.scss'

import {
  getTemplateQuestions as getTemplateQuestionsAction,
  getQuestionTypes as getQuestionTypesAction,
  unactiveTemplateQuestion,
  updateTemplateQuestion,
  createTemplateQuestion,
  batchUpdateTemplateQuestions
} from '../../actions'
import { Store, TemplateQuestion, QuestionType, Company, SelectOption, SurveyTarget } from '../../types'
import { QuestionsGrid, SignedInLayout } from '../../components'


interface IProps extends RouteComponentProps<any> {
  getTemplateQuestions: (target?: SurveyTarget, companyId?: string) => void
  getQuestionTypes: () => void
  deleteQuestion: (id: string) => void
  createQuestion: (data: TemplateQuestion, meta: any) => void
  updateQuestion: (id: string, data: TemplateQuestion, meta: any) => void
  batchUpdateQuestions: (ids: string[], data: TemplateQuestion, meta: any) => void
  questionTypes: QuestionType[]
  templateQuestions: TemplateQuestion[]
  locale: string
  companies: Company[]
}

interface IState {
  gridApi: GridApi | undefined
  gridColumnApi: ColumnApi | undefined
  selectedCompany: SelectOption
}

class QuestionsScene extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props)

    this.state = {
      gridApi: undefined,
      gridColumnApi: undefined,
      selectedCompany: { value: '', label: '' }
    }
  }

  componentDidMount() {
    const { getQuestionTypes, getTemplateQuestions } = this.props

    getQuestionTypes()
    getTemplateQuestions()
  }

  /* todo: show status: https://stackblitz.com/edit/async-validation-ag-grid-final?file=main.js */
  handleChanges(event: RowValueChangedEvent) {
    const { createQuestion, updateQuestion } = this.props
    const data: any = get(event, 'data')

    if (questionIsInvalid(data)) {
      notification('error', 'templateQuestion.invalid')
      return
    }

    if (get(data, 'isNew', false)) {
      createQuestion(data, {
        onSuccess: () => {
          notification('success', 'templateQuestion.create')
        },
        onFailure: () => {
          notification('error', 'templateQuestion.create')
        }
      })
    } else {
      updateQuestion(data.id, data, {
        onSuccess: () => {
          notification('success', 'templateQuestion.update')
        },
        onFailure: () => {
          notification('error', 'templateQuestion.update')
        }
      })
    }
    // data.updating = true
  }

  handleDelete(question: TemplateQuestion): Promise<boolean> {
    return new Promise((resolve: any, reject: any) => {
      const { deleteQuestion } = this.props

      if (!question.isNew) {
        deleteQuestion(get(question, 'id'))
        resolve(true)
      }
    })
  }

  handleBatchUpdate(templateQuestions: TemplateQuestion[], attribute: string, newValue: any) {
    const { batchUpdateQuestions } = this.props

    batchUpdateQuestions(templateQuestions.map((data: any) => data.id), {[attribute]: newValue}, {
      onSuccess: () => {
        notification('success', 'templateQuestion.batchUpdate')
      },
      onFailure: () => {
        notification('error', 'templateQuestion.batchUpdate')
      }
    })
  }

  handleBatchDelete(templateQuestions: TemplateQuestion[], api: GridApi) {
    const { batchUpdateQuestions } = this.props

    batchUpdateQuestions(templateQuestions.map((data: any) => data.id), {active: false}, {
      onSuccess: () => {
        if (api) {
          api.applyTransaction({ remove: templateQuestions })
        }
        notification('success', 'templateQuestion.batchDelete')
      },
      onFailure: () => {
        notification('error', 'templateQuestion.batchDelete')
      }
    })
  }

  onGridReady(event: GridReadyEvent) {
    this.setState({
      gridApi: event.api,
      gridColumnApi: event.columnApi
    })
  }

  filterCompany(selectedCompany: SelectOption) {
    if (selectedCompany.value) {
      this.props.getTemplateQuestions(undefined, selectedCompany.value)
    } else {
      this.props.getTemplateQuestions()
    }
    this.setState({ selectedCompany })
  }

  renderSelectCompany(){
    const { companies } = this.props
    const { selectedCompany } = this.state

    if (!companies || companies.length <= 1) {
      return null
    }

    return (
      <Select
        value={selectedCompany.value ? selectedCompany : 'Organisation'}
        onChange={(selectedOption: any) => this.filterCompany(selectedOption)}
        options={companyOptions(companies)}
        placeholder="Organisation"
      />
    )
  }

  renderGrid() {
    const { questionTypes, templateQuestions, locale } = this.props

    return (
      <QuestionsGrid
        key="template-questions-management"
        handleChanges={ (event: RowValueChangedEvent) => this.handleChanges(event) }
        handleDelete={ (question: TemplateQuestion) => this.handleDelete(question)}
        onGridReady={ (event: GridReadyEvent) => this.onGridReady(event) }
        data={{
          locale,
          questionTypes,
          rows: templateQuestions
        }}
        mode="manage"
      />
    )
  }

  renderGridMenu() {
    const { gridApi, gridColumnApi } = this.state

    if (!gridApi) {
      return null
    }

    return (
      <div className="ag-grid-tools">
        <AddRowComponent
          gridApi={gridApi}
          columnApi={gridColumnApi}
          buttonLabel={I18n.t('pages.questions.title_create')}
          initData={{labelI18n: {}, tooltipI18n: {}}}
        />
        <BatchActionsComponent
          gridApi={gridApi}
          fields={['target', 'questionTypeId']}
          onChange={(templateQuestions: TemplateQuestion[], attribute: string, newValue: any) => this.handleBatchUpdate(templateQuestions, attribute, newValue)}
          onDelete={(templateQuestions: TemplateQuestion[], api: GridApi) => this.handleBatchDelete(templateQuestions, api)}
          mode="manage"
        />
        <div className="ag-grid-filters-container">
          { this.renderSelectCompany() }
          <TextInput
            placeholder={I18n.t('actions.search')}
            className="ag-grid-filter white"
            onChangeText={(e: any) => gridApi && gridApi.setQuickFilter(e)}
          />
        </div>
      </div>
    )
  }

  render() {
    return (
      <SignedInLayout>
        <div className="ag-grid-container">
          { this.renderGridMenu() }
          { this.renderGrid() }
        </div>
      </SignedInLayout>
    )
  }

}

const mapStateToProps = () => ({
  templateQuestions: { allTemplateQuestions },
  companies: { allCompanies },
  questionTypes: { allQuestionTypes },
  i18n: { locale }
}: Store) => ({
  locale,
  companies: allCompanies,
  questionTypes: allQuestionTypes,
  templateQuestions: allTemplateQuestions
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getTemplateQuestions: (target?: SurveyTarget, companyId?: string) => dispatch(getTemplateQuestionsAction(target, companyId)),
  getQuestionTypes: () => dispatch(getQuestionTypesAction()),
  deleteQuestion: (id: string) => dispatch(unactiveTemplateQuestion(id)),
  createQuestion: (data: TemplateQuestion, meta: any) => dispatch(createTemplateQuestion(data, meta)),
  batchUpdateQuestions: (ids: string[], data: TemplateQuestion, meta: any) => dispatch(batchUpdateTemplateQuestions(ids, data, meta)),
  updateQuestion: (id: string, data: TemplateQuestion, meta: any) => dispatch(updateTemplateQuestion(id, data, meta)),
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionsScene) as any)
