import {
  GET_COMPANY,
  GET_COMPANIES,
  DELETE_COMPANY,
} from '../constants'
import {
  getCompany as apiGetCompany,
  getCompanies as apiGetCompanies,
  deleteCompany as apiDeleteCompany,
} from '../api'

export const getCompany = (id: string) => ({
  type: GET_COMPANY,
  promise: apiGetCompany(id),
})

export const deleteCompany = (id: string) => ({
  type: DELETE_COMPANY,
  promise: apiDeleteCompany(id),
})

export const getCompanies = () => ({
  type: GET_COMPANIES,
  promise: apiGetCompanies(),
})
