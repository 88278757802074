import React from 'react'
import styled, { css } from 'styled-components'
import { camelize } from 'humps'
import { get } from 'lodash'
import classnames from 'classnames'

import './styles.sass'

import { filter, check } from '../../assets/icons'

interface IProps {
  checked: boolean
  children: string
  onClick: () => void
  target: string
}

interface StyledProps {
  target: string
}

const Container =  styled.button.attrs<StyledProps>(() => ({
  className: 'filter-button-component',
}))<StyledProps>`
  ${props =>
    css`
    background: ${props.theme[`${camelize(get(props, 'target'))}Color`]};
  `};`

export default ({ children, target, checked, onClick }: IProps) => (
  <Container onClick={onClick} target={target}>
    <img src={filter} className="filter-icon" />
    {children}
    <img src={check} className={classnames('check-icon', {checked})} />
  </Container>
)
