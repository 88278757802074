import React from 'react'

/* redux */
import { I18n } from 'react-redux-i18n'

/* Ag Grid */
import { AgGridReact, AgGridColumn } from 'ag-grid-react'

/* libs */
import { treeRenderer, colorRenderer, gridSkeleton } from '../../components/ag-grid'
import { upperCase, pull, get } from 'lodash'

/* app */

import { LOCALES } from '../../i18n'
import { Tag, GridSharedProps } from '../../types'

interface IProps extends GridSharedProps {
  data: {
    locale: string,
    rows: Tag[]
  }
  handleDelete?: (tag: Tag) => Promise<boolean>
}

const tagsGrid = (props: IProps) => {
  const { data, options, handleDelete, refreshCells, firstDataRendered, gridReady, rowDragEnd } = props
  const translatedColumns: JSX.Element[] = []

  pull(LOCALES, data.locale).forEach((lang: string) => {
    translatedColumns.push(
      <AgGridColumn
        field={`labelI18n.${lang}`}
        key={`labelI18n-${lang}`}
        headerName={`${upperCase(lang)} - ${I18n.t('models.tag.label')}`}
        editable={true}
      />,
      <AgGridColumn
        field={`descI18n.${lang}`}
        key={`descI18n-${lang}`}
        headerName={`${upperCase(lang)} - ${I18n.t('models.tag.desc')}`}
        editable={true}
      />
    )
  })

  const renderActions = () => {
    return (
      <AgGridColumn
        colId="actions"
        cellRenderer="actionsRenderer"
        cellRendererParams={
          {
            showButtons: { edit: true, add: true, delete: handleDelete !== undefined },
            confirmText: I18n.t('models.tag.delete.alert'),
            onDeleteAction: (tag: Tag) => { handleDelete && handleDelete(tag) }
          }
        }
        headerName=""
        suppressSizeToFit={true}
        editable={false}
        filter={false}
        sortable={false}
        resizable={false}
        width={120}
      ></AgGridColumn>
    )
  }

  return (
    <AgGridReact
      gridOptions={options}
      rowData={data.rows}
      onFirstDataRendered={firstDataRendered}
      onFilterChanged={refreshCells}
      onGridReady={gridReady}
      onRowDataUpdated={refreshCells}
      onRowDragEnd={rowDragEnd}
    >
      <AgGridColumn
        colId="first-column"
        field={`labelI18n.${data.locale}`}
        headerName={I18n.t('models.tag.label')}
        suppressSizeToFit={ true }
        width={400}
        editable={true}
        cellRenderer={treeRenderer}
        rowDrag={ true }
      ></AgGridColumn>
      <AgGridColumn
        field={`descI18n.${data.locale}`}
        headerName={I18n.t('models.tag.desc')}
        editable={true}
      ></AgGridColumn>
      { translatedColumns }

      <AgGridColumn
        field="color"
        editable={(params: any) => {
          return get(params, 'data.depth', 0) === 0
        }}
        headerName={I18n.t('models.tag.color')}
        cellEditor="colorCellEditor"
        cellRenderer={colorRenderer}
        width={150}
      ></AgGridColumn>

      <AgGridColumn
        field="depth"
        editable={false}
        headerName={I18n.t('models.tag.depth')}
        minWidth={80}
      ></AgGridColumn>

      { renderActions() }

    </AgGridReact>
  )
}

export default gridSkeleton(tagsGrid, {
  rowDragManaged: false,
  suppressMoveWhenRowDragging: true
})
