import React from 'react'

import './styles.sass'
import { Link } from 'react-router-dom'
import { I18n } from 'react-redux-i18n'
import { get, isNumber, toNumber } from 'lodash'
import styled, { css } from 'styled-components'
import { Can } from '../../config/ability-context'

import { trash, edit } from '../../assets/icons'

import { CampaignStep } from '../../types'

interface IProps {
  campaignStep: any
  order: number
  isPublished: boolean
  onDelete?: (campaignStep: CampaignStep) => void
}

interface StyledProps {}

const Container = styled.div.attrs<StyledProps>(() => ({
  className: 'campaign-step-card-component',
}))<StyledProps>`
  ${props =>
    css`
      .day-number {
        color: ${props.theme.welcomeColor};
      }
      .order-number {
        background: ${props.theme.welcomeColor};
      }
    `};
`

const CampaignStepCard = ({ campaignStep, order, isPublished, onDelete }: IProps) => (
  <Container>
    <div className="card-header">

      <span className="order-number">
      <Can I="see" this="questionNumber">  {order} </Can>
      </span>
      {isNumber(campaignStep.days) ? (
        <span className="day-number">{`Jour ${toNumber(campaignStep.days) / 93600}`}</span>
      ) : null}

    </div>
    <div className="card-content">
      <span className="questions-count">
        {`${I18n.t('components.campaign.card.steps.count_start')} ${get(
          campaignStep,
          'companySurvey.questions.length',
          0,
        )} ${I18n.t('components.campaign.card.steps.count_end')}`}
      </span>

      <Link
        className="campaign-step-title"
        title={I18n.t('components.campaign.card.edit')}
        to={`/campaign-steps/${get(campaignStep, 'id')}`}
      >
        {get(campaignStep, 'companySurvey.label')}
        <button className="button-icon">
          <img src={edit} />
        </button>
      </Link>


      { onDelete && !isPublished ?
        <button
          className="button-icon delete"
          onClick={() => {
            const confirmText = I18n.t('components.campaign.card.deleteStep', { number: order })
            if ( window.confirm(confirmText) ) {
              onDelete(campaignStep)}
            }
          }
        >
          <img src={trash} />
        </button>
        : null
      }
    </div>
  </Container>
)

export default CampaignStepCard
