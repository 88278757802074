import { Quill } from 'react-quill';

const Parchment = Quill.import('parchment')

class IndentAttributor extends Parchment.Attributor.Style {
  constructor(name: string, type: string, options: any) {
    super(name, type, options)
  }

  add(node: any, value: any) {
    if (value === 0) {
      this.remove(node)
      return true
    } else {
      return super.add(node, `${value}em`)
    }
  }
}

let IndentStyle = new IndentAttributor('indent', 'text-indent', {
  scope: Parchment.Scope.BLOCK,
  whitelist: ['1em', '2em', '3em', '4em', '5em', '6em', '7em', '8em', '9em']
})

export const initializeQuill = (): { modulesQuill: any, formatsQuill: string[] } => {
  // configure Quill to use inline styles so the email's format properly
  var DirectionAttribute = Quill.import('attributors/attribute/direction');
  Quill.register(DirectionAttribute, true);

  var AlignClass = Quill.import('attributors/class/align');
  Quill.register(AlignClass, true);

  var BackgroundClass = Quill.import('attributors/class/background');
  Quill.register(BackgroundClass, true);

  var ColorClass = Quill.import('attributors/class/color');
  Quill.register(ColorClass, true);

  var DirectionClass = Quill.import('attributors/class/direction');
  Quill.register(DirectionClass, true);

  var FontClass = Quill.import('attributors/class/font');
  Quill.register(FontClass, true);

  var SizeClass = Quill.import('attributors/class/size');
  SizeClass.whitelist = ['8px', '10px', '12px', '14px', '16px', '18px', '20px'];
  Quill.register(SizeClass, true);

  var AlignStyle = Quill.import('attributors/style/align');
  Quill.register(AlignStyle, true);

  var BackgroundStyle = Quill.import('attributors/style/background');
  Quill.register(BackgroundStyle, true);

  var ColorStyle = Quill.import('attributors/style/color');
  Quill.register(ColorStyle, true);

  var DirectionStyle = Quill.import('attributors/style/direction');
  Quill.register(DirectionStyle, true);

  var FontStyle = Quill.import('attributors/style/font');
  Quill.register(FontStyle, true);

  // var SizeStyle = Quill.import('attributors/style/size');
  // Quill.register(SizeStyle, true);

  Quill.register(IndentStyle, true);


  return {
    modulesQuill: {
      toolbar:[
        [{'font': []}],
        [{'size': ['8px', '10px', '12px', '14px', '16px', '18px', '20px', false]}],
        ['bold', 'italic', 'underline','strike', 'blockquote', 'code-block'],
        [{'color': []}, {'background': []}],
        [{ 'header': 1 }, { 'header': 2 }],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link'],
        ['clean'],
        [{'align': []}]
      ],
      clipboard: { matchVisual: false }
    },
    formatsQuill: [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link',
      'background', 'color', 'font', 'code', 'size', 'align'
    ]
  }

}
