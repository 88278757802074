import { handle } from 'redux-pack'
import { filter } from 'lodash'
import {
  GET_COMPANY,
  DELETE_COMPANY,
  GET_COMPANIES,
  UPDATE_SURVEY,
} from '../constants'
import { Company } from '../types/company'

interface IState {
  company: Company
  allCompanies: Company[]
  loading: boolean
}

const initialState: IState = {
  company: {},
  allCompanies: [],
  loading: false,
}

export default (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case UPDATE_SURVEY:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allCompanies: [...prevState.allCompanies, payload],
        }),
      })
    case GET_COMPANY:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          company: payload.company,
        }),
      })
    case DELETE_COMPANY:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allCompanies: filter(prevState.allCompanies, (c: Company) => c.id !== payload.id),
        }),
      })
    case GET_COMPANIES:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({ ...prevState, allCompanies: payload }),
      })
    default:
      return state
  }
}
