import { defineAbility } from '@casl/ability'
import { get, isEmpty } from 'lodash'
import { User } from '../types'

export default (user: User | null) => {
  const isSuperAdmin = get(user, 'admin', false)
  const isAdmin = get(user, 'manager.role') === 'admin'

  const ability = defineAbility((can: any, cannot: any) => {
    if (!isEmpty(user)) {

      if (isSuperAdmin) {
        can('add', 'companyField')
        can('see', 'admins')
        can('see', 'users')
        can('see', 'companies')
        can('delete', 'companies')
        can('show', 'message')
        can('set', 'superAdmin')
        can('access', 'questions')
        can('access', 'hierarchies')
        can('manage', 'tags')
        can('access', 'statistics')
        can('download', 'localVersion')
        can('set', 'userSurveyOwner')
        can('see', 'version')
      }

      if (isAdmin) {
        can('see', 'companies')
        can('see', 'users')
        if (get(user, 'company.activatedTags', false)) {
          can('access', 'hierarchies')
        }
      }

      if (isSuperAdmin || isAdmin) {
        can('access', 'userInvitationLink')
        can('access', 'companies')
        can('access', 'admins')
        can('access', 'campaigns')
        can('access', 'mosaics')
        can('set', 'role')
        can('manage', 'userSurvey')
        can('manage', 'workflow')
        can('delete', 'User', { email: { $ne: get(user, 'email') } })
        can('see','questionNumber')
        can('see','videoStats')
        can('download','videos')
        can('access', 'statistics')
      }

      can('access', 'user_surveys')
      can('share', 'userSurvey')

      if (get(user, 'company.activatedTags', false)) {
        can('access', 'tags')
      }

      if (isAdmin && get(user, 'email') === 'saurydelphine@gmail.com') {
        can('access', 'userInvitationLink')
      }
    }
  })

  return ability
}
