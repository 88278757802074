import { ColumnApi, GridApi } from 'ag-grid-community/main.d'
import { get, head } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { I18n } from 'react-redux-i18n'

import { SelectOption } from '../types'

export const isEmptyRow = (data: any) => {
  let dataCopy = { ...data }
  delete dataCopy.id
  return !Object.values(dataCopy).some(value => value)
}

export const booleanOptions = (): SelectOption[] => {
  return [
    { label: I18n.t('actions.yes'), value: 'true' },
    { label: I18n.t('actions.no'), value: 'false' }
  ]
}

export  const addRow = (gridApi: GridApi, columnApi: ColumnApi, initData: any = {}, addIndex: number = 0) => {
  const id = uuidv4()
  const emptyRow = { ...initData, id, isNew: true, active: true }

  if (gridApi && columnApi) {
    gridApi.applyTransaction({ addIndex, add: [emptyRow] })

    const node = gridApi.getRowNode(id)
    gridApi.ensureIndexVisible(get(node, 'rowIndex'))

    setTimeout(() => {
      if (columnApi !== null && columnApi.getAllColumns !== null && columnApi.getAllColumns() !== null && head(columnApi.getAllColumns())) {
        gridApi.startEditingCell({
          rowIndex: get(node, 'rowIndex'),
          colKey: head(columnApi.getAllColumns()).getColId()
        })
      }
    }, 600)
  }

}
