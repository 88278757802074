import { handle } from 'redux-pack'

import { UPDATE_USER, GET_USERS_COLLABORATOR } from '../constants'
import { User } from '../types'

interface IState {
  currentUser: User
  collaborators: User[]
  loading: boolean
}

const initialState: IState = {
  currentUser: {},
  collaborators: [],
  loading: false,
}

export default (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case UPDATE_USER:
      return {
        ...state,
        currentUser: payload,
      }
    case GET_USERS_COLLABORATOR:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({ ...prevState, collaborators: payload }),
      })
    default:
      return state
  }
}
