import { apiClient } from '../constants'
import { Campaign, CampaignStep } from '../types'

export const getCampaigns = (preview: boolean = false) => apiClient.get('/campaigns', { params: { preview } }).then(({ data }) => data)

export const getCampaignsForCompany = (companyId: string, preview: boolean = false) => apiClient.get('/campaigns', {
  params: {
    company_id: companyId,
    preview: preview
  }
}).then(({ data }) => data)

export const createCampaign = (campaign: Campaign) =>
  apiClient.post('/campaigns', { campaign }).then(({ data }) => data)

export const updateCampaign = (campaign: Campaign) =>
  apiClient.patch(`/campaigns/${campaign.id}`, { campaign }).then(({ data }) => data)

export const publishCampaign = (campaign: Campaign) =>
  apiClient.patch(`/campaigns/${campaign.id}/publish`).then(({ data }) => data)

export const duplicateCampaignForCompany = (campaign: Campaign, companyId: string) =>
  apiClient.patch(`/campaigns/${campaign.id}/duplicate`, { companyId }).then(({ data }) => data)

export const duplicateCampaign = (campaign: Campaign) =>
  apiClient.patch(`/campaigns/${campaign.id}/duplicate`).then(({ data }) => data)

export const deleteCampaign = (campaign: Campaign) =>
  apiClient.delete(`/campaigns/${campaign.id}`).then(({ data }) => data)

export const updateCampaignStep = (params: CampaignStep) => {
  apiClient
    .patch(`/campaign_steps/${params.id}`, {
      campaignStep: {
        id: params.id,
        days: params.days,
      },
    })
    .then(({ data }) => data)
}

export const getCampaign = (id: string) =>
  apiClient.get(`/campaigns/${id}`).then(({data}) => data)

export const getCampaignStep = (id: string) =>
  apiClient.get(`/campaign_steps/${id}`).then(({ data }) => data)

export const createCampaignStep = (campaignStep: CampaignStep) =>
  apiClient.post('/campaign_steps', { campaignStep }).then(({ data }) => data)

export const deleteCampaignStep = (id: string) =>
  apiClient.delete(`/campaign_steps/${id}`).then(({ data }) => data)
