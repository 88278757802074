import { Campaign, Question, TemplateQuestion } from '../types'
import { compact, get, includes, isEmpty, filter, flatten, map, union } from 'lodash'

export const campaignUrl = (campaign: Campaign) =>
campaign.target === 'thank_you' ? `/campaign-steps/${get(campaign, 'campaignSteps[0].id')}` : `/campaigns/${campaign.id}`

export const filterTemplateQuestions = (
  currentCampaign: Campaign | null,
  selectedQuestions: Question[],
  templateQuestions: TemplateQuestion[],
) => {
  const questionsFromCampaign = flatten(
    map(get(currentCampaign, 'campaignSteps'), (step: any) => get(step, 'companySurvey.questions')),
  )
  const idsTemplateQuestions = map(questionsFromCampaign, (question: Question) =>
    get(question, 'templateQuestionId'),
  )
  const idsSelectedQuestions = map(selectedQuestions, (question: Question) => question.id)
  return filter(
    templateQuestions,
    (question: Question) =>
      !(includes(idsSelectedQuestions, question.id) || includes(idsTemplateQuestions, question.id)),
  )
}

export const formattedQuestionUpdate = (questions: Question[], prevQuestions: Question[]) => {
  const newQuestionIds = map(questions, (question: Question) => question.id)

  const result = map(questions, (question: any, idx: number) => {
    if (isEmpty(get(question, 'templateQuestion.id'))) {
      return {
        order: idx + 1,
        templateQuestionId: get(question, 'id'),
      }
    }

    return {
      id: get(question, 'id'),
      order: idx + 1,
      templateQuestionId: get(question, 'templateQuestion.id'),
    }
  })

  const deleteQuestions = compact(
    map(prevQuestions, (question: any) => {
      if (!includes(newQuestionIds, question.id)) {
        return {
          id: get(question, 'id'),
          _destroy: true,
          templateQuestionId: get(question, 'templateQuestion.id'),
        }
      }
    }),
  )
  return union(result, deleteQuestions)
}

export const filteredTemplateQuestions = (templateQuestions: Question[], questions: Question[]) => {
  const questionsIds = map(questions, (question: Question) =>
    isEmpty(get(question, 'templateQuestion.id', null))
      ? get(question, 'id')
      : get(question, 'templateQuestion.id'),
  )

  return filter(templateQuestions, (question: any) => {
    return !includes(questionsIds, get(question, 'id'))
  })
}
