import React from 'react'

/* redux */
import { connect } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Dispatch } from 'redux'

/* ag grid */
import { GridApi, GridReadyEvent } from 'ag-grid-community/main.d'

/* libs */
import Select from 'react-select'
import { get, filter } from 'lodash'

import { Button, TextInput, Loader } from 'tyaw-components'

/* app */
import { Can } from '../../config/ability-context'
import { UsersGrid, SignedInLayout } from '../../components'
import { Account, Company, SelectOption, Store, User } from '../../types'
import { getAccounts, deleteUser, deleteUserInvitationToken, deleteManagerInvitationToken,
  deleteGuestAuthorizationToken  } from '../../api'
import { companyOptions } from '../../helpers/company-helper'
import { notification } from '../../helpers'
import {
  getCompanies as getCompaniesAction,
  createManagerInvitationToken as createManagerInvitationTokenAction
} from '../../actions'
import { ExportGridComponent } from '../../components/ag-grid'

import './styles.sass'

interface IProps {
  user?: User
  locale: string
  companies: Company[]
  createManagerInvitationToken: (managerInvitToken: any) => void
  getCompanies: () => void
}

interface IState {
  email: string
  accountType: string
  isSuperAdmin: boolean
  managerRole: SelectOption
  selectedOption: SelectOption
  gridApi: GridApi | undefined
  accounts: any[]
}


export class UsersScene extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props)

    this.state = {
      email: '',
      isSuperAdmin: false,
      gridApi: undefined,
      accounts: [],
      accountType: "managers",
      managerRole: {
        value: 'admin',
        label: I18n.t('models.user.roles.admin')
      },
      selectedOption: {
        value: get(props.user, 'company.id', ''),
        label: get(props.user, 'company.name', '')
      },
    }
  }

  componentDidMount() {
    const {
      getCompanies
    } = this.props

    getCompanies()
    this.loadAccounts(this.state.accountType)
  }

  changeRole(selectedOption: any) {
    if (selectedOption.value === 'super_admin'){
      this.setState({
        selectedOption: { value: '', label: '' },
        isSuperAdmin: true,
        managerRole: selectedOption
      })
    } else {
      this.setState({
        managerRole: selectedOption,
        isSuperAdmin: false
      })
    }
  }

  handleChange(selectedOption: any) {
    return this.setState({
      selectedOption: {
        value: selectedOption.value,
        label: selectedOption.label,
      },
    })
  }

  handleDelete(account: Account): Promise<boolean> {
    return new Promise((resolve, reject) => {
      switch(account.dataType) {
        case "User":
          deleteUser(account.id, account.role, account.companyId).then(() => {
            this.removeUser(account)
            notification('success', 'user.delete')
            resolve(true)
          }).catch((error: any) => {
            notification('error', 'user.delete')
            reject(false)
          })
          break;
        case "UserInvitationToken":
          deleteUserInvitationToken(account.id).then(() => {
            this.removeUser(account)
            notification('success', 'invitation.delete')
            resolve(true)
          }).catch((error: any) => {
            notification('error', 'invitation.delete')
            reject(false)
          })
          break;
        case "ManagerInvitationToken":
          deleteManagerInvitationToken(account.id).then(() => {
            this.removeUser(account)
            notification('success', 'invitation.delete')
            resolve(true)
          }).catch((error: any) => {
            notification('error', 'invitation.delete')
            reject(false)
          })
          break;
        case "GuestAuthorizationToken":
          deleteGuestAuthorizationToken(account.id).then(() => {
            this.removeUser(account)
            notification('success', 'invitation.delete')
            resolve(true)
          }).catch((error: any) => {
            notification('error', 'invitation.delete')
            reject(false)
          })
          break;
        default:
          console.error("Unknown data type")
      }
    })
  }

  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    const { createManagerInvitationToken } = this.props
    const { email, selectedOption, managerRole } = this.state

    e.preventDefault()

    createManagerInvitationToken({ email, companyId: selectedOption.value, role: get(managerRole, 'value', null) })
  }

  loadAccounts(accountType: string) {
    getAccounts(accountType).then((accounts: Account[]) => {
      this.setState({ accounts, accountType })
    })
  }

  onGridReady(event: GridReadyEvent) {
    this.setState({
      gridApi: event.api
    })
  }

  removeUser(deletedAccount: Account) {
    const { accounts } = this.state
    this.setState({
      accounts: filter(accounts, (account: any) => {
        if (account.dataType === deletedAccount.dataType && account.role === deletedAccount.role) {
          return account.id !== deletedAccount.id
        }
        return true
      })
    })
  }

  renderTabs() {
    const { accountType } = this.state

    return (
      <div className="ag-grid-tabs">
        <Can I="see" this="admins">
          <Button
            onClick={() => this.loadAccounts("super_admins")}
            type="button"
            className={accountType === "super_admins" ? "active-button" : "inactive-button" }
          >
            <Translate value="pages.users.superAdmins" />
          </Button>
        </Can>

        <Button
          onClick={() => this.loadAccounts("managers")}
          type="button"
          className={accountType === "managers" ? "active-button" : "inactive-button"}
        >
          <Translate value="pages.users.managers" />
        </Button>
        <Button
          onClick={() => this.loadAccounts("user_invitations")}
          type="button"
          className={accountType === "user_invitations" ? "active-button" : "inactive-button"}
        >
          <Translate value="pages.users.invitedUsers" />
        </Button>
        <Button
          onClick={() => this.loadAccounts("guest_invitations")}
          type="button"
          className={accountType === "guest_invitations" ? "active-button" : "inactive-button"}
        >
          <Translate value="pages.users.invitedGuests" />
        </Button>
      </div>
    )
  }

  renderGridMenu() {
    const { gridApi } = this.state

    if (!gridApi) {
      return null
    }

    return (
      <div className="ag-grid-tools">
        <div className="ag-grid-buttons">
          <ExportGridComponent
            gridApi={gridApi}
          />
        </div>
        <div className="ag-grid-filters-container">
          <TextInput
            placeholder={I18n.t('actions.search')}
            className="ag-grid-filter white"
            onChangeText={(e: any) => gridApi && gridApi.setQuickFilter(e)}
          />
        </div>
      </div>
    )
  }

  renderGrid() {
    const { accounts } = this.state
    const { locale, user } = this.props

    return(
      <div className="ag-grid-container">
        { this.renderTabs() }
        { this.renderGridMenu() }
        <UsersGrid
          onGridReady={ (event: GridReadyEvent) => this.onGridReady(event) }
          handleDelete={ (data: any) => this.handleDelete(data)}
          data={{
            locale,
            rows: accounts,
            showCompany: get(user, 'admin')
          }}
        />
      </div>
    )
  }

  renderInviteForm() {
    const { user, companies } = this.props
    const { managerRole, email, isSuperAdmin, selectedOption } = this.state

    const primaryColor = get(user, 'company.primaryColor')
    const activatedTags = get(user, 'company.activatedTags')

    let roleOptions = [
      { label: I18n.t('models.user.roles.admin'), value: 'admin' }
    ]

    if (get(user, 'admin', false)) {
      roleOptions.push(
        { label: I18n.t('models.user.roles.super_admin'), value: 'super_admin' },
        { label: I18n.t('models.user.roles.advanced_user'), value: 'advanced_user' }
      )
    }

    if (activatedTags) {
      roleOptions.push(
        { label: I18n.t('models.user.roles.advanced_user'), value: 'advanced_user' }
      )
    }

    return (
      <div className="admin-input-container">
        <Can I="set" this="role">
          <div className="admin-checkbox-card text-input-component">
            <label htmlFor="managerRole" className="text-input-label">
              <Translate value="models.user.roles.label" />
            </label>
            <Select
              value={managerRole}
              id="managerRole"
              options={roleOptions}
              onChange={(selectedOption: any) => this.changeRole(selectedOption)}
            />
          </div>
        </Can>

        <div className="admin-input-card">
          <TextInput
            className="white"
            label={I18n.t('models.user.email')}
            placeholder=""
            onChangeText={(email: string) => this.setState({ email })}
            value={email}
          />
        </div>

        <Can I="add" this="companyField">
          {isSuperAdmin === false && (
            <div className="admin-select-card text-input-component">
              <label htmlFor="selectCompany" className="text-input-label">
                <Translate value="models.user.company" />
              </label>
              <Select
                id="selectCompany"
                value={selectedOption.value ? selectedOption : I18n.t('models.user.company')}
                onChange={(selectedOption: any) => this.handleChange(selectedOption)}
                options={companyOptions(companies)}
                placeholder={I18n.t('models.user.company')}
              />
            </div>
          )}
        </Can>

        <Button
          type="submit"
          className="invite-button"
          onClick={(e: any) => this.handleSubmit(e)}
          primaryColor={primaryColor}
        >
          <Translate value="pages.users.button" />
        </Button>
      </div>
    )
  }

  render() {
    const { user } = this.props

    if (!user || !user.id) {
      return (
        <SignedInLayout>
          <Loader />
        </SignedInLayout>
      )
    }

    return (
      <SignedInLayout>
        <div className="header">
          <h2><Translate value="pages.users.title" /></h2>

        </div>
        <h3><Translate value="pages.users.inviteForm" /></h3>
        { this.renderInviteForm() }
        <h3><Translate value="pages.users.list" /></h3>
        { this.renderGrid() }
      </SignedInLayout>
    )
  }
}

const mapStateToProps = ({
  user: { currentUser },
  companies: { allCompanies },
  i18n: { locale }
}: Store) => ({
  locale,
  user: currentUser,
  companies: allCompanies
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCompanies: () => dispatch(getCompaniesAction()),
  createManagerInvitationToken: (data: any) => dispatch(createManagerInvitationTokenAction(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersScene)
