import { apiClient } from '../constants'

export const getStatistics = () =>
  apiClient.get('/statistics').then(({ data }) => data)

export const getStatisticsForCompany = (companyId: string) => apiClient.get('/statistics', {
  params: {
    company_id: companyId
  }
}).then(({ data }) => data)

export const getStatistic = (id: string) =>
  apiClient.get(`/statistics/${id}`).then(({ data }) => data)

  export const createStatistic = (loggedDataType: string, loggedDataId: string, statType: string, fromMosaic: boolean , token: string | null ) =>
  apiClient.post('/statistics/',{ loggedDataType, loggedDataId, statType, fromMosaic, token }).then(({ data }) => data)
