import React from 'react'
import icons from '../../assets/icons/icons.svg'

interface IProps {
  name: string
  size?: number
}

const Icon = (props: IProps) => {
  const { name, size = 15 } = props

  return (
    <svg
      viewBox={`0 0 ${size} ${size}`}
      className={`icon icon-${name}`}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <use xlinkHref={`${icons}#${name}`} />
    </svg>
  )
}

export default Icon
