import { get } from 'lodash'
import { Company, SelectOption } from '../types'
import { I18n } from 'react-redux-i18n'

export const companyOptions = (companies: Company[]): SelectOption[] => {

  const selectOtions: SelectOption[] = [
    { value: 'all', label: I18n.t('components.companySelect.all'), prop1: ''  }
  ]

  companies.forEach((company: Company) => {
    selectOtions.push({
      value: get(company, 'id'),
      label: get(company, 'name'),
      details: company,
      prop1: ''
    })
  })

  return selectOtions
}
