import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'

/* redux */
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'

/* AG Grid */
import { ColumnApi, GridApi, GridReadyEvent, RowValueChangedEvent, RowDragEndEvent } from 'ag-grid-community/main.d'

/* libs */
import { get } from 'lodash'
import { TextInput } from 'tyaw-components'

/* app */
import { notification } from '../../helpers'
import { AddRowComponent, ExportGridComponent } from '../../components/ag-grid'
import { Tag, Store, User } from '../../types'
import { getTags, deleteTag, createTag, updateTag, moveTag } from '../../api'
import { TagsGrid, SignedInLayout } from '../../components'

interface IProps extends RouteComponentProps<any> {
  user: User
  locale: string
}

interface IState {
  gridApi: GridApi | undefined
  gridColumnApi: ColumnApi | undefined
  tags: Tag[]
}

class TagsScene extends React.Component<IProps, IState> {

  hierarchyId: string

  constructor(props: IProps) {
    super(props)

    const {
      match: {
        params: { id },
      }
    } = props

    this.hierarchyId = id

    this.state = {
      gridApi: undefined,
      gridColumnApi: undefined,
      tags: []
    }
  }

  componentDidMount(){
    getTags(this.hierarchyId).then((tags) =>
      this.setState({ tags })
    ).catch(() => notification('error', 'tag.index'))
  }

  /* todo: show status: https://stackblitz.com/edit/async-validation-ag-grid-final?file=main.js */
  handleChanges(event: RowValueChangedEvent) {
    const data: any = get(event, 'data')

    if (get(data, 'isNew', false)) {
      createTag(this.hierarchyId, data).then((tags: Tag[]) => {
        this.setState({ tags })
        notification('success', 'tag.create')
      }).catch(() => {
        notification('error', 'tag.create')
      })
    } else {
      updateTag(this.hierarchyId, data.id, data).then((tags: Tag[]) => {
        this.setState({ tags })
        notification('success', 'tag.update')
      }).catch(() => {
        notification('error', 'tag.update')
      })
    }
  }

  handleDelete(tag: Tag): Promise<boolean> {
    return new Promise((resolve: any, reject: any) => {
      if (!tag.isNew) {
        deleteTag(this.hierarchyId, get(tag, 'id')).then((tags: Tag[]) => {
          this.setState({ tags })
          notification('success', 'tag.delete')
          resolve(true)
        }).catch((error: any) => {
          notification('error', 'tag.delete', { apiErrors: get(error, 'response.data', []) })
          reject(false)
        })
      }
    })
  }

  onRowDragEnd(event: RowDragEndEvent) {
    const tagId: number = get(event, 'node.data.id')
    const newParentId: number = get(event, 'overNode.data.id', tagId)
    if (tagId === newParentId) {
      return
    }

    moveTag(this.hierarchyId, tagId, newParentId).then((newTags: Tag[]) => {
      this.setState({ tags: newTags })
      notification('success', 'tag.move')
    }).catch((error: any) => {
      this.forceUpdate()
      /*
      const newTags = get(error, 'response.data.tags')
      if (newTags) {
        this.setState({ tags: newTags })
      }*/
      notification('error', 'tag.move', { apiErrors: get(error, 'response.data', []) })
      return
    })
  }

  onGridReady(event: GridReadyEvent) {
    this.setState({
      gridApi: event.api,
      gridColumnApi: event.columnApi
    })
  }

  renderGridMenu() {
    const { gridApi, gridColumnApi } = this.state

    if (!gridApi) {
      return null
    }

    return (
      <div className="ag-grid-tools">
        <div className="ag-grid-buttons">
          <AddRowComponent
            gridApi={gridApi}
            columnApi={gridColumnApi}
            buttonLabel={I18n.t('pages.tags.titleCreate')}
            initData={{labelI18n: {}, descI18n: {}}}
          />
          <ExportGridComponent
            gridApi={gridApi}
          />
        </div>
        <div className="ag-grid-filters-container">
          <TextInput
            placeholder={I18n.t('actions.search')}
            className="ag-grid-filter white"
            onChangeText={(e: any) => gridApi && gridApi.setQuickFilter(e)}
          />
        </div>
      </div>
    )
  }

  renderGrid() {
    const { tags } = this.state
    const { locale } = this.props

    return(
      <div className="ag-grid-container">

        { this.renderGridMenu() }
        <TagsGrid
          onGridReady={ (event: GridReadyEvent) => this.onGridReady(event) }
          handleChanges={ (event: RowValueChangedEvent) => this.handleChanges(event) }
          onRowDragEnd={ (event: RowDragEndEvent) => this.onRowDragEnd(event) }
          handleDelete={ (tag: Tag) => this.handleDelete(tag)}
          data={{
            locale,
            rows: tags,
          }}
        />
      </div>
    )
  }

  render() {
    return (
      <SignedInLayout>
        { this.renderGrid() }
      </SignedInLayout>
    )
  }
}

const mapStateToProps = () => ({
  user: { currentUser },
  i18n: { locale }
}: Store) => ({
  locale,
  user: currentUser
})

export default withRouter(connect(
  mapStateToProps
)(TagsScene) as any)
