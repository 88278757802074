import { handle } from 'redux-pack'
import { map } from 'lodash'
import { GET_USER_SURVEYS, VALIDATE_USER_SURVEY, RESEND_SURVEY_INVITATION } from '../constants'
import { Survey } from '../types'

interface IState {
  allSurveys: Survey[]
  loading: boolean,
  error: any
}

const initialState: IState = {
  allSurveys: [],
  loading: false,
  error: null
}

export default (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case GET_USER_SURVEYS:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({ ...prevState, allSurveys: payload }),
      })
    case VALIDATE_USER_SURVEY:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => prevState,
        success: prevState => ({
          ...prevState,
          allSurveys: map(prevState.allSurveys, (survey: any) =>
            survey.id === payload.id ? payload : survey),
        }),
      })
    case RESEND_SURVEY_INVITATION:
      return handle(state, action, {
        start: prevState => ({ ...prevState, loading: true }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload }),
        success: prevState => ({
          ...prevState,
          allSurveys: map(prevState.allSurveys, (survey: any) =>
            survey.id === payload.id ? payload : survey),
        }),
      })
    default:
      return state
  }
}
