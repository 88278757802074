import { apiClient } from '../constants'
import { Tag } from '../types'

export const getTags = (hierarchyId: string, mode: string = "default") =>
  apiClient.get(`/hierarchies/${hierarchyId}/tags`, { params: { mode } }).then(({data}) => data)

export const getCompanyTags = (companyId: string) =>
  apiClient.get(`/companies/${companyId}/tags`, { params: { mode: "tree" } }).then(({ data }) => data)

export const getSurveyTags = (userSurveyId: string, token: string | null = null, fromMosaic?: boolean) =>
  apiClient.get(`/user_surveys/${userSurveyId}/tags`, { params: { mode: "tree", t: token, from_mosaic: fromMosaic } }).then(({ data }) => data)

export const deleteTag = (hierarchyId: string, tagId: number) =>
  apiClient.delete(`/hierarchies/${hierarchyId}/tags/${tagId}`).then(({ data }) => data)

export const updateTag = (hierarchyId: string, tagId: number, tag: Tag) =>
  apiClient.patch(`/hierarchies/${hierarchyId}/tags/${tagId}`, { tag }).then(({ data }) => data)

export const createTag = (hierarchyId: string, tag: Tag) =>
  apiClient.post(`/hierarchies/${hierarchyId}/tags`, { tag }).then(({ data }) => data)

export const moveTag = (hierarchyId: string, tagId: number, parentId: number) =>
  apiClient.patch(`/hierarchies/${hierarchyId}/tags/${tagId}/move`, { parentId }).then(({ data }) => data)
